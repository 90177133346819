import { BackendError } from 'src/errors/BackendError';
import { addSessionToken, requestUpdateToken } from '../../api/auth';

interface IDefaultRequestParams {
  headers?: Record<string, string>;
  body: BodyInit | object;
}

class AuthApi {
  readonly _baseURL: string;
  readonly _headers: Headers;

  constructor(settings?: { baseURL?: string; headers?: Record<string, string> }) {
    this._baseURL = settings?.baseURL || '';
    this._headers = this._setJwtTokenHeader(settings?.headers);
  }

  private _setJwtTokenHeader(params?: Record<string, string>) {
    const accessToken = sessionStorage.getItem(`${process.env.REACT_APP_IAS_ACCESS_TOKEN}`);
    const jwtHeader: Record<string, string> = {
      Authorization: `Bearer ${accessToken}`,
      ...params
    };
    return this._setHeaders(jwtHeader);
  }

  private _setHeaders(params?: Record<string, string>) {
    const headers = new Headers(this._headers);

    for (const prop in params) {
      const val = params[prop];

      headers.set(prop, val);
    }

    return headers;
  }

  private static async _parseBodyResponse<R>(res: Response): Promise<R> {
    const contentType = res.headers.get('content-type');
    const text = (await res.text()) as any;
    let data: any = null;

    if (contentType?.includes('text/plain')) {
      if (res.ok) {
        return text as R;
      } else {
        throw new BackendError(res.statusText, res.status, null);
      }
    }
    try {
      data = JSON.parse(text);
    } catch (err) {
      throw new BackendError(text, res.status, null);
    }

    if (!res.ok) {
      throw new BackendError(res.statusText, res.status, data);
    }

    return data as R;
  }

  private static getNewHeaderToken(headers: HeadersInit | undefined, accessToken: string) {
    const tokenHeader: Record<string, string> = {
      Authorization: `Bearer ${accessToken}`
    };
    const newRequestHeader: HeadersInit = {
      ...headers,
      ...tokenHeader
    };
    return newRequestHeader;
  }

  private static async updateTokenAndRequestRetry(input: RequestInfo | URL, init?: RequestInit) {
    const tokenInfo = await requestUpdateToken();
    addSessionToken(tokenInfo.access_token, tokenInfo.refresh_token);
    const newInit: RequestInit = { ...init };
    newInit.headers = AuthApi.getNewHeaderToken(init?.headers, tokenInfo.access_token);
    return await fetch(input, newInit);
  }

  private static async fetchRequest(input: RequestInfo | URL, init?: RequestInit) {
    const res = await fetch(input, init);
    if (res.status === 401) {
      return await AuthApi.updateTokenAndRequestRetry(input, init);
    }
    return res;
  }

  async get<R = unknown>(path: string, params?: Omit<IDefaultRequestParams, 'body'>) {
    const res = await AuthApi.fetchRequest(this._baseURL + path, {
      method: 'GET',
      headers: this._setHeaders(params?.headers)
    });

    const body = await AuthApi._parseBodyResponse<R>(res);

    return { body, headers: res.headers };
  }

  async post<R = unknown>(path: string, params: IDefaultRequestParams) {
    const res = await AuthApi.fetchRequest(this._baseURL + path, {
      method: 'POST',
      body: JSON.stringify(params.body),
      headers: this._setHeaders(params.headers)
    });

    const body = await AuthApi._parseBodyResponse<R>(res);

    return { body, headers: res.headers };
  }

  async patch<R = unknown>(path: string, params: IDefaultRequestParams) {
    const res = await AuthApi.fetchRequest(this._baseURL + path, {
      method: 'PATCH',
      body: JSON.stringify(params.body),
      headers: this._setHeaders(params.headers)
    });

    const body = await AuthApi._parseBodyResponse<R>(res);

    return { body, headers: res.headers };
  }

  async delete<R = unknown>(path: string, params?: Omit<IDefaultRequestParams, 'body'>) {
    const fetchParma = {
      method: 'DELETE',
      headers: this._setHeaders(params?.headers)
    };

    const res = await AuthApi.fetchRequest(this._baseURL + path, fetchParma);

    const body = await AuthApi._parseBodyResponse<R>(res);

    return { body, headers: res.headers };
  }

  async put<R = unknown>(path: string, params: IDefaultRequestParams) {
    const res = await AuthApi.fetchRequest(this._baseURL + path, {
      method: 'PUT',
      body: JSON.stringify(params.body),
      headers: this._setHeaders({})
    });

    const body = await AuthApi._parseBodyResponse<R>(res);

    return { body, headers: res.headers };
  }

  async putFile<R = unknown>(url: string, files: Omit<FormData, 'body'>) {
    const res = await AuthApi.fetchRequest(url, {
      method: 'PUT',
      body: files,
      headers: this._setHeaders({
        'Content-Type': 'multipart/form-data'
      })
    });

    const body = await AuthApi._parseBodyResponse<R>(res);

    return { body, headers: res.headers };
  }
}

export default AuthApi;
