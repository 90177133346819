/* eslint max-len: 0 */
import * as React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export const NotAvailableIcon = createSvgIcon(
  <g>
    <svg width='24' height='24' viewBox='0 0 49 48' fill='current' xmlns='http://www.w3.org/2000/svg'>
      <g id='24px icons' clipPath='url(#clip0_590_23759)'>
        <path
          id='Vector'
          d='M40.5 21.9999C40.0109 18.4803 38.3781 15.2192 35.8533 12.7189C33.3284 10.2186 30.0515 8.61774 26.5273 8.16301C23.0032 7.70827 19.4272 8.42486 16.3504 10.2024C13.2736 11.9799 10.8665 14.7198 9.5 17.9999M8.5 9.9999V17.9999H16.5'
          stroke='current'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M8.5 26C8.98912 29.5196 10.6219 32.7807 13.1467 35.281C15.6716 37.7813 18.9485 39.3822 22.4727 39.8369C25.9968 40.2916 29.5728 39.575 32.6496 37.7975C35.7264 36.02 38.1335 33.2801 39.5 30M40.5 38V30H32.5'
          stroke='current'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_590_23759'>
          <rect width='48' height='48' fill='current' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </svg>
  </g>,
  'Not Available Icon'
);
