import { PolygonPoint, RoomBoundaryPoint } from 'src/@types/reducer';
import _ from 'lodash';

export const getDefaultCoordinates = (): RoomBoundaryPoint[] => [
  {
    id: 'p1',
    x: 0.265,
    y: 0.032
  },
  {
    id: 'p2',
    x: 0.695,
    y: 0.032
  },
  {
    id: 'p3',
    x: 0.695,
    y: 0.435
  },
  {
    id: 'p4',
    x: 0.695,
    y: 0.855
  },
  {
    id: 'p5',
    x: 0.265,
    y: 0.855
  },
  {
    id: 'p6',
    x: 0.265,
    y: 0.435
  }
];

export const getCoordinatesInRelative = (
  coords: { x: number; y: number },
  resolution: { width: number; height: number }
): { rx: number; ry: number } => {
  const rx = coords.x / resolution.width;
  const ry = coords.y / resolution.height;
  return { rx, ry };
};

export const getRelativeinCoordinates = (
  relatives: { rx: number; ry: number },
  resolution: { width: number; height: number }
): { x: number; y: number } => {
  const x = resolution.width * relatives.rx;
  const y = resolution.height * relatives.ry;
  return { x, y };
};

export const transformPointsToPolygon = (points: RoomBoundaryPoint[]): PolygonPoint[] => {
  return points.map(point => ({
    x: point.x,
    y: point.y
  }));
};

export const debouncedFunction = _.debounce(
  (value: any, func: any) => {
    func(value);
  },
  250,
  {
    leading: true,
    maxWait: 250
  }
);
