import React from 'react';
import { FallbackPage } from 'src/components/FallbackPage/FallbackPage';
import { AuthRouteNames } from './route-names';

const Main = React.lazy(() => import('../screens/Main'));
const PhysicianInfo = React.lazy(() => import('../screens/PhysicianInfo'));
const SignIn = React.lazy(() => import('../screens/SignIn'));
const Callback = React.lazy(() => import('../screens/Callback'));

export const AuthRoutes = [
  {
    path: AuthRouteNames.Main,
    element: (
      <React.Suspense fallback={<FallbackPage />}>
        <Main />
      </React.Suspense>
    )
  },
  {
    path: AuthRouteNames.PhysicianInfo,
    element: (
      <React.Suspense fallback={<FallbackPage />}>
        <PhysicianInfo />
      </React.Suspense>
    )
  },
  {
    path: AuthRouteNames.SignIn,
    element: (
      <React.Suspense fallback={<FallbackPage />}>
        <SignIn />
      </React.Suspense>
    )
  },
  {
    path: AuthRouteNames.Callback,
    element: (
      <React.Suspense fallback={<FallbackPage />}>
        <Callback />
      </React.Suspense>
    )
  }
];
