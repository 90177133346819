import React from 'react';
import { FallbackPage } from 'src/components/FallbackPage/FallbackPage';
import { NotFoundRouteNames } from './route-names';

const NotFound = React.lazy(() => import('../screens/NotFound'));

export const NotFoundRoutes = [
  {
    path: NotFoundRouteNames.NotFound,
    element: (
      <React.Suspense fallback={<FallbackPage />}>
        <NotFound />
      </React.Suspense>
    )
  }
];
