import { RootState } from '../reducers/root';
import { BedrailsState } from '../reducers/bedrailsSlice';
import { createSelector } from 'reselect';
import { getSelectedCameraUuid } from './esitter';
import _ from 'lodash';
import { BedrailsStatus } from '../features/esitter/components/SidePanel/content/BedrailsPanel/BedrailsPanel';
import { VirtualBedrailsSensitivityLevel } from '../features/esitter/components/SidePanel/content/VirtualBedrailsContent/VirtualBedrailsContent';

export const getBedrails = (state: RootState): BedrailsState => state.bedrails;
export const selectBoundariesArray = createSelector([getBedrails], bedrails => bedrails.boundariesArray || []);
export const isBoundariesSetUpForSelectedCamera = createSelector(
  [getBedrails, getSelectedCameraUuid],
  (bedrails, selectedCameraUuid) => bedrails.boundariesArray.findIndex(value => value.id === selectedCameraUuid) >= 0
);

export const isBoundariesSetUpForCameraUuid = createSelector(
  [getBedrails, (bedrails, cameraUuid) => cameraUuid],
  (bedrails, cameraUuid) => bedrails.boundariesArray.findIndex(value => value.id === cameraUuid) >= 0
);

export const selectIsSaving = createSelector([getBedrails, getSelectedCameraUuid], (bedrails, cameraUuid) => {
  const { isSaving } = bedrails.boundariesArray.find(value => value.id === cameraUuid) || { isSaving: false };
  return isSaving;
});

export const selectIsSaved = createSelector([getBedrails, getSelectedCameraUuid], (bedrails, cameraUuid) => {
  const { isSaved } = bedrails.boundariesArray.find(value => value.id === cameraUuid) || { isSaved: false };
  return isSaved;
});

export const selectSaveDisabled = createSelector(
  [getBedrails, (bedrails, cameraUuid) => cameraUuid],
  (bedrails, cameraUuid) => {
    const { saveDisabled } = bedrails.boundariesArray.find(value => value.id === cameraUuid) || { saveDisabled: true };
    return saveDisabled;
  }
);
export const getLastBoundaryWithMotionDetected = createSelector([getBedrails], bedrails => {
  const sortedBoundaries = _.sortBy(bedrails.boundariesArray, b => -b.motionDetectedTimestamp);
  return sortedBoundaries.find(b => b.motionDetected && b.mutedTimestamp < Date.now());
});

export const getReactivatedBoundaries = createSelector([getBedrails], bedrails =>
  bedrails.boundariesArray.find(value => value.reactivated)
);

export const selectEditMode = createSelector(
  [getBedrails, (bedrails, cameraUuid) => cameraUuid],
  (bedrails, cameraUuid) => {
    const { editMode } = bedrails.boundariesArray.find(value => value.id === cameraUuid) || {
      editMode: false
    };
    return editMode;
  }
);
export const selectSelectedCameraEditMode = createSelector(
  [getBedrails, getSelectedCameraUuid],
  (bedrails, cameraUuid) => {
    const { editMode } = bedrails.boundariesArray.find(value => value.id === cameraUuid) || {
      editMode: false
    };
    return editMode;
  }
);

export const selectEnabled = createSelector(
  [getBedrails, (bedrails, cameraUuid) => cameraUuid],
  (bedrails, cameraUuid) => {
    const { enabled } = bedrails.boundariesArray.find(value => value.id === cameraUuid) || {
      enabled: false
    };
    return enabled;
  }
);

export const selectSensitivityLevel = createSelector(
  [getBedrails, (bedrails, cameraUuid) => cameraUuid],
  (bedrails, cameraUuid) => {
    const { sensitivityLevel } = bedrails.boundariesArray.find(value => value.id === cameraUuid) || {
      sensitivityLevel: VirtualBedrailsSensitivityLevel.MEDUIM
    };
    return sensitivityLevel;
  }
);

export const selectMotionDetected = createSelector(
  [getBedrails, (bedrails, cameraUuid) => cameraUuid],
  (bedrails, cameraUuid) => {
    const { motionDetected } = bedrails.boundariesArray.find(value => value.id === cameraUuid) || {
      motionDetected: false
    };
    return motionDetected;
  }
);

export const getCameraBedrailsStatus = createSelector(
  [getBedrails, (bedrails, cameraUuid) => cameraUuid],
  (bedrails, cameraUuid) => {
    const { enabled, motionDetected, mutedTimestamp } = bedrails.boundariesArray.find(
      value => value.id === cameraUuid
    ) || {
      enabled: false,
      motionDetected: false,
      mutedTimestamp: 0
    };
    return mutedTimestamp >= Date.now()
      ? BedrailsStatus.MUTED
      : enabled && motionDetected
      ? BedrailsStatus.ALARM
      : enabled
      ? BedrailsStatus.ON
      : BedrailsStatus.OFF;
  }
);

export const getSelectedCameraBedrailsStatus = createSelector(
  [getBedrails, getSelectedCameraUuid],
  (bedrails, cameraUuid) => {
    const { enabled, motionDetected, mutedTimestamp } = bedrails.boundariesArray.find(
      value => value.id === cameraUuid
    ) || {
      enabled: false,
      motionDetected: false,
      mutedTimestamp: 0
    };
    return mutedTimestamp >= Date.now()
      ? BedrailsStatus.MUTED
      : enabled && motionDetected
      ? BedrailsStatus.ALARM
      : enabled
      ? BedrailsStatus.ON
      : BedrailsStatus.OFF;
  }
);

export const getSelectedCameraBedrailsPolygon = createSelector(
  [getBedrails, getSelectedCameraUuid],
  (bedrails, cameraUuid) => {
    const { points } = bedrails.boundariesArray.find(value => value.id === cameraUuid) || {
      points: []
    };
    return points;
  }
);

export const getSelectedCameraBedrailsFirstTime = createSelector(
  [getBedrails, getSelectedCameraUuid],
  (bedrails, cameraUuid) => {
    const { firstTime } = bedrails.boundariesArray.find(value => value.id === cameraUuid) || {
      firstTime: false
    };
    return firstTime;
  }
);

export const getSelectedCameraBoundaryMutedTimestamp = createSelector(
  [getBedrails, getSelectedCameraUuid],
  (bedrails, selectedCameraUuid) => {
    const { mutedTimestamp } = bedrails.boundariesArray.find(value => value.id === selectedCameraUuid) || {
      mutedTimestamp: 0
    };
    return mutedTimestamp;
  }
);

export const getBoundaryByCameraUuid = createSelector(
  [getBedrails, (bedrails, cameraUuid) => cameraUuid],
  (bedrails, cameraUuid) =>
    bedrails.boundariesArray.find(value => value.id === cameraUuid) || {
      id: '',
      points: [],
      firstTime: true,
      mutedTimestamp: 0,
      motionDetectedTimestamp: 0,
      reactivated: false,
      status: BedrailsStatus.OFF
    }
);
