import { Action, SetUpInitialState } from 'src/@types/reducer';

const frameRateFromLocalStorage: boolean | null = localStorage.getItem('frameRate') === 'false';
const themeSettingTypeFromLocalStorage: string | null = localStorage.getItem('themeSettingType');
const themeFromLocalStorage: string | null = localStorage.getItem('theme');
const i18nextLngFromLocalStorage: string | null = localStorage.getItem('i18nextLng');

const initialState: SetUpInitialState = {
  frameRate: frameRateFromLocalStorage || false,
  themeSettingType: themeSettingTypeFromLocalStorage || 'dark',
  theme: themeFromLocalStorage || 'dark',
  i18nextLng: i18nextLngFromLocalStorage || 'ko'
};

const setupReducer = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case 'SET_FRAME_RATE':
      return {
        ...state,
        frameRate: action.payload
      };
    case 'SET_THEME_SETTING_TYPE':
      return {
        ...state,
        themeSettingType: action.payload
      };
    case 'SET_THEME':
      return {
        ...state,
        theme: action.payload
      };
    case 'SET_LANGUAGE':
      return {
        ...state,
        i18nextLng: action.payload
      };
    default:
      return state;
  }
};

export default setupReducer;
