import React from 'react';
import { makeThemedStyles } from '../../../../../../hooks/useThemedStyles';
import { VirtualBedrailsIcon } from '../../../../../../assets/icons/VirtualBedrailsIcon';
import { IconWithText } from '../IconWithText/IconWithText';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { SvgIcon } from '@mui/material';
import { BedrailsStatus } from '../BedrailsPanel/BedrailsPanel';
import { AlertMutedTimer } from './AllertMutedTimer';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface BedrailsIndicatorSettings {
  text: string | React.ReactNode;
  icon: typeof SvgIcon;
  color: string;
}

interface BedrailsIndicatorProps {
  status: BedrailsStatus;
}

type BedrailsStatusMap = Record<BedrailsStatus, BedrailsIndicatorSettings>;

export const BedrailsIndicator = React.memo((props: BedrailsIndicatorProps): JSX.Element | null => {
  const { status } = props;
  const {
    palette: { mode }
  } = useTheme();
  const { t } = useTranslation();

  const statusMap: BedrailsStatusMap = {
    [BedrailsStatus.ON]: {
      text: t('bedObservation.rightPanel.virtualBedrail.alertOn'),
      icon: VirtualBedrailsIcon,
      color: '#297859'
    },
    [BedrailsStatus.OFF]: {
      text: t('bedObservation.rightPanel.virtualBedrail.alertOff'),
      icon: VirtualBedrailsIcon,
      color: mode === 'dark' ? '#444444' : '#888888'
    },
    [BedrailsStatus.MUTED]: { text: <AlertMutedTimer />, icon: VolumeOffIcon, color: '#444444' },
    [BedrailsStatus.ALARM]: { text: 'Attention Needed', icon: VirtualBedrailsIcon, color: '#A30909' }
  };

  const { text, icon, color } = statusMap[status as BedrailsStatus];
  const { styles } = useStyles({ iconColor: color });

  return (
    <>
      {/*<Box sx={{ color: 'white' }}>{status}</Box>*/}
      <IconWithText
        text={text}
        icon={icon}
        sx={{ mb: 1, opacity: text === 'Alert Off' ? '0.5' : '1' }}
        iconSx={{ ...styles.icon, width: '34px', height: '34px', padding: '7px' }}
        labelSx={{ color: mode === 'dark' ? '#FFFFFF' : '#111111' }}
      />
    </>
  );
});

type StyleProps = {
  iconColor?: string;
};

const useStyles = makeThemedStyles<StyleProps>()((theme, { iconColor }) => ({
  icon: {
    fontSize: '34px',
    backgroundColor: iconColor,
    borderRadius: '50%',
    padding: '6px'
  }
}));
