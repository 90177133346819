/* eslint max-len: 0 */
import * as React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export const WarningFullIcon = createSvgIcon(
  <g>
    <svg width='24' height='24' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='warning'>
        <path
          id='Vector'
          d='M2.90503 21.4986C2.1331 22.8319 3.09522 24.5007 4.63589 24.5007H23.3648C24.9054 24.5007 25.8675 22.8319 25.0956 21.4986L15.7312 5.32364C14.9608 3.99307 13.0398 3.99307 12.2695 5.32364L2.90503 21.4986ZM15.167 21.0007C15.167 21.645 14.6447 22.1673 14.0003 22.1673C13.356 22.1673 12.8337 21.645 12.8337 21.0007C12.8337 20.3563 13.356 19.834 14.0003 19.834C14.6447 19.834 15.167 20.3563 15.167 21.0007ZM15.167 17.5007C15.167 18.145 14.6447 18.6673 14.0003 18.6673C13.356 18.6673 12.8337 18.145 12.8337 17.5006V12.834C12.8337 12.1897 13.356 11.6673 14.0003 11.6673C14.6447 11.6673 15.167 12.1897 15.167 12.834V17.5007Z'
          fill='#A30909'
        />
      </g>
    </svg>
  </g>,
  'Warning Full Icon'
);
