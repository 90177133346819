import { SxProps, Theme } from '@mui/material';
import { StyleProps } from '../hooks/useThemedStyles';

type MergeSxArgument = SxProps<Theme> | undefined;

export const mergeSx = (...sxStyles: MergeSxArgument[]): MergeSxArgument =>
  sxStyles.reduce((prev, current) => {
    if (Array.isArray(prev)) {
      return prev.concat(current);
    }
    return [prev].concat(current);
  }, []);

export const utilityStyleKeys = {
  children: '> :not([hidden])',
  afterFirstChild: '> :not([hidden]) ~ :not([hidden])',
  scrollbarThumb: '::-webkit-scrollbar-thumb',
  scrollbarTrack: '::-webkit-scrollbar-track'
};

export const styledScrollbar = (): StyleProps => {
  const scrollbarThumbColor = '#5B6B7B';
  const scrollbarTrackColor = '#1A1F24';
  const { scrollbarThumb, scrollbarTrack } = utilityStyleKeys;

  return {
    scrollbarWidth: 'auto',
    scrollbarColor: `${scrollbarThumbColor} ${scrollbarTrackColor}`,
    '::-webkit-scrollbar': {
      width: 20
    },
    [`${scrollbarThumb}, ${scrollbarTrack}`]: {
      border: '6px solid transparent',
      borderRadius: 8,
      backgroundClip: 'padding-box'
    },
    [scrollbarThumb]: {
      backgroundColor: scrollbarThumbColor
    },
    [scrollbarTrack]: {
      backgroundColor: scrollbarTrackColor
    }
  };
};
