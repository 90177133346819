import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
  fontFamily: 'Cairo, Noto Sans KR, sans-serif',
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontSize: 32,
    lineHeight: 1.2,
    fontWeight: 600
  },
  h2: {
    fontSize: 20,
    lineHeight: 1.2,
    fontWeight: 600
  },
  h4: {
    fontSize: 24,
    lineHeight: 1.33,
    fontWeight: 600
  },
  body1: {
    fontSize: 14
  },
  caption: {
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.15px'
  }
};
