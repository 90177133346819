import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './root';

interface UIState {
  showBedrailsPTZChangedWarning: boolean;
  showBedrailsCameraChangedWarning: boolean;
  cameraUuid: string;
}

const initialState: UIState = {
  showBedrailsPTZChangedWarning: false,
  showBedrailsCameraChangedWarning: false,
  cameraUuid: ''
};

interface WarningOnNotification {
  cameraUuid: string;
}

const uiStateSlice = createSlice({
  name: 'uiState',
  initialState,
  reducers: {
    setShowBedrailsPTZChangedWarningOn: (state: UIState, action: PayloadAction<WarningOnNotification>) => {
      const { cameraUuid } = action.payload;

      state.cameraUuid = cameraUuid;
      state.showBedrailsPTZChangedWarning = true;
    },
    setShowBedrailsPTZChangedWarningOff: state => {
      state.showBedrailsPTZChangedWarning = false;
    },
    setShowBedrailsCameraChangedWarningOn: (state: UIState, action: PayloadAction<WarningOnNotification>) => {
      const { cameraUuid } = action.payload;
      state.cameraUuid = cameraUuid;
      state.showBedrailsCameraChangedWarning = true;
    },
    setShowBedrailsCameraChangedWarningOff: (state: UIState) => {
      state.showBedrailsCameraChangedWarning = false;
    }
  }
});

export const {
  setShowBedrailsPTZChangedWarningOn,
  setShowBedrailsPTZChangedWarningOff,
  setShowBedrailsCameraChangedWarningOn,
  setShowBedrailsCameraChangedWarningOff
} = uiStateSlice.actions;

export const selectNotificationCameraUuid = (state: RootState): string => state.uiState.cameraUuid;
export const selectShowBedrailsPTZChangedWarning = (state: RootState): boolean =>
  state.uiState.showBedrailsPTZChangedWarning;
export const selectShowBedrailsCameraChangedWarning = (state: RootState): boolean =>
  state.uiState.showBedrailsCameraChangedWarning;

export default uiStateSlice.reducer;
