/* eslint max-len: 0 */
import * as React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export const WarningCircleIcon = createSvgIcon(
  <g>
    <svg width='24' height='24' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1049_72594)'>
        <path
          d='M20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35Z'
          stroke='current'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M20 13.334V20.0007' stroke='current' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M20 26.666H20.0167' stroke='current' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_1049_72594'>
          <rect width='40' height='40' fill='currentColor' />
        </clipPath>
      </defs>
    </svg>
  </g>,
  'Warning Circle Icon'
);
