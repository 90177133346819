import { BackendError } from 'src/errors/BackendError';
import { addSessionToken, requestUpdateToken } from '../../api/auth';

interface IDefaultRequestParams {
  headers?: Record<string, string>;
  body: BodyInit | object;
}

class Api {
  private _baseURL: string;
  private _headers: Headers;

  constructor(settings?: { baseURL?: string; headers?: Record<string, string> }) {
    this._baseURL = settings?.baseURL || '';
    this._headers = this._setJwtTokenHeader(settings?.headers);
  }

  private _setJwtTokenHeader(params?: Record<string, string>) {
    const accessToken = sessionStorage.getItem(`${process.env.REACT_APP_IAS_ACCESS_TOKEN}`);
    const guestToken = sessionStorage.getItem(`${process.env.REACT_APP_GUEST_ACCESS_TOKEN}`);
    const authToken = accessToken ? accessToken : guestToken;
    const jwtHeader: Record<string, string> = {
      Authorization: `Bearer ${authToken}`,
      ...params
    };
    return this._setHeaders(jwtHeader);
  }

  private _setHeaders(params?: Record<string, string>) {
    const headers = new Headers(this._headers);

    for (const prop in params) {
      const val = params[prop];

      headers.set(prop, val);
    }

    return headers;
  }

  private async _parseBodyResponse<R>(res: Response): Promise<R> {
    const contentType = res.headers.get('content-type');
    const text = (await res.text()) as any;
    let data: any = null;

    if (contentType?.includes('text/plain')) {
      if (res.ok) {
        return text as R;
      } else {
        throw new BackendError(res.statusText, res.status, null);
      }
    }

    try {
      data = JSON.parse(text);
    } catch (err) {
      throw new BackendError(text, res.status, null);
    }

    if (!res.ok) {
      throw new BackendError(res.statusText, res.status, data);
    }

    return data as R;
  }

  private static getNewHeaderToken(headers: HeadersInit | undefined, accessToken: string) {
    const tokenHeader: Record<string, string> = {
      Authorization: `Bearer ${accessToken}`
    };
    const newRequestHeader: HeadersInit = {
      ...headers,
      ...tokenHeader
    };
    return newRequestHeader;
  }

  private static async updateTokenAndRequestRetry(input: RequestInfo | URL, init?: RequestInit) {
    const tokenInfo = await requestUpdateToken();
    addSessionToken(tokenInfo.access_token, tokenInfo.refresh_token);
    const newInit: RequestInit = { ...init };
    newInit.headers = this.getNewHeaderToken(init?.headers, tokenInfo.access_token);
    return await fetch(input, newInit);
  }

  private static async fetchRequest(input: RequestInfo | URL, init?: RequestInit) {
    const res = await fetch(input, init);
    if (res.status === 401) {
      return await this.updateTokenAndRequestRetry(input, init);
    }
    return res;
  }

  private _getUrl(path: string): string {
    if (path.startsWith('/api/')) {
      return this._baseURL + path.substring(4);
    } else {
      return this._baseURL + path;
    }
  }

  async initHeaderToken() {
    this._headers = this._setJwtTokenHeader();
  }

  async get<R = unknown>(path: string, params?: Omit<IDefaultRequestParams, 'body'>) {
    const res = await Api.fetchRequest(this._getUrl(path), {
      method: 'GET',
      headers: this._setHeaders(params?.headers)
    });

    const body = await this._parseBodyResponse<R>(res);

    return { body, headers: res.headers };
  }

  async post<R = unknown>(path: string, params: IDefaultRequestParams) {
    const res = await Api.fetchRequest(this._getUrl(path), {
      method: 'POST',
      body: JSON.stringify(params.body),
      headers: this._setHeaders(params.headers)
    });

    const body = await this._parseBodyResponse<R>(res);

    return { body, headers: res.headers };
  }

  async patch<R = unknown>(path: string, params: IDefaultRequestParams) {
    const res = await Api.fetchRequest(this._getUrl(path), {
      method: 'PATCH',
      body: JSON.stringify(params.body),
      headers: this._setHeaders(params.headers)
    });

    const body = await this._parseBodyResponse<R>(res);

    return { body, headers: res.headers };
  }

  async delete<R = unknown>(path: string, params?: Omit<IDefaultRequestParams, 'body'>) {
    const res = await Api.fetchRequest(this._getUrl(path), {
      method: 'DELETE',
      headers: this._setHeaders(params?.headers)
    });

    const body = await this._parseBodyResponse<R>(res);

    return { body, headers: res.headers };
  }

  async put<R = unknown>(path: string, params: IDefaultRequestParams) {
    const res = await Api.fetchRequest(this._getUrl(path), {
      method: 'PUT',
      body: JSON.stringify(params.body),
      headers: this._setHeaders(params.headers)
    });

    const body = await this._parseBodyResponse<R>(res);

    return { body, headers: res.headers };
  }

  async postFormData<R = unknown>(path: string, fdata: FormData) {
    const res = await Api.fetchRequest(this._getUrl(path), {
      method: 'POST',
      body: fdata,
      headers: this._setHeaders()
    });

    const body = await this._parseBodyResponse<R>(res);

    return { body, headers: res.headers };
  }
}

export const api = new Api({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  headers: { 'Content-Type': 'application/json; charset=utf-8' }
});

export { Api };
export default api;
