/* eslint max-len: 0 */
import * as React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export const VrMicIcon = createSvgIcon(
  <g>
    <svg width='24' height='24' viewBox='0 0 58 58' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M36.25 12.084C36.25 8.07992 33.0041 4.83398 29 4.83398C24.9959 4.83398 21.75 8.07992 21.75 12.084V24.1673C21.75 28.1714 24.9959 31.4173 29 31.4173C33.0041 31.4173 36.25 28.1714 36.25 24.1673V12.084Z'
        fill='currentColor'
        stroke='current'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.083 24.166C12.083 28.6526 13.8653 32.9554 17.0378 36.1279C20.2103 39.3004 24.5131 41.0827 28.9997 41.0827C33.4863 41.0827 37.7891 39.3004 40.9616 36.1279C44.1341 32.9554 45.9163 28.6526 45.9163 24.166'
        stroke='current'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M19.333 50.75H38.6663' stroke='current' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M29 41.084V50.7507' stroke='current' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </g>,
  'Vr MicIcon Icon'
);
