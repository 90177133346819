import React, { useCallback, useEffect } from 'react';
import styles from '../styles/CallEnded.module.css';
import { DeviceRouteNames } from 'src/features/devices/router/route-names';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { RootState } from '../../../reducers/root';
import { NotAvailableIcon } from 'src/assets/icons/NotAvailableIcon';
import { CallEndIcon } from 'src/assets/icons/CallEndIcon';
import { WarningCircleIcon } from 'src/assets/icons/WarningCircleIcon';
import { useTheme } from '@mui/material/styles';
import { AuthRouteNames } from '../../auth/router';
import { CarepointRouteNames } from '../../carepoint/router';

const CallEnded: React.FC<EmptyProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { didVisitDeviceTable } = useSelector((state: RootState) => state.settings);
  const { palette } = useTheme();
  const isDarkMode = palette.mode === 'dark';
  const isMobile = useSelector((state: RootState) => state.settings.browserType) === 'mobile';

  const { endCallScreenIcon, endCallScreenMessage, endCallScreenParticipant } = useSelector(
    (state: RootState) => state.vrcall
  );

  useEffect(() => {
    dispatch({ type: 'CLEAR_WEBSOCKETS' });
  }, [dispatch]);

  useEffect(() => {
    if (!endCallScreenParticipant) {
      console.log('endcall screen participant is null');
      setTimeout(() => {
        navigate(AuthRouteNames.Main);

        window.parent.postMessage(
          JSON.stringify({
            event: 'call_ended'
            //callType: 'esitter'
            // TODO: might need to be re-added later (maybe)
            // in that case it needs to be added to a common VR+eSitter Redux
          }),
          '*'
        );

        const shouldReload = localStorage.getItem('@should-reload');
        if (shouldReload && JSON.parse(shouldReload) === true) {
          localStorage.setItem('@should-reload', JSON.stringify(false));
          window.location.reload();
        }
      }, 5000);
    }
  });

  const handleIcon = useCallback(() => {
    if (endCallScreenIcon === 'notConnected') {
      return (
        <WarningCircleIcon
          aria-label={'Warning Circle'}
          fill={palette.mode === 'dark' ? '#FFFFFF' : '#111111'}
          stroke={palette.mode === 'dark' ? '#FFFFFF' : '#111111'}
          style={{ width: '40px', height: '40px' }}
        />
      );
    } else if (endCallScreenIcon === 'notAvailable') {
      return (
        <NotAvailableIcon
          aria-label={'Not Available'}
          fill={palette.mode === 'dark' ? '#FFFFFF' : '#111111'}
          stroke={palette.mode === 'dark' ? '#FFFFFF' : '#111111'}
          style={{ width: '40px', height: '40px' }}
        />
      );
    } else {
      return (
        <CallEndIcon
          aria-label={'Call End'}
          stroke={palette.mode === 'dark' ? '#FFFFFF' : '#111111'}
          style={{ width: '40px', height: '40px' }}
        />
      );
    }
  }, [endCallScreenIcon]);

  return (
    <Box className={styles.container} sx={{ backgroundColor: palette.mode === 'dark' ? 'black' : '#BBBBBB' }}>
      {endCallScreenIcon ? (
        typeof endCallScreenIcon === 'string' ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: palette.mode === 'dark' ? '#333333' : '#FFFFFF',
              borderRadius: '50%',
              padding: '15px'
            }}
          >
            {handleIcon()}
          </Box>
        ) : (
          <CallEndIcon
            aria-label={'Call End'}
            stroke={palette.mode === 'dark' ? '#FFFFFF' : '#111111'}
            style={{
              width: '72px',
              height: '72px',
              borderRadius: '50%',
              padding: '15px',
              backgroundColor: isDarkMode ? '#333333' : '#FFFFFF'
            }}
          />
        )
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: palette.mode === 'dark' ? '#333333' : '#FFFFFF',
            borderRadius: '50%',
            padding: '15px'
          }}
        >
          {handleIcon()}
        </Box>
      )}
      <p
        className={styles.title}
        style={{ color: palette.mode === 'dark' ? 'white' : 'black' }}
        dangerouslySetInnerHTML={{
          __html: endCallScreenMessage
        }}
      />
    </Box>
  );
};

export default CallEnded;
