import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';

interface SaveButtonProps {
  disabled: boolean;
  loading: boolean;
  loaded: boolean;
  onClick: () => void;
}

export const SaveButton = React.memo((props: SaveButtonProps): React.ReactElement => {
  const { loaded, loading, onClick, disabled } = props;
  const { t } = useTranslation();
  return (
    <LoadingButton
      onClick={onClick}
      startIcon={loaded ? <CheckIcon /> : undefined}
      fullWidth
      variant={'contained'}
      loading={loading}
      loadingPosition='start'
      disabled={disabled}
      sx={{
        padding: '12px 0',
        borderRadius: '6px',
        backgroundColor: '#297859',
        transition: 'background-color 0.2s ease-in-out',
        '&:hover': {
          backgroundColor: '#297859',
          opacity: 0.8
        },
        '&:disabled': {
          backgroundColor: loading ? '#297859' : '#777777',
          color: '#fff'
        }
      }}
    >
      {loading
        ? t('bedObservation.rightPanel.savingButton')
        : loaded
        ? t('bedObservation.rightPanel.savedButton')
        : t('bedObservation.rightPanel.saveButton')}
    </LoadingButton>
  );
});
