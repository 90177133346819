import { AddDevice, Call, IDeviceRoomInfo, IDeviceCapabilities, IAddDeviceToEsitter } from 'src/@types/provider';
import { User } from 'src/@types/devices';
import { IRoomInfo } from 'src/@types/embed';
import { BackendError, isBackendError } from 'src/errors/BackendError';
import api from 'src/services/api/api';

export async function addDevice(callId: string, deviceUuid: string): Promise<IAddDeviceToEsitter | BackendError> {
  try {
    const res = await api.post<FetchResponse<RoomInfoData<IRoomInfo>>>(`/api/calls/${callId}/device`, {
      body: {
        participantUuid: deviceUuid,
        mode: 'OBSERVATION_V',
        forceMode: true
      }
    });

    if (!res.body.success) {
      return new BackendError('api failure', 200, res.body);
    }

    const roomInfo = res.body.data.roomInfo;
    const addedDevice = roomInfo.participants.filter(p => {
      return p.uuid === deviceUuid;
    });
    if (addedDevice.length < 1) {
      return new BackendError('unexpected error, matched participant not found', 200, res.body);
    }

    return {
      deviceId: deviceUuid,
      deviceName: addedDevice[0].metadata?.displayName || '',
      mode: (addedDevice[0].callMode.toLocaleLowerCase() as TMode) || 'observation_v',
      room: roomInfo.roomSid,
      token: roomInfo.roomToken,
      success: true,
      deviceCapabilities: [
        {
          name: 'VirtualBedRails',
          id: 1000,
          version: 1,
          available: true
        }
      ]
    } as IAddDeviceToEsitter;
  } catch (error) {
    return error as BackendError;
  }
}

export async function removeDevice(callId: string, deviceId: string): Promise<SimpleResponse | BackendError> {
  try {
    const res = await api.delete<SimpleResponse>(`/api/calls/${callId}/devices/${deviceId}`);

    return res.body;
  } catch (error) {
    return error as BackendError;
  }
}

export async function changeMode(
  callId: string,
  deviceId: string,
  data: { mode: string; forceMode: boolean; skipPatientPrompt?: boolean }
): Promise<string | BackendError> {
  try {
    data.mode = data.mode?.toUpperCase();
    const res = await api.patch<string>(`/api/calls/${callId}/devices/${deviceId}`, {
      body: data
    });

    return res.body;
  } catch (error) {
    return error as BackendError;
  }
}

export async function stopCall(callId: string): Promise<string | BackendError> {
  try {
    const res = await api.delete<string>(`/api/calls/${callId}`);

    return res.body;
  } catch (error) {
    return error as BackendError;
  }
}

export async function removeUser(callId: string, userId: string): Promise<string | BackendError> {
  try {
    const res = await api.delete<string>(`/api/calls/${callId}/participant/${userId}`);

    return res.body;
  } catch (error) {
    return error as BackendError;
  }
}

export async function makeCallActive(callId: string): Promise<Call | BackendError> {
  try {
    const res = await api.patch<FetchResponse<RoomInfoData<IRoomInfo[]>>>(`/api/calls/${callId}`, {
      body: { status: 'activate' }
    });

    if (!res.body.success) {
      return new BackendError('api failure', 200, res.body);
    }

    const roomInfo = res.body.data.roomInfo[0];
    return {
      callUuid: callId,
      deviceRooms: roomInfo.participants
        .filter(p => {
          return p.userType.toUpperCase() === 'DEVICE';
        })
        .map(
          p =>
            ({
              deviceId: p.uuid,
              deviceName: p.metadata.displayName,
              mode: p.callMode,
              facility: p.metadata.facility,
              room: roomInfo.roomSid, // twilio room id
              token: roomInfo.roomToken, // twilio token
              deviceCapabilities: [] as IDeviceCapabilities[]
            } as IDeviceRoomInfo)
        )
    };
  } catch (error) {
    return error as BackendError;
  }
}

export async function getCallableDevicesList({
  callId,
  pageSize = 100,
  page = 1
}: {
  callId: string;
  pageSize?: number;
  page?: number;
}): Promise<AddDevice[] | BackendError> {
  try {
    const res = await api.get<FetchResponse<DeviceListData<User[]>>>(
      `/api/users/callableDevices?itemPerPage=${pageSize}&pageNum=${page}`
    );

    if (res.body.success) {
      const data: DeviceListData<User[]> = res.body.data;
      const converted: AddDevice[] = data.devices.map(d => ({
        uuid: d.uuid,
        availability: d.isOnline ? (d.inCall ? 'busy' : 'online') : 'offline',
        name: d.metadata?.displayName,
        tenant: '',
        facility: d.metadata?.facility
      }));
      return converted;
    }
  } catch (error) {
    return error as BackendError;
  }
  return [];
}

/*** MAKE A HOST ***/
export async function makeParticipantHost(callId: string, uuid: string): Promise<string | BackendError> {
  console.log('Assigning Host status to participant: ', uuid);
  try {
    const res = await api.patch<string>(`/api/calls/${callId}/participant/${uuid}`, {
      body: { host: 'true' }
    });

    console.log('Participant ', uuid, ' was successfully made Host');

    return res.body;
  } catch (error) {
    if (isBackendError(error)) {
      console.log(`Error: ${error.message}`);
    }

    return error as BackendError;
  }
}
