/* eslint max-len: 0 */
import * as React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export const LogoutIcon = createSvgIcon(
  <g>
    <svg width='20' height='20' viewBox='0 0 20 20' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.494 13.6423C14.3365 13.7998 14.0672 13.6882 14.0672 13.4655V11.5863H8.77411C8.49797 11.5863 8.27411 11.3624 8.27411 11.0863V8.77596C8.27411 8.49982 8.49797 8.27596 8.77411 8.27596H14.0672V6.39676C14.0672 6.17403 14.3365 6.06249 14.494 6.21998L17.8516 9.57758C18.0469 9.77284 18.0469 10.0894 17.8516 10.2847L14.494 13.6423ZM12.412 1.65527C12.851 1.65527 13.272 1.82966 13.5824 2.14006C13.8928 2.45047 14.0672 2.87147 14.0672 3.31045V4.46562C14.0672 4.74176 13.8434 4.96562 13.5672 4.96562H12.912C12.6359 4.96562 12.412 4.74176 12.412 4.46562V3.81045C12.412 3.5343 12.1882 3.31045 11.912 3.31045H5.46377C5.18762 3.31045 4.96377 3.5343 4.96377 3.81045V16.0518C4.96377 16.328 5.18762 16.5518 5.46377 16.5518H11.912C12.1882 16.5518 12.412 16.328 12.412 16.0518V15.3967C12.412 15.1205 12.6359 14.8967 12.912 14.8967H13.5672C13.8434 14.8967 14.0672 15.1205 14.0672 15.3967V16.5518C14.0672 16.9908 13.8928 17.4118 13.5824 17.7222C13.272 18.0326 12.851 18.207 12.412 18.207H4.96377C4.52479 18.207 4.10379 18.0326 3.79338 17.7222C3.48298 17.4118 3.30859 16.9908 3.30859 16.5518V3.31045C3.30859 2.87147 3.48298 2.45047 3.79338 2.14006C4.10379 1.82966 4.52479 1.65527 4.96377 1.65527H12.412Z'
        fill='currentColor'
      />
    </svg>
  </g>,
  'Logout Icon'
);
