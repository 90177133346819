import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../reducers/root';
import { AppInitialState } from '../@types/reducer';

export const selectApp = (state: RootState): AppInitialState => state.app;

export const selectMediaAccessModalVisible = createSelector([selectApp], app => app.mediaAccessModalVisible);

export const selectMediaAccessModalData = createSelector([selectApp], app => app.mediaAccessModalData);
export const selectIsMyMicActive = createSelector([selectApp], app => app.isMyMicActive);
export const selectIsMyCamActive = createSelector([selectApp], app => app.isMyCamActive);
export const selectCurrentUser = createSelector([selectApp], app => app.currentUser);
export const selectProviderAppVersion = createSelector([selectApp], app => app.providerAppVersion);
export const selectCmsVersion = createSelector([selectApp], app => app.cmsVersion);
