import React, { useEffect, useState } from 'react';
import { setContentName } from '../../../../../../actions/esitter';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { FormControlLabel, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { SidebarBackNavigation } from '../SidebarBackNavigation/SidebarBackNavigation';
import Box from '@mui/material/Box';
import { SidePanelContent } from 'src/@types/enums';
import {
  getSelectedCameraUuid,
  getSelectedDeviceId,
  selectSidePanelContentName
} from '../../../../../../selectors/esitter';
import {
  getSelectedCameraBedrailsFirstTime,
  isBoundariesSetUpForSelectedCamera,
  selectEnabled,
  selectSaveDisabled,
  selectSensitivityLevel
} from '../../../../../../selectors/bedrails';
import { RootState } from '../../../../../../reducers/root';
import { setBoundaryToDefault, setEditMode, setSaveDisabled } from '../../../../../../reducers/bedrailsSlice';
import { SaveBedrailsButton } from '../../SaveBedrailsButton/SaveBedrailsButton';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

export enum VirtualBedrailsSensitivityLevel {
  HIGH = 'high',
  MEDUIM = 'medium',
  LOW = 'low'
}

export const VirtualBedrailsContent = (): JSX.Element => {
  const { browserType } = useSelector((state: RootState) => state.settings);
  const isMobile = browserType === 'mobile';
  const contentName = useSelector(selectSidePanelContentName);

  const selectedDeviceId = useSelector(getSelectedDeviceId);
  const isBoundariesSetUp = useSelector(isBoundariesSetUpForSelectedCamera);
  const selectedCameraUuid = useSelector(getSelectedCameraUuid);
  const boundaryEnabled = useSelector((state: RootState) => selectEnabled(state, selectedCameraUuid));
  const bedrailsSaveDisabled = useSelector((state: RootState) => selectSaveDisabled(state, selectedCameraUuid));
  const boundaryFirstTime = useSelector(getSelectedCameraBedrailsFirstTime);
  const boundarySensitivityLevel = useSelector((state: RootState) => selectSensitivityLevel(state, selectedCameraUuid));

  const [switchEnabled, setSwitchEnabled] = useState(boundaryEnabled || boundaryFirstTime);
  const [sensitivityLevel, setSensitivityLevel] = useState<VirtualBedrailsSensitivityLevel>(boundarySensitivityLevel);

  const dispatch = useDispatch();
  const {
    palette: { mode }
  } = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setEditMode({ cameraUuid: selectedCameraUuid, editMode: true }));
    return () => {
      dispatch(setEditMode({ cameraUuid: selectedCameraUuid, editMode: false }));
    };
  }, [dispatch, selectedCameraUuid, selectedDeviceId]);

  useEffect(() => {
    if (!isBoundariesSetUp) {
      dispatch(setBoundaryToDefault({ cameraUuid: selectedCameraUuid, deviceId: selectedDeviceId }));
    }
  }, [dispatch, isBoundariesSetUp, selectedCameraUuid, selectedDeviceId]);

  const onBackClick = () => {
    if (bedrailsSaveDisabled) {
      dispatch(setContentName(SidePanelContent.PATIENT_DETAILS));
    } else {
      dispatch(setContentName(SidePanelContent.UNSAVED_CHANGES_WARNING));
    }
  };

  const handleEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchEnabled(event.target.checked);

    if (bedrailsSaveDisabled) {
      dispatch(setSaveDisabled({ cameraUuid: selectedCameraUuid, disabled: false }));
    }
  };

  const handleSensitivityLevelChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setSensitivityLevel((value as VirtualBedrailsSensitivityLevel) || VirtualBedrailsSensitivityLevel.MEDUIM);

    if (bedrailsSaveDisabled) {
      dispatch(setSaveDisabled({ cameraUuid: selectedCameraUuid, disabled: false }));
    }
  };

  return (
    <Box
      sx={{
        marginTop: isMobile && contentName === SidePanelContent.VIRTUAL_BEDRAILS ? '56.25%' : 0,
        padding: '24px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {/*<SidebarBackNavigation title={'Patient Details'} onClick={onBackClick} />*/}
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: '24px',
          marginBottom: '24px',
          borderBottom: '1px dotted #666666'
        }}
      >
        <Typography variant={'subtitle1'}>{t('bedObservation.rightPanel.virtualBedrail.title')}</Typography>
        <Switch checked={switchEnabled} onChange={handleEnabledChange} />
      </Stack>
      <Stack spacing={6} sx={{ flexGrow: 1, color: mode === 'dark' ? '#BBBBBB' : '#111111' }}>
        <Typography variant={'body1'} sx={{ whiteSpace: 'pre-line' }}>
          {t('bedObservation.rightPanel.virtualBedrail.description')}
        </Typography>
      </Stack>
      <SaveBedrailsButton enable={switchEnabled} disabled={bedrailsSaveDisabled} sensitivityLevel={sensitivityLevel} />
    </Box>
  );
};
