import { useWebsocketHelper } from '../../../wsHelper';
import { PolygonPoint } from '../../../@types/reducer';
import { useCallback } from 'react';
import { VirtualBedrailsSensitivityLevel } from '../components/SidePanel/content/VirtualBedrailsContent/VirtualBedrailsContent';

export enum wsCommandMode {
  ABSOLUTE = 'absolute',
  RELATIVE = 'relative',
  HOME = 'home',
  REQUEST_STATE = 'requestState',
  STORE_HOME_POSITION = 'storeHomePosition',
  VIRTUAL_BEDRAILS_HOME = 'virtualBedRailsHome'
}

interface UseEsitterWSMessages {
  sendDeviceFeccStateWSMessage: (commandMode: wsCommandMode, data: object) => void;
  sendUpdateVirtualBedRailsEnabledWSMessage: (
    cameraUuid: string,
    enable: boolean,
    sensitivityLevel?: VirtualBedrailsSensitivityLevel,
    polygon?: PolygonPoint[]
  ) => void;
}

export const useEsitterWSMessages = (deviceId: string, callId: string): UseEsitterWSMessages => {
  const { sendWsMessage } = useWebsocketHelper();

  const sendDeviceFeccStateWSMessage = useCallback(
    (commandMode: wsCommandMode, data = {}): void =>
      sendWsMessage({
        event: 'deviceFeccState',
        recipientType: 'DEVICE',
        recipientUuid: deviceId,
        callUuid: callId,
        data: {
          stateType: 'desired',
          commandMode: commandMode,
          ...data
        }
      }),
    [callId, deviceId, sendWsMessage]
  );

  const sendUpdateVirtualBedRailsEnabledWSMessage = useCallback(
    (
      cameraUuid: string,
      enable: boolean,
      sensitivityLevel?: VirtualBedrailsSensitivityLevel,
      polygon?: PolygonPoint[]
    ): void => {
      const messageObj = {
        event: 'deviceVirtualBedRailsState',
        recipientType: 'DEVICE',
        recipientUuid: deviceId,
        callUuid: callId,
        data: {
          stateType: 'desired',
          cameraUuid: cameraUuid,
          enabled: enable,
          sensitivityLevel: sensitivityLevel || null,
          polygon: polygon || null
        }
      };
      return sendWsMessage(messageObj);
    },
    [callId, deviceId, sendWsMessage]
  );

  return {
    sendDeviceFeccStateWSMessage,
    sendUpdateVirtualBedRailsEnabledWSMessage
  };
};
