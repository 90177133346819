import { Action, AppInitialState } from 'src/@types/reducer';
import packageJson from '../../package.json';

const initialState: AppInitialState = {
  providerAppVersion: `${packageJson.version}`,
  cmsVersion: '0.0.0',
  myMicDisabled: false,
  myCamDisabled: false,
  isMyMicActive: false,
  isMyCamActive: false,
  // Shared by VR and eSitter to display errors related to mic/camera access
  mediaAccessModalVisible: false,
  mediaAccessModalData: {
    title: '',
    MuiSvgIcon: undefined,
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    onSecondaryBtnClick: undefined,
    onPrimaryBtnClick: undefined,
    hideCloseBtn: undefined,
    onClose: undefined
  },
  currentUser: {
    host: false,
    metadata: {
      displayName: '',
      imageUrl: '',
      specialty: ''
    },
    uuid: ''
  }
};

const appReducer = (state = initialState, action: Action<any>): AppInitialState => {
  switch (action.type) {
    case 'SET_MY_MIC_DISABLED': {
      return {
        ...state,
        myMicDisabled: true
      };
    }
    case 'RESET_MY_MIC_DISABLED': {
      return {
        ...state,
        myMicDisabled: false
      };
    }
    case 'SET_MY_CAM_DISABLED': {
      return {
        ...state,
        myCamDisabled: true
      };
    }
    case 'RESET_MY_CAM_DISABLED': {
      return {
        ...state,
        myCamDisabled: false
      };
    }
    case 'SET_MY_MIC_IS_ACTIVE': {
      return {
        ...state,
        isMyMicActive: true
      };
    }
    case 'RESET_MY_MIC_IS_ACTIVE': {
      return {
        ...state,
        isMyMicActive: false
      };
    }
    case 'SET_MY_CAM_IS_ACTIVE': {
      return {
        ...state,
        isMyCamActive: true
      };
    }
    case 'RESET_MY_CAM_IS_ACTIVE': {
      return {
        ...state,
        isMyCamActive: false
      };
    }
    case 'SET_CMS_VERSION': {
      return { ...state, cmsVersion: action.payload };
    }
    case 'SET_APP_MEDIA_ACCESS_MODAL_VISIBLE': {
      if (action.payload) {
        return { ...state, mediaAccessModalVisible: action.payload };
      }
      return {
        ...state,
        mediaAccessModalVisible: false,
        mediaAccessModalData: { ...initialState.mediaAccessModalData }
      };
    }
    case 'SET_APP_MEDIA_ACCESS_MODAL_DATA': {
      return {
        ...state,
        mediaAccessModalData: {
          title: action.payload?.title || '',
          MuiSvgIcon: action.payload?.MuiSvgIcon,
          MuiSvgIconStyle: action.payload?.MuiSvgIconStyle,
          description: action.payload?.description || '',
          primaryBtnName: action.payload?.primaryBtnName || '',
          secondaryBtnName: action.payload?.secondaryBtnName || '',
          onSecondaryBtnClick: action.payload?.onSecondaryBtnClick,
          onPrimaryBtnClick: action.payload?.onPrimaryBtnClick,
          hideCloseBtn: action.payload?.hideCloseBtn,
          onClose: action.payload?.onClose,
          disablePrimaryBtnAutoClose: action.payload?.disablePrimaryBtnAutoClose
        }
      };
    }
    case 'SET_MY_USER_ID': {
      return { ...state, currentUser: action.payload };
    }

    default:
      return { ...state };
  }
};

export default appReducer;
