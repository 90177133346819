/* eslint max-len: 0 */
import * as React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export const CheckOnDarkIcon = createSvgIcon(
  <g>
    <svg width='24' height='24' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='15' cy='15' r='14' fill='#297859' stroke='#297859' strokeWidth='2' />
      <g clipPath='url(#clip0_147_10891)'>
        <path
          d='M8.5835 15L13.1668 19.5833L22.3335 10.4167'
          stroke='white'
          strokeWidth='2.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_147_10891'>
          <rect width='22' height='22' fill='white' transform='translate(4 4)' />
        </clipPath>
      </defs>
    </svg>
  </g>,
  'Check On Light'
);
