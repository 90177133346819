import { Action, UserAccountState } from 'src/@types/reducer';

const initialState: UserAccountState = {
  account: undefined,
  profile: {
    fhirID: '',
    partnerCode: '',
    role: 'none',
    tenantCode: ''
  },
  uuid: undefined
};
const authReducer = (state = initialState, action: Action<any>): UserAccountState => {
  switch (action.type) {
    case 'SET_ACCOUNT':
      return {
        ...state,
        account: action.payload.account,
        profile: action.payload.profile,
        uuid: action.payload.uuid
      };
    default:
      return state;
  }
};
export default authReducer;
