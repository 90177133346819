import React from 'react';
import Participant from '../screens/Participant';
import ParticipantEmpty from '../screens/ParticipantEmpty';
import Begin from '../screens/Begin';
import CallEnded from '../screens/CallEnded';
import { EmbedRouteNames } from './route-names';

export const EmbedRoutes = [
  {
    path: `${EmbedRouteNames.Participant}/:callId/:otpToken`,
    element: <Participant />
  },
  {
    path: EmbedRouteNames.Participant,
    element: <ParticipantEmpty />
  },
  {
    path: `${EmbedRouteNames.Begin}/:callUuid`,
    element: <Begin />
  },
  {
    path: EmbedRouteNames.CallEnded,
    element: <CallEnded />
  }
];
