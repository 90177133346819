export enum EBrowserType {
  DESKTOP = 'desktop',
  MOBILE = 'mobile'
}

export enum EButtonControlSize {
  EXTRALARGE = 'EXTRALARGE',
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL'
}

export enum EButtonControlColor {
  BLUE = 'BLUE',
  GRAY = 'GRAY',
  RED = 'RED',
  WHITE = 'WHITE'
}

export enum EToastStatus {
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  STARTINTERACTION = 'startinteraction',
  MUTEALL = 'muteall',
  MAKEHOST = 'makehost',
  OFFLINE = 'offline',
  LOADING = 'loading',
  PARTICIPANTJOIN = 'participantjoin',
  PARTICIPANTLEAVE = 'participantleave',
  INVITEPARTICIPANT = 'inviteparticipant'
}

export enum DeviceStatus {
  ACTIVE = 'active',
  PENDING = 'pending'
}

export enum DeviceAvailability {
  ONLINE = 'online',
  USED = 'used',
  OFFLINE = 'offline'
}

export enum ESortType {
  ATOZ = 'NAME A to Z',
  ZTOA = 'NAME Z to A',
  TAG = 'TAG'
}

export enum ETagColor {
  LEVEL1 = '#A30909',
  LEVEL2 = '#E7B918',
  LEVEL3 = '#297859',
  NONE = '#888888'
}

export enum ETagColorName {
  RED = 'RED',
  YEL = 'YELLOW',
  GRN = 'GREEN',
  NONE = 'NONE'
}

export enum ETagColorFullName {
  RED = 'RED',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  NONE = 'NONE'
}
export enum SidePanelContent {
  MY_PATIENTS = 'my_patients',
  ADD_PATIENT = 'add_patient',
  PATIENT_DETAILS = 'patient_details',
  VIRTUAL_BEDRAILS = 'virtual_bedrails',
  UNSAVED_CHANGES_WARNING = 'unsaved_changes_warning',
  FOCUS_VIEW = 'focus_view'
}
