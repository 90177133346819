// TODO: will be beater use here unknown instead of any
export class BackendError<E = any> extends Error {
  payload?: E;
  status: number;

  constructor(message: string, status: number, payload?: E) {
    super(message);
    this.name = 'BackendError';
    this.payload = payload;
    this.status = status;
  }
}

export function isBackendError(err: unknown): err is BackendError {
  return err instanceof BackendError;
}

export function isOk<T>(err: unknown): err is T {
  return !(err instanceof BackendError);
}
