import i18n from '../i18n';
import { store } from './store';
import AppRouter from './Router';
import React, { useEffect } from 'react';
import { CssBaseline } from '@mui/material';
import { RootState } from 'src/reducers/root';
import { initialPalette } from '../theme/palette';
import { CheckOnDarkIcon } from '../assets/icons/CheckOnDarkIcon';
import { CheckOnLightIcon } from '../assets/icons/CheckOnLightIcon';
import { CheckOffDarkIcon } from '../assets/icons/CheckOffDarkIcon';
import { CheckOffLightIcon } from '../assets/icons/CheckOffLightIcon';
import { componentsOverrides } from '../theme/components';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { setFrameRate, setThemeSettingType } from '../actions/setup';
import { QueryClient, QueryClientProvider } from 'react-query';
import { typography } from '../theme/typography';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {
    /** */
  };
}

const App: React.FC<EmptyProps> = () => {
  const dispatch = useDispatch();
  const { frameRate, themeSettingType, theme, i18nextLng: language } = useSelector((state: RootState) => state.setup);
  const { t, i18n } = useTranslation();
  const { browserType } = useSelector((state: RootState) => state.settings);
  const isMobile = browserType === 'mobile';

  useEffect(() => {
    dispatch(setFrameRate(frameRate || false));
  }, [dispatch, frameRate]);

  useEffect(() => {
    dispatch(setThemeSettingType(themeSettingType || 'dark'));
  }, [dispatch, themeSettingType]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (!storedTheme) {
      localStorage.setItem('theme', 'dark');
    } else {
      dispatch({ type: 'SET_THEME', payload: storedTheme });
    }
  }, [dispatch]);

  const themeType = createTheme({
    palette: {
      ...initialPalette,
      mode: theme,
      ...(theme === 'light' ? { primary: { main: '#1F4033' } } : { primary: { main: '#297859' } })
    },
    typography: {
      ...typography
    },
    components: {
      ...componentsOverrides,
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            overflow: browserType === 'desktop' ? 'hidden' : 'auto'
          }
        }
      },
      MuiRadio: {
        defaultProps: {
          icon:
            theme === 'dark' ? (
              <CheckOffDarkIcon aria-label={'check off'} />
            ) : (
              <CheckOffLightIcon aria-label={'check off'} />
            ),
          checkedIcon:
            theme === 'dark' ? (
              <CheckOnDarkIcon aria-label={'check on'} />
            ) : (
              <CheckOnLightIcon aria-label={'check on'} />
            )
        }
      }
    }
  });

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  return (
    <>
      <Helmet>
        <title>{t('meta.title')}</title>
        <meta name='description' content={t('meta.description')} />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={themeType}>
          <CssBaseline />
          <div className='App' style={{ overflow: isMobile ? 'hidden' : 'auto' }}>
            <AppRouter />
          </div>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
};

const AppWithContext: React.FC<EmptyProps> = () => (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </I18nextProvider>
  </Provider>
);

export default AppWithContext;
