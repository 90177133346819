import { Action, EsitterInitialState } from 'src/@types/reducer';
import { IDeviceRoomInfo } from 'src/@types/provider';
import { ESortType } from 'src/@types/enums';
import { SidePanelContent } from 'src/@types/enums';
import { DUMMY_ROOM_LIST } from '../config';

const initialState: EsitterInitialState = {
  callId: '',
  roomsArray: [],
  selectedDevice: undefined,
  hoveredDeviceId: '',
  isCameraControlActive: false,
  isAudioControlActive: false,
  isPendingRemoval: false, //is the selected device pending confirmation for removal
  contentName: SidePanelContent.MY_PATIENTS,
  prevContentName: SidePanelContent.MY_PATIENTS,
  modalVisible: false,
  modalData: {
    title: '',
    MuiSvgIcon: undefined,
    MuiSvgIconStyle: undefined,
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    onSecondaryBtnClick: undefined,
    onPrimaryBtnClick: undefined,
    onClose: undefined
  },
  patientDetailsVisible: false,
  sidePanelCollapsed: false,
  sortType: ESortType.ATOZ,
  interventionConnectionStatus: {
    connecting: false,
    disconnecting: false,
    deviceId: null
  }
};

const esitterReducer = (state = initialState, action: Action<any>): EsitterInitialState => {
  // console.log('esitterReducer action.type=', action.type);
  switch (action.type) {
    case 'SET_ROOMS': {
      return { ...state, roomsArray: action.payload };
    }
    case 'REMOVE_ROOM_BY_DEVICE_ID': {
      const newRoomsArray = state.roomsArray.filter((d: IDeviceRoomInfo) => d.deviceId !== action.payload);
      return { ...state, roomsArray: newRoomsArray };
    }
    case 'SET_ROOM_MODE': {
      state.roomsArray.forEach((room: any) => {
        if (room.deviceId === action.payload.id) {
          room.mode = action.payload.mode;
        }
      });
      const newState = { ...state, roomsArray: [...state.roomsArray] };
      if (state.selectedDevice && action.payload.id === state.selectedDevice?.deviceId) {
        newState.selectedDevice = { ...state.selectedDevice, mode: action.payload.mode };
      }
      return newState;
    }
    case 'SET_ROOM_PAUSED': {
      state.roomsArray.forEach((room: any) => {
        if (room.deviceId === action.payload.id) {
          room.paused = action.payload.paused;
        }
      });
      const newState = { ...state, roomsArray: [...state.roomsArray] };
      if (state.selectedDevice && action.payload.id === state.selectedDevice?.deviceId) {
        newState.selectedDevice = { ...state.selectedDevice, paused: action.payload.paused };
      }
      return newState;
    }
    case 'SET_ROOM_TAG_COLOR': {
      state.roomsArray.forEach((room: IDeviceRoomInfo) => {
        if (room.deviceId === action.payload.id) {
          room.tagColor = action.payload.tagColor;
        }
      });
      const newState = { ...state, roomsArray: [...state.roomsArray] };
      if (state.selectedDevice && action.payload.id === state.selectedDevice?.deviceId) {
        newState.selectedDevice = { ...state.selectedDevice, tagColor: action.payload.tagColor };
      }
      return newState;
    }
    case 'SET_ROOM_NOTE': {
      state.roomsArray.forEach((room: IDeviceRoomInfo) => {
        if (room.deviceId === action.payload.id) {
          room.note = action.payload.note;
        }
      });
      const newState = { ...state, roomsArray: [...state.roomsArray] };
      if (state.selectedDevice && action.payload.id === state.selectedDevice?.deviceId) {
        newState.selectedDevice = { ...state.selectedDevice, note: action.payload.note };
      }
      return newState;
    }
    case 'SET_FECC_VALUES': {
      state.roomsArray.forEach((room: any) => {
        if (room.deviceId === action.payload.id) {
          for (const key of Object.keys(action.payload.data)) {
            if (key !== 'stateType') {
              //stateType is just part of the reported FECC message and we don't need to save it
              room.deviceInfo[key] = action.payload.data[key];
            }
          }
        }
      });
      return { ...state, roomsArray: [...state.roomsArray] };
    }
    case 'SET_SELECTED_DEVICE': {
      return { ...state, selectedDevice: action.payload };
    }
    case 'SET_HOVERED_DEVICE': {
      return { ...state, hoveredDeviceId: action.payload };
    }
    case 'SET_IS_CAMERA_CONTROL_ACTIVE': {
      return { ...state, isCameraControlActive: action.payload };
    }
    case 'SET_IS_AUDIO_CONTROL_ACTIVE': {
      return { ...state, isAudioControlActive: action.payload };
    }
    case 'SET_PENDING_REMOVAL': {
      if (state.selectedDevice) {
        return { ...state, isPendingRemoval: action.payload };
      } else {
        return { ...state };
      }
    }
    case 'SET_SIDE_PANEL_CONTENT': {
      return { ...state, contentName: action.payload };
    }
    case 'SET_PREV_SIDE_PANEL_CONTENT': {
      return { ...state, prevContentName: action.payload };
    }
    case 'SET_CALL_ID': {
      return { ...state, callId: action.payload };
    }
    case 'SET_ESITTER_MODAL_VISIBLE': {
      if (action.payload) {
        return { ...state, modalVisible: action.payload };
      }
      return { ...state, modalVisible: action.payload, modalData: { ...initialState.modalData } };
    }
    case 'SET_ESITTER_MODAL_DATA': {
      return {
        ...state,
        modalData: {
          title: action.payload?.title || '',
          MuiSvgIcon: action.payload?.MuiSvgIcon,
          MuiSvgIconStyle: action.payload?.MuiSvgIconStyle,
          description: action.payload?.description || '',
          primaryBtnName: action.payload?.primaryBtnName || '',
          secondaryBtnName: action.payload?.secondaryBtnName || '',
          onSecondaryBtnClick: action.payload?.onSecondaryBtnClick,
          onPrimaryBtnClick: action.payload?.onPrimaryBtnClick,
          onClose: action.payload?.onClose,
          hideCloseBtn: action.payload?.hideCloseBtn
        }
      };
    }
    case 'SET_PATIENT_DETAILS_VISIBILITY': {
      return { ...state, patientDetailsVisible: action.payload };
    }
    case 'SET_SIDE_PANEL_COLLAPSED': {
      return { ...state, sidePanelCollapsed: action.payload };
    }
    case 'SET_HAS_RECEIVED_WS_MESSAGE': {
      state.roomsArray.forEach((room: IDeviceRoomInfo) => {
        if (room.deviceId === action.payload.id) {
          room.hasReceivedWSMessage = action.payload.value;
        }
      });
      return { ...state, roomsArray: [...state.roomsArray] };
    }
    case 'SET_SORT_TYPE': {
      return { ...state, sortType: action.payload };
    }
    case 'SET_STATE_BACK_TO_DEFAULT': {
      return {
        ...initialState
      };
    }
    case 'SET_INTERVENTION_CONNECTION_STATUS': {
      return {
        ...state,
        interventionConnectionStatus: {
          connecting: action.payload.connecting,
          disconnecting: action.payload.disconnecting,
          deviceId: action.payload.deviceId
        }
      };
    }

    default:
      return { ...state };
  }
};

export default esitterReducer;
