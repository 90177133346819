import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './translations/en.json';
import ko from './translations/ko.json';

const savedLanguage = localStorage.getItem('i18nextLng');

i18n
  .use(LanguageDetector)
  // Webpack async splitting solution for locale resources
  // Disabled for now for simplicity and sync-based development
  /* .use({
        type: 'backend',
        read(language, namespace, callback) {
          import(`./translations/${language}.json`)
            .then((resources) => {
              callback(null, resources);
            })
            .catch((error) => {
              callback(error, null);
            });
        },
      }) */
  .use(initReactI18next)
  .init({
    lng: savedLanguage || 'ko',
    fallbackLng: 'ko',
    resources: {
      en,
      ko
    },
    debug: true,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
