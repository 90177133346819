import React from 'react';
import { useNavigate } from 'react-router';
import { DeviceRouteNames } from 'src/features/devices/router';
import styles from '../styles/Participant.module.css';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

const Participant: React.FC = () => {
  const navigate = useNavigate();
  const { palette } = useTheme();

  return (
    <Box className={styles.container} sx={{ backgroundColor: palette.mode === 'dark' ? 'black' : '#BBBBBB' }}>
      <div className={styles.emptyBox}>
        <p className={styles.emptyTitle}>No invitation for you!</p>
        <button className={styles.joinMeeting} onClick={() => navigate(DeviceRouteNames.AdminDevices)}>
          Navigate to Devices page
        </button>
      </div>
    </Box>
  );
};

export default Participant;
