import { Action } from 'redux';

export interface SetupAction extends Action {
  type: string;
  payload: any; // eslint-disable-line
}

export const setFrameRate = (frameRate: boolean) => {
  localStorage.setItem('frameRate', String(frameRate));

  return {
    type: 'SET_FRAME_RATE',
    payload: frameRate
  };
};

export const setThemeSettingType = (themeSettingType: string) => {
  localStorage.setItem('themeSettingType', themeSettingType);

  return {
    type: 'SET_THEME_SETTING_TYPE',
    payload: themeSettingType
  };
};

export const setTheme = (theme: string) => {
  localStorage.setItem('theme', theme);

  return {
    type: 'SET_THEME',
    payload: theme
  };
};

export const setLanguage = (i18nextLng: string) => {
  localStorage.setItem('i18nextLng', i18nextLng);

  return {
    type: 'SET_LANGUAGE',
    payload: i18nextLng
  };
};
