import React from 'react';
import { FallbackPage } from 'src/components/FallbackPage/FallbackPage';
import { DeviceRouteNames } from './route-names';

const AdminDevices = React.lazy(() => import('../screens/AdminDevices'));

export const DeviceRoutes = [
  {
    path: DeviceRouteNames.AdminDevices,
    element: (
      <React.Suspense fallback={<FallbackPage />}>
        <AdminDevices />
      </React.Suspense>
    )
  }
];
