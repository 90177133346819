/* eslint max-len: 0 */
import * as React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export const CheckOffLightIcon = createSvgIcon(
  <g>
    <svg width='24' height='24' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='15' cy='15' r='14' fill='#E9E9E9' stroke='#AAAAAA' strokeWidth='2' />
      <g clipPath='url(#clip0_15_9234)'>
        <path
          d='M8.5835 14.9998L13.1668 19.5832L22.3335 10.4165'
          stroke='#BBBBBB'
          strokeWidth='2.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_15_9234'>
          <rect width='22' height='22' fill='white' transform='translate(4 4)' />
        </clipPath>
      </defs>
    </svg>
  </g>,
  'Check Off Light'
);
