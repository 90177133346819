import MUIButton from '@mui/material/Button';
import React, { useCallback } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ButtonProps } from '@mui/material/Button/Button';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { useTheme } from '@mui/material';

interface MuteButtonProps extends ButtonProps {
  onMute: (value: number) => void;
  isAlarm?: boolean;
}

interface MuteButtonOption {
  label: string;
  ms: number; // time to mute in ms
  isAlarm?: boolean;
}

export const MuteButton = React.memo((props: MuteButtonProps): JSX.Element => {
  const { onMute, isAlarm, ...otherProps } = props;
  const { t } = useTranslation();
  const {
    palette: { mode }
  } = useTheme();
  const options: MuteButtonOption[] = [
    { label: t('bedObservation.bedrailsAlarm.2minute'), ms: 120000 },
    { label: t('bedObservation.bedrailsAlarm.5minute'), ms: 300000 },
    { label: t('bedObservation.bedrailsAlarm.10minute'), ms: 600000 }
  ];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuItemClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, index: number) => {
      //   setSelectedIndex(index);
      onMute(options[index].ms);
      setAnchorEl(null);
    },
    [onMute, options]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  return (
    <>
      <MUIButton onClick={handleClick} {...otherProps} sx={{ color: mode === 'dark' ? '#FFFFFF' : '#111111' }}>
        {t('bedObservation.bedrailsAlarm.mute')}
      </MUIButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          marginTop: '10px'
        }}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
          sx: {
            padding: 0,
            backgroundColor: '#840707'
          }
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            sx={{
              position: 'relative',
              padding: '12px 36px 12px 12px',
              backgroundColor: isAlarm ? '#bc2626' : '#333333',
              color: '#FFFFFF',
              fontSize: '1.125rem',
              fontWeight: '600',
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: '50%',
                width: 'calc(100% - 24px)',
                height: '1px',
                transform: 'translateX(-50%)',
                borderWidth: '.5px',
                borderStyle: 'dashed',
                borderColor: 'rgba(204,204,204,0.05)'
              },
              '&:last-of-type': { '&:after': { borderWidth: '0' } },
              '&:hover': {
                '&:after': { display: 'none' }
              }
            }}
            key={option.ms}
            onClick={event => handleMenuItemClick(event, index)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});
