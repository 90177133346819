import { SidePanelContent } from 'src/@types/enums';
import { Action } from 'redux';
import { AppDispatch, AppThunk } from '../reducers/root';
import { IDeviceRoomInfo } from '../@types/provider';
import { changeMode } from '../api/esitter';

export interface EsitterAction extends Action {
  type: string;
  payload: any; // eslint-disable-line
}

export const setSelectedDevice = (payload?: IDeviceRoomInfo): EsitterAction => ({
  type: 'SET_SELECTED_DEVICE',
  payload: payload
});

export const setHoveredDevice = (deviceId?: string): EsitterAction => ({
  type: 'SET_HOVERED_DEVICE',
  payload: deviceId
});

export const resetSelectedDevice = (): EsitterAction => setSelectedDevice(undefined);

export const setIsCameraControlActive = (active: string | boolean): EsitterAction => ({
  type: 'SET_IS_CAMERA_CONTROL_ACTIVE',
  payload: active
});

export const setIsAudioControlActive = (active: string | boolean): EsitterAction => ({
  type: 'SET_IS_AUDIO_CONTROL_ACTIVE',
  payload: active
});

export const setIsMicActive =
  (flag: boolean): AppThunk =>
  (dispatch: AppDispatch): void => {
    if (flag) {
      dispatch({ type: 'SET_MY_MIC_IS_ACTIVE' });
    } else {
      dispatch({ type: 'RESET_MY_MIC_IS_ACTIVE' });
    }
  };

export const setRoomMode = (id: string, mode: string): EsitterAction => ({
  type: 'SET_ROOM_MODE',
  payload: { id, mode }
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setFeccValues = (id: string, data: any): EsitterAction => ({
  type: 'SET_FECC_VALUES',
  payload: { id, data }
});

export const setHasReceivedWsMessage = (id: string, value: boolean): EsitterAction => ({
  type: 'SET_HAS_RECEIVED_WS_MESSAGE',
  payload: { id, value }
});

export const setContentName = (content: SidePanelContent): EsitterAction => ({
  type: 'SET_SIDE_PANEL_CONTENT',
  payload: content
});

export const setPrevContentName = (content: SidePanelContent): EsitterAction => ({
  type: 'SET_PREV_SIDE_PANEL_CONTENT',
  payload: content
});

export const setPatientDetailsVisibility = (value: boolean): EsitterAction => ({
  type: 'SET_PATIENT_DETAILS_VISIBILITY',
  payload: value
});

export const setPatientDetailsVisibilityOn = (): Action => setPatientDetailsVisibility(true);
export const setPatientDetailsVisibilityOff = (): Action => setPatientDetailsVisibility(false);

export const setInterventionConnectionStatus = ({
  connecting,
  disconnecting,
  deviceId
}: {
  connecting: boolean;
  disconnecting: boolean;
  deviceId: string;
}): EsitterAction => ({
  type: 'SET_INTERVENTION_CONNECTION_STATUS',
  payload: { connecting, disconnecting, deviceId }
});

//*** CHANGE MODE ***/
export const handleChangeMode =
  (device: IDeviceRoomInfo | undefined): AppThunk =>
  async (dispatch: AppDispatch, getState): Promise<void> => {
    const state = getState();

    if (device) {
      console.log('Change Mode for device ', device.deviceId);

      const deviceRoom = state.esitter.roomsArray.find((room: IDeviceRoomInfo) => room.deviceId === device.deviceId);

      if (state.esitter.callId && state.esitter.roomsArray.length > 0) {
        let changeToMode = '';

        if (deviceRoom?.mode.includes('observation')) {
          dispatch(
            setInterventionConnectionStatus({ connecting: true, disconnecting: false, deviceId: device.deviceId })
          );

          changeToMode = 'interactive_av';
        } else if (deviceRoom?.mode.includes('interactive')) {
          dispatch(
            setInterventionConnectionStatus({ connecting: false, disconnecting: true, deviceId: device.deviceId })
          );

          changeToMode = 'observation_v';
        }
        console.log('change to: ', device.deviceId, changeToMode, ' for callUuid - ', state.esitter.callId);

        try {
          await changeMode(state.esitter.callId, device.deviceId, {
            mode: changeToMode,
            forceMode: true
          });
        } catch (error: any) {
          console.error(error);
          dispatch(
            setInterventionConnectionStatus({ connecting: false, disconnecting: false, deviceId: device.deviceId })
          );
        }

        dispatch(setIsAudioControlActive(false));
        dispatch(setIsCameraControlActive(false));
      }
    }
  };
