import {
  AddParticipantResponseData,
  ParticipantStatus,
  IParticipantMetadata,
  IInvitedParticipantInfo,
  IRoomInfo
} from 'src/@types/embed';
import { DeviceRoom } from 'src/@types/provider';
import { EBrowserType } from 'src/@types/enums';
import { BackendError } from 'src/errors/BackendError';
import api from 'src/services/api/api';

export async function addParticipant(
  callId: string,
  data: { displayName: string; loginToken: string; browserType: EBrowserType.DESKTOP | EBrowserType.MOBILE }
): Promise<AddParticipantResponseData | BackendError> {
  try {
    const res = await api.post<FetchResponse<GuestInfoData<IRoomInfo[]>>>(`/api/guests/${callId}/users`, {
      body: data
    });

    if (!res.body.success) {
      return new BackendError('api failure', 200, res.body);
    }

    const roomInfo = res.body.data.roomInfo[0];
    return {
      callUuid: callId,
      callType: roomInfo.callType === 'VR' ? 'vr' : roomInfo.callType === 'ESITTER' ? 'esitter' : 'none',
      userUuid: res.body.data.guestUuid,
      deviceRooms: roomInfo.participants
        .filter(p => {
          return p.userType.toUpperCase() === 'DEVICE';
        })
        .map(
          p =>
            ({
              deviceId: p.uuid,
              mode: p.callMode,
              room: roomInfo.roomSid,
              success: true, //??
              token: roomInfo.roomToken
            } as DeviceRoom)
        ),
      participantStatus: roomInfo.participants.map(
        p =>
          ({
            host: p.host,
            uuid: p.uuid,
            metadata: {
              displayName: p.metadata?.displayName,
              specialty: p.metadata?.specialty,
              imageUrl: p.metadata?.imageUrl
            }
          } as ParticipantStatus)
      )
    };
  } catch (error) {
    console.log(error);

    return error as BackendError;
  }
}

// export async function postParticipantLogin(data: {
//   callUuid: string;
//   loginToken: string;
// }): Promise<{ body: IParticipantLogin; headers: Headers } | BackendError> {
//   try {
//     const res = await api.post<IParticipantLogin>('/api/v2/auth/participantLogin', { body: data });

//     return res;
//   } catch (error) {
//     return error as BackendError;
//   }
// }

// export async function getCallInfo(callId: string, token: string): Promise<IRoomInfo[] | BackendError> {
//   try {
//     const res = await api.get<FetchResponse<RoomInfoData<IRoomInfo[]>>>(`/api/calls/${callId}`, {
//       headers: { Authorization: `Bearer ${token}` }
//     });

//     return res;
//   } catch (error) {
//     return error as BackendError;
//   }
// }

export async function getInvitationInfo(
  callId: string | undefined,
  otpToken: string | undefined
): Promise<IInvitedParticipantInfo | BackendError> {
  try {
    const res = await api.get<FetchResponse<IInvitedParticipantInfo>>(
      `/api/guests/${callId}/invitation?loginToken=${otpToken}`
    );

    if (!res.body.success) {
      return new BackendError('api failure', 200, res.body);
    }

    console.log('getInvitationInfo', JSON.stringify(res.body.data));
    return res.body.data;
  } catch (error) {
    return error as BackendError;
  }
}
