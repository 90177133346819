import { SvgIcon, Theme } from '@mui/material';
import React, { useMemo } from 'react';
import MUIButton from '@mui/material/Button';
import { makeThemedStyles } from '../../../../../../hooks/useThemedStyles';
import { ButtonProps } from '@mui/material/Button/Button';
import { mergeSx } from '../../../../../../utils/styles';
import { SxProps } from '@mui/system/styleFunctionSx';

interface TextButtonProps extends ButtonProps {
  title: string;
  icon: typeof SvgIcon;
  isActive?: boolean;
  iconSx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
}
export const TextButton = React.memo((props: TextButtonProps): JSX.Element => {
  const { icon: IconComponent, title, isActive = false, iconSx, labelSx, ...otherProps } = props;
  const { styles } = useStyles({ isActive });

  const iconComponent = useMemo(
    () => <IconComponent sx={mergeSx(styles.icon, iconSx)} />,
    [IconComponent, iconSx, styles.icon]
  );

  return (
    <MUIButton {...otherProps} variant='text' startIcon={iconComponent} sx={mergeSx(styles.label, labelSx)}>
      {title}
    </MUIButton>
  );
});

type StyleProps = {
  isActive?: boolean;
};

const useStyles = makeThemedStyles<StyleProps>()((_, { isActive }) => ({
  label: { justifyContent: 'start', padding: 0 },
  icon: isActive
    ? {
        backgroundColor: '#297859 !important'
      }
    : {}
}));
