import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  LocalParticipant,
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalVideoTrack,
  LocalAudioTrack
} from 'twilio-video';
import { selectIsMyCamActive, selectIsMyMicActive } from '../selectors/app';
import {
  selectAudioInputId,
  selectAudioInputInitialized,
  selectAudioOutputId,
  selectAudioOutputInitialized,
  selectSelectedAudioInputId,
  selectSelectedVideoInputId,
  selectVideoInputId,
  selectVideoInputInitialized
} from '../selectors/settings';

interface IUseMediaDevicesParams {
  shouldEnumerateDevices?: boolean;
  shouldUpdateCameraList?: boolean;
  onEnumerateDeviceNotSupported?: () => void;
  localParticipant?: LocalParticipant;
  dispatchMediaAccessModal?: () => void;
}

export interface IUseMediaDevicesMediaInfo {
  deviceId: string;
  groupId: string;
  kind: MediaDeviceKind;
  label: string;
  usable: boolean;
  toJSON: any;
}

export enum EMediaTrackState {
  STOPPED = 'stopped',
  STARTING = 'starting',
  STARTED = 'started'
}

export default function useMediaDevices({
  shouldEnumerateDevices = true,
  shouldUpdateCameraList = true,
  onEnumerateDeviceNotSupported,
  localParticipant,
  dispatchMediaAccessModal
}: IUseMediaDevicesParams): {
  audioInputDevices: IUseMediaDevicesMediaInfo[];
  audioOutputDevices: IUseMediaDevicesMediaInfo[];
  videoInputDevices: IUseMediaDevicesMediaInfo[];
  updateDeviceList: () => void;
} {
  const [audioInputDevices, setAudioInputDevices] = useState<IUseMediaDevicesMediaInfo[]>([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState<IUseMediaDevicesMediaInfo[]>([]);
  const [videoInputDevices, setVideoInputDevices] = useState<IUseMediaDevicesMediaInfo[]>([]);
  const [videoTrackState, setVideoTrackState] = useState<EMediaTrackState>(EMediaTrackState.STOPPED);
  const [audioTrackState, setAudioTrackState] = useState<EMediaTrackState>(EMediaTrackState.STOPPED);

  const selectedAudioInputId = useSelector(selectSelectedAudioInputId);
  const selectedVideoInputId = useSelector(selectSelectedVideoInputId);
  const audioInputId = useSelector(selectAudioInputId);
  const audioOutputId = useSelector(selectAudioOutputId);
  const videoInputId = useSelector(selectVideoInputId);
  const audioInputInitialized = useSelector(selectAudioInputInitialized);
  const audioOutputInitialized = useSelector(selectAudioOutputInitialized);
  const videoInputInitialized = useSelector(selectVideoInputInitialized);
  const isMyMicActive = useSelector(selectIsMyMicActive);
  const isMyCamActive = useSelector(selectIsMyCamActive);

  const prevSelectedAudioInputId = useRef(selectedAudioInputId);
  const prevSelectedVideoInputId = useRef(selectedVideoInputId);

  const dispatch = useDispatch();

  // Avoid importing useMediaHardawreChecker hook so that we don't end up with components that import
  // hooks twice (e.g. begin screen)
  const triggerCameraAndMicCheck = useCallback(() => {
    dispatch({ type: 'SHOULD_CHECK_MIC_AND_CAMERA_PERM' });
  }, [dispatch]);

  const triggerMicCheck = useCallback(() => {
    dispatch({ type: 'SHOULD_CHECK_MIC_PERM' });
  }, [dispatch]);

  const setAudioInputInitialized = useCallback(
    (flag: boolean) => {
      dispatch({ type: 'SET_AUDIO_INPUT_INITIALIZED', payload: flag });
      /*(
      if (!flag) {
        dispatch({ type: 'SET_AUDIO_INPUT_ID', payload: '' });
      }
      */
    },
    [dispatch]
  );
  const setAudioOutputInitialized = useCallback(
    (flag: boolean) => {
      dispatch({ type: 'SET_AUDIO_OUTPUT_INITIALIZED', payload: flag });
      /*
      if (!flag) {
        dispatch({ type: 'SET_AUDIO_OUTPUT_ID', payload: '' });
      }
      */
    },
    [dispatch]
  );
  const setVideoInputInitialized = useCallback(
    (flag: boolean) => {
      dispatch({ type: 'SET_VIDEO_INPUT_INITIALIZED', payload: flag });
      /*
      if (!flag) {
        dispatch({ type: 'SET_VIDEO_INPUT_ID', payload: '' });
      }
      */
    },
    [dispatch]
  );

  const getActualDeviceId = (mediaStreamTrack: MediaStreamTrack): string => {
    let actualDeviceId: string | undefined = '';
    // getCapabilities might not exist in Firefox
    if (typeof mediaStreamTrack.getCapabilities === 'function') {
      actualDeviceId = mediaStreamTrack.getCapabilities().deviceId;
    }
    const consDeviceId = mediaStreamTrack.getConstraints().deviceId;
    console.log(`Actual device ID from constraints: ${consDeviceId}, from capabilities: ${actualDeviceId}`);
    if (!actualDeviceId) {
      actualDeviceId = consDeviceId as string;
    }
    return actualDeviceId;
  };

  const updateDeviceList = useCallback(async () => {
    let mediaDevices: MediaDeviceInfo[] = [];
    try {
      mediaDevices = await navigator.mediaDevices.enumerateDevices();
    } catch (err) {
      console.error(`${JSON.stringify(err)}`);
    }
    const audioInput: IUseMediaDevicesMediaInfo[] = [];
    const audioOutput: IUseMediaDevicesMediaInfo[] = [];
    const videoInput: IUseMediaDevicesMediaInfo[] = [];

    const mediaDeviceOk = async (constraints: MediaStreamConstraints) => {
      let hardwareCaptured = false;
      try {
        const mediaStream = await navigator.mediaDevices?.getUserMedia(constraints);
        if (mediaStream) {
          mediaStream.getTracks().forEach((track: MediaStreamTrack) => {
            if (track.readyState === 'live') {
              hardwareCaptured = true;
              track.stop();
            }
          });
        }
      } catch (error) {
        console.debug(
          `useMediaDevices: Error obtaining hardware with constraints ${JSON.stringify(constraints)}: ${JSON.stringify(
            error
          )}`
        );
      }
      return hardwareCaptured;
    };

    for (const mediaDevice of mediaDevices) {
      const deviceGroupId = mediaDevice.groupId;

      switch (mediaDevice.kind) {
        case 'audioinput': {
          if (
            mediaDevice?.deviceId?.length === 0 ||
            mediaDevice?.deviceId === 'default' ||
            audioInput.some(mediaDev => mediaDev.groupId === deviceGroupId)
          ) {
            // don't append a device to the list twice, and skip devices without an ID,
            //  and skip duplicates labelled 'default'
            break;
          }
          const peripheralUsable = await mediaDeviceOk({
            video: false,
            audio: {
              deviceId: { exact: mediaDevice.deviceId }
            }
          });
          const mediaDeviceInfo = mediaDevice.toJSON() as IUseMediaDevicesMediaInfo;
          mediaDeviceInfo.usable = peripheralUsable;
          audioInput.push(mediaDeviceInfo);
          break;
        }
        case 'audiooutput': {
          if (
            mediaDevice?.deviceId?.length === 0 ||
            mediaDevice?.deviceId === 'default' ||
            audioOutput.some(mediaDev => mediaDev.groupId === deviceGroupId)
          ) {
            // don't append a device to the list twice, and skip devices without an ID,
            //  and skip duplicates labelled 'default'
            break;
          }
          const mediaDeviceJSON = mediaDevice.toJSON() as IUseMediaDevicesMediaInfo;
          mediaDeviceJSON.usable = true;
          audioOutput.push(mediaDeviceJSON);
          break;
        }
        case 'videoinput': {
          if (!shouldUpdateCameraList) {
            // Skip creating tracks for video devices if we are in a call that doesn't use
            // the camera, e.g. eSitter
            break;
          }
          if (
            mediaDevice?.deviceId?.length === 0 ||
            mediaDevice?.deviceId === 'default' ||
            videoInput.some(mediaDev => mediaDev.groupId === deviceGroupId)
          ) {
            // don't append a device to the list twice, and skip devices without an ID
            // , and skip duplicates labelled 'default'
            break;
          }
          const peripheralUsable = await mediaDeviceOk({
            video: {
              width: { min: 640, ideal: 1280, max: 1280 },
              height: { min: 360, ideal: 720, max: 720 },
              aspectRatio: 16 / 9,
              deviceId: { exact: mediaDevice.deviceId }
            },
            audio: false
          });
          const mediaDeviceJSON = mediaDevice.toJSON() as IUseMediaDevicesMediaInfo;
          mediaDeviceJSON.usable = peripheralUsable;
          videoInput.push(mediaDeviceJSON);
          break;
        }
      }
    }

    // Check if selected device still persist
    if (!audioInput.some(device => device.deviceId === audioInputId)) {
      setAudioInputInitialized(false);
    }
    if (!audioOutput.some(device => device.deviceId === audioOutputId)) {
      setAudioOutputInitialized(false);
    }
    if (!videoInput.some(device => device.deviceId === videoInputId)) {
      setVideoInputInitialized(false);
    }

    setAudioInputDevices(audioInput);
    setAudioOutputDevices(audioOutput);
    setVideoInputDevices(videoInput);
  }, [
    audioInputId,
    audioOutputId,
    videoInputId,
    setAudioInputInitialized,
    setAudioOutputInitialized,
    setVideoInputInitialized,
    shouldUpdateCameraList
  ]);

  useEffect(() => {
    // "The order is significant - the default capture devices will be listed first"
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/enumerateDevices#return_value
    if (!audioInputInitialized && audioInputDevices.length > 0) {
      let firstAvailableInputId = audioInputDevices.findIndex(aI => aI.usable);
      if (firstAvailableInputId < 0) {
        firstAvailableInputId = 0;
      }
      setAudioInputInitialized(true);

      // Automatically selecting audio input
      dispatch({
        type: 'SET_AUDIO_INPUT_ID',
        payload: audioInputDevices[firstAvailableInputId]?.deviceId
      });
    }
    if (!audioOutputInitialized && audioOutputDevices.length > 0) {
      let firstAvailableInputId = audioOutputDevices.findIndex(aO => aO.usable);
      if (firstAvailableInputId < 0) {
        firstAvailableInputId = 0;
      }
      setAudioOutputInitialized(true);
      dispatch({
        type: 'SET_AUDIO_OUTPUT_ID',
        payload: audioOutputDevices[firstAvailableInputId]?.deviceId
      });
    }
    if (!videoInputInitialized && videoInputDevices.length > 0) {
      let firstAvailableInputId = videoInputDevices.findIndex(vI => vI.usable);
      if (firstAvailableInputId < 0) {
        firstAvailableInputId = 0;
      }
      setVideoInputInitialized(true);
      dispatch({
        type: 'SET_VIDEO_INPUT_ID',
        payload: videoInputDevices[firstAvailableInputId]?.deviceId
      });
    }
  }, [
    dispatch,
    audioInputDevices,
    audioOutputDevices,
    videoInputDevices,
    audioInputInitialized,
    setAudioInputInitialized,
    audioOutputInitialized,
    setAudioOutputInitialized,
    videoInputInitialized,
    setVideoInputInitialized
  ]);

  useEffect(() => {
    if (!shouldEnumerateDevices) {
      // may occur if this hook is called before media permissions
      return;
    }

    if (!navigator.mediaDevices?.enumerateDevices) {
      console.log('enumerateDevices() not supported.');
      onEnumerateDeviceNotSupported && onEnumerateDeviceNotSupported();
    } else {
      updateDeviceList();
      navigator.mediaDevices.addEventListener('devicechange', updateDeviceList);
      return () => {
        navigator.mediaDevices.removeEventListener('devicechange', updateDeviceList);
      };
    }
  }, [shouldEnumerateDevices, onEnumerateDeviceNotSupported, updateDeviceList]);

  const attemptToPublishNewLocalAudioTrack = useCallback(async () => {
    if (!localParticipant) {
      return;
    }

    localParticipant.audioTracks.forEach(trackPub => {
      trackPub.track.detach();
      trackPub.track.stop();
      trackPub.unpublish();
    });
    let createdAudioTrack = null;
    try {
      let idealAudioInputId = audioInputId;

      if (!idealAudioInputId) {
        idealAudioInputId = '';
      }
      createdAudioTrack = await createLocalAudioTrack({ deviceId: idealAudioInputId, sampleRate: 16000 });

      if (createdAudioTrack && createdAudioTrack.mediaStreamTrack) {
        console.log(
          `Created track with sampling rate ${JSON.stringify(createdAudioTrack.mediaStreamTrack.getConstraints())}`
        );
        const actualDeviceId = getActualDeviceId(createdAudioTrack.mediaStreamTrack);
        if (actualDeviceId && actualDeviceId !== idealAudioInputId) {
          dispatch({
            type: 'SET_AUDIO_INPUT_ID',
            payload: actualDeviceId
          });
        }
        await localParticipant.publishTrack(createdAudioTrack);
      } else {
        dispatch({ type: 'RESET_MY_MIC_IS_ACTIVE' });
        triggerMicCheck();
        dispatchMediaAccessModal && dispatchMediaAccessModal();
      }
    } catch (error: any) {
      createdAudioTrack && createdAudioTrack.stop();
      setAudioTrackState(EMediaTrackState.STOPPED);
      if (error['code'] === 53304 && error['name'] === 'TwilioError') {
        console.log('Publishing audio track that already exists, ignoring');
      } else {
        console.log(`Unknown error creating new audio track: ${error}`);
        console.log(`More: ${JSON.stringify(error, Object.getOwnPropertyNames(error))}`);
        // Here, the user will *not* be reprompted for permission to get their mic
        // from the browser (in Chrome, at least), as the browser remembers the previous
        // blocked audio permission. We need to indicate to the user that they have to do
        // something external to the application to enable audio.
        dispatch({ type: 'RESET_MY_MIC_IS_ACTIVE' });
        triggerMicCheck();
        dispatchMediaAccessModal && dispatchMediaAccessModal();
      }
    }
  }, [dispatch, localParticipant, audioInputId, triggerMicCheck, setAudioTrackState, dispatchMediaAccessModal]);

  // Listen for when the audio or video track is actually 'started' (publication completed successfully),
  // and reset our flag indicating that we can try to publish a new track
  useEffect(() => {
    if (!localParticipant) {
      return;
    }

    let isUnmounted = false;

    const trackStartedHandler = (track: LocalAudioTrack | LocalVideoTrack) => {
      if (isUnmounted) {
        return;
      }
      if (track.kind === 'audio') {
        setAudioTrackState(EMediaTrackState.STARTED);
      } else if (track.kind === 'video' && track.name !== 'screenshare') {
        setVideoTrackState(EMediaTrackState.STARTED);
      }
    };
    localParticipant.on('trackStarted', trackStartedHandler);
    return () => {
      localParticipant.removeListener('trackStarted', trackStartedHandler);
      isUnmounted = true;
    };
  }, [localParticipant, setAudioTrackState, setVideoTrackState]);

  // Listen for changes to our actual localparticipant published video/audio media, and if there
  // is a difference between that and our desired state, trigger a permissions modal
  useEffect(() => {
    if (!localParticipant) {
      return;
    }

    let isUnmounted = false;

    const trackStoppedHandler = (track: LocalAudioTrack | LocalVideoTrack) => {
      // Avoid handling track updates if the component is no longer mounted
      if (isUnmounted) {
        return;
      }
      // If we detect a track stopped and the internal app state thinks the device is still active
      // (mic or camera), this indicates a likely loss due to hardware or permissions error. Trigger
      // a recheck of the permissions in this case, and disable the camera or mic.
      // XXX: can we successfully trigger a check of a device that already has a track on Android mobile?
      if (track.kind === 'audio') {
        if (isMyMicActive) {
          dispatch({ type: 'RESET_MY_MIC_IS_ACTIVE' });
          triggerMicCheck();
        }
        // This 'STOPPED' event must occur after the dispatches above, or we might try to publish a track
        // from another device when we lose a track due to a hardware error/device disconnect
        setAudioTrackState(EMediaTrackState.STOPPED);
      } else if (track.kind === 'video' && track.name !== 'screenshare') {
        if (isMyCamActive) {
          dispatch({ type: 'RESET_MY_CAM_IS_ACTIVE', info: 'setIsCamActive' });
          triggerCameraAndMicCheck();
        }
        setVideoTrackState(EMediaTrackState.STOPPED);
      }
    };

    localParticipant.on('trackStopped', trackStoppedHandler);
    return () => {
      localParticipant.removeListener('trackStopped', trackStoppedHandler);
      isUnmounted = true;
    };
  }, [
    localParticipant,
    isMyCamActive,
    isMyMicActive,
    setVideoTrackState,
    setAudioTrackState,
    triggerMicCheck,
    triggerCameraAndMicCheck,
    dispatch
  ]);

  // If the audio track state is "STARTED" and the input ID has changed, we should unpublish tracks
  useEffect(() => {
    if (!localParticipant) {
      return;
    }

    if (!selectedAudioInputId || selectedAudioInputId === prevSelectedAudioInputId.current) {
      return;
    }

    if (audioTrackState === EMediaTrackState.STARTING) {
      // ignore this event, set the selected audio ID back to the previous value.
      dispatch({ type: 'SET_SELECTED_AUDIO_INPUT_ID_FROM_USER', payload: prevSelectedAudioInputId.current });
      return;
    }

    prevSelectedAudioInputId.current = selectedAudioInputId;

    // If the selected audio input ID changed, stop our media tracks on the existing audio input.
    if (audioTrackState === EMediaTrackState.STARTED) {
      // This will trigger the trackStopped handler, which will cause the existing tracks to be unpublished.
      // Then, the following useEffect will take effect
      if (localParticipant?.audioTracks) {
        for (const [, trackPub] of localParticipant.audioTracks) {
          if (trackPub?.track) {
            trackPub.track.disable();
            trackPub.track.stop();
          }
          if (trackPub) {
            trackPub.unpublish();
          }
        }
      }

      dispatch({ type: 'SET_AUDIO_INPUT_ID', payload: selectedAudioInputId });
      dispatch({ type: 'SET_MY_MIC_IS_ACTIVE' });
    } else if (audioTrackState === EMediaTrackState.STOPPED) {
      dispatch({ type: 'SET_AUDIO_INPUT_ID', payload: selectedAudioInputId });
    }
  }, [selectedAudioInputId, audioTrackState, localParticipant, dispatch]);

  useEffect(() => {
    if (!localParticipant) {
      return;
    }

    if (!selectedVideoInputId || selectedVideoInputId === prevSelectedVideoInputId.current) {
      return;
    }

    if (videoTrackState === EMediaTrackState.STARTING) {
      // ignore this event, set the selected audio ID back to the previous value.
      dispatch({ type: 'SET_SELECTED_VIDEO_INPUT_ID_FROM_USER', payload: prevSelectedVideoInputId.current });
      return;
    }

    prevSelectedVideoInputId.current = selectedVideoInputId;

    // If the selected video input ID changed, stop our media tracks on the existing audio input.
    if (videoTrackState === EMediaTrackState.STARTED) {
      for (const [, trackPub] of localParticipant.videoTracks) {
        if (trackPub?.track && trackPub?.track?.name !== 'screenshare') {
          trackPub.track.disable();
          trackPub.track.stop();
          trackPub.unpublish();
        }
      }
      dispatch({ type: 'SET_VIDEO_INPUT_ID', payload: selectedVideoInputId });
      dispatch({ type: 'SET_MY_CAM_IS_ACTIVE' });
    } else if (videoTrackState === EMediaTrackState.STOPPED) {
      dispatch({ type: 'SET_VIDEO_INPUT_ID', payload: selectedVideoInputId });
    }
  }, [selectedVideoInputId, videoTrackState, localParticipant, dispatch]);

  useEffect(() => {
    if (!localParticipant) {
      return;
    }
    // The audio input ID may change as the app initializes, don't attempt to create a track
    // until we have a valid audio input ID
    const validAudioInputId = audioInputId && audioInputId?.length > 0;
    if (validAudioInputId && audioTrackState === EMediaTrackState.STOPPED && isMyMicActive) {
      setAudioTrackState(EMediaTrackState.STARTING);
      attemptToPublishNewLocalAudioTrack();
    } else if (!isMyMicActive) {
      if (localParticipant?.audioTracks) {
        for (const [, trackPub] of localParticipant.audioTracks) {
          if (trackPub?.track) {
            trackPub.track.disable();
            trackPub.track.stop();
          }
          if (trackPub) {
            trackPub.unpublish();
          }
        }
      }
    }
  }, [
    audioInputId,
    localParticipant,
    attemptToPublishNewLocalAudioTrack,
    isMyMicActive,
    audioTrackState,
    setAudioTrackState
  ]);

  const attemptToPublishNewLocalVideoTrack = useCallback(async () => {
    if (!localParticipant) {
      return;
    }
    for (const [, trackPub] of localParticipant.videoTracks) {
      if (trackPub?.track && trackPub?.track?.name !== 'screenshare') {
        trackPub.track.disable();
        trackPub.track.stop();
        trackPub.unpublish();
      }
    }
    let createdVideoTrack = null;
    try {
      let idealVideoInputId = videoInputId;
      if (!idealVideoInputId) {
        idealVideoInputId = '';
      }
      createdVideoTrack = await createLocalVideoTrack({
        aspectRatio: 16 / 9,
        height: { min: 360, ideal: 720, max: 720 },
        width: { min: 640, ideal: 1280, max: 1280 },
        facingMode: 'user',
        name: 'camera',
        deviceId: idealVideoInputId
      });

      if (createdVideoTrack && createdVideoTrack.mediaStreamTrack) {
        const actualDeviceId = getActualDeviceId(createdVideoTrack.mediaStreamTrack);
        if (actualDeviceId && actualDeviceId !== idealVideoInputId) {
          dispatch({
            type: 'SET_VIDEO_INPUT_ID',
            payload: actualDeviceId
          });
        }
        await localParticipant.publishTrack(createdVideoTrack);
      } else {
        dispatch({ type: 'RESET_MY_CAM_IS_ACTIVE' });
        triggerCameraAndMicCheck();
        dispatchMediaAccessModal && dispatchMediaAccessModal();
      }
    } catch (error: any) {
      createdVideoTrack && createdVideoTrack.stop();
      setVideoTrackState(EMediaTrackState.STOPPED);
      if (error['code'] === 53304 && error['name'] === 'TwilioError') {
        console.log('Publishing video track that already exists, ignoring');
      } else {
        console.log(`Error publishing new video track: ${JSON.stringify(error, Object.getOwnPropertyNames(error))}`);
        dispatch({ type: 'RESET_MY_CAM_IS_ACTIVE' });
        triggerCameraAndMicCheck();
        dispatchMediaAccessModal && dispatchMediaAccessModal();
      }
    }
  }, [
    dispatch,
    localParticipant,
    videoInputId,
    triggerCameraAndMicCheck,
    setVideoTrackState,
    dispatchMediaAccessModal
  ]);

  useEffect(() => {
    if (!localParticipant) {
      return;
    }
    const validVideoInputId = videoInputId && videoInputId?.length > 0;
    if (validVideoInputId && videoTrackState === EMediaTrackState.STOPPED && isMyCamActive) {
      setVideoTrackState(EMediaTrackState.STARTING);
      attemptToPublishNewLocalVideoTrack();
    } else if (!isMyCamActive) {
      if (localParticipant?.videoTracks) {
        for (const [, trackPub] of localParticipant.videoTracks) {
          if (trackPub?.track && trackPub?.track?.name !== 'screenshare') {
            trackPub.track.disable();
            trackPub.track.stop();
            trackPub.unpublish();
          }
        }
      }
    }
  }, [
    localParticipant,
    isMyCamActive,
    attemptToPublishNewLocalVideoTrack,
    videoTrackState,
    setVideoTrackState,
    videoInputId
  ]);

  return { audioInputDevices, audioOutputDevices, videoInputDevices, updateDeviceList };
}
