import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import rootReducer from '../reducers/root';
import thunk from 'redux-thunk';

// const sentryReduxEnhancer = Sentry.createReduxEnhancer({
//   actionTransformer: action => {
//     if (action.type === 'SET_VR_PARTICIPANTS') {
//       // remove identity, name, and iniitals from each element
//       const cleanedAction = action.map(({ uuid }: { uuid: string }) => ({ uuid }));
//       return {
//         ...cleanedAction
//       };
//     }
//     return action;
//   }
// });

const middlewareEnhancer = applyMiddleware(thunk);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(middlewareEnhancer);

export const store = createStore(rootReducer, enhancer);
