import { createSelector } from 'reselect';
import { EsitterInitialState } from '../@types/reducer';
import { RootState } from '../reducers/root';
import { IDeviceRoomInfo } from '../@types/provider';

export const getEsitter = (state: RootState): EsitterInitialState => state.esitter;
export const selectRoomsArray = createSelector([getEsitter], esitter => esitter.roomsArray);
export const selectModalVisible = createSelector([getEsitter], esitter => esitter.modalVisible);

export const selectModalData = createSelector([getEsitter], esitter => esitter.modalData);
export const selectPatientDetailsVisible = createSelector([getEsitter], esitter => esitter.patientDetailsVisible);
export const selectSidePanelCollapsed = createSelector([getEsitter], esitter => esitter.sidePanelCollapsed);

export const selectSortType = createSelector([getEsitter], esitter => esitter.sortType);
export const selectIsPendingRemoval = createSelector([getEsitter], esitter => esitter.isPendingRemoval);
export const selectIsCameraControlActive = createSelector([getEsitter], esitter => esitter.isCameraControlActive);

export const selectIsAudioControlActive = createSelector([getEsitter], esitter => esitter.isAudioControlActive);
export const selectHoveredDeviceId = createSelector([getEsitter], esitter => esitter.hoveredDeviceId);

export const selectIsInterventionConnecting = createSelector(
  [getEsitter],
  esitter => esitter.interventionConnectionStatus.connecting
);

export const selectIsInterventionDisconnecting = createSelector(
  [getEsitter],
  esitter => esitter.interventionConnectionStatus.disconnecting
);

export const selectInterventionConnectionDeviceId = createSelector(
  [getEsitter],
  esitter => esitter.interventionConnectionStatus.deviceId
);

export const selectDeviceName = createSelector([getEsitter, (esitter, deviceId) => deviceId], (esitter, deviceId) => {
  const { deviceName } = esitter.roomsArray.find(item => item.deviceId === deviceId) || { deviceName: deviceId };
  return deviceName;
});

export const selectSidePanelContentName = createSelector([getEsitter], esitter => esitter.contentName);
export const selectPrevContentName = createSelector([getEsitter], esitter => esitter.prevContentName);

export const selectDeviceById = createSelector([getEsitter, (esitter, deviceId) => deviceId], (esitter, deviceId) => {
  return esitter.roomsArray.find(item => item.deviceId === deviceId);
});

export const selectInteractiveDevice = createSelector([getEsitter], esitter => {
  return esitter.roomsArray.find((room: IDeviceRoomInfo) => room.mode.includes('interactive'));
});
export const selectInteractiveDeviceId = createSelector([getEsitter], esitter => {
  return esitter.roomsArray.find((room: IDeviceRoomInfo) => room.mode.includes('interactive'))?.deviceId;
});

export const selectIsDeviceInInteractiveMode = createSelector(
  [selectInteractiveDeviceId, (interactiveDeviceId, deviceId) => deviceId],
  (interactiveDeviceId, deviceId) => {
    return Boolean(interactiveDeviceId === deviceId);
  }
);

export const selectDeviceSelectedCameraUuid = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { selectedCameraUuid } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.deviceInfo || {
      selectedCameraUuid: ''
    };
    return selectedCameraUuid;
  }
);

export const selectDeviceMicGain = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { micGain } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.deviceInfo || {
      micGain: 0
    };
    return micGain;
  }
);

export const selectDeviceMicGainLimit = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { micGainLimit } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.deviceInfo || {
      micGainLimit: { min: true, max: true }
    };
    return micGainLimit;
  }
);

export const selectDevicePatientVolume = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { patientVolume } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.deviceInfo || {
      patientVolume: 0
    };
    return patientVolume;
  }
);

export const selectDeviceZoom = createSelector([getEsitter, (esitter, deviceId) => deviceId], (esitter, deviceId) => {
  const { zoom } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.deviceInfo || {
    zoom: 0
  };
  return zoom;
});

export const selectDevicePanLimit = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { panLimit } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.deviceInfo || {
      panLimit: { min: true, max: true }
    };
    return panLimit;
  }
);

export const selectDeviceTiltLimit = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { tiltLimit } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.deviceInfo || {
      tiltLimit: { min: true, max: true }
    };
    return tiltLimit;
  }
);

export const selectDeviceZoomLimit = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { zoomLimit } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.deviceInfo || {
      zoomLimit: { min: true, max: true }
    };
    return zoomLimit;
  }
);

export const selectDeviceCameraList = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { cameraList } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.deviceInfo || {
      cameraList: []
    };
    return cameraList;
  }
);

export const selectDevicePatientVolumeLimit = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { patientVolumeLimit } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.deviceInfo || {
      patientVolumeLimit: { min: true, max: true }
    };
    return patientVolumeLimit;
  }
);

export const selectDeviceTwilioRoom = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { room } = esitter.roomsArray.find(item => item.deviceId === deviceId) || {
      room: ''
    };
    return room;
  }
);

export const selectDeviceTwilioToken = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { token } = esitter.roomsArray.find(item => item.deviceId === deviceId) || {
      token: ''
    };
    return token;
  }
);

export const selectIsDeviceInPrivacyMode = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    return esitter.roomsArray.some(
      (room: IDeviceRoomInfo) => room.deviceId === deviceId && room.deviceInfo.privacyMode
    );
  }
);

export const selectIsDevicePaused = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    return esitter.roomsArray.some((room: IDeviceRoomInfo) => room.deviceId === deviceId && room.paused);
  }
);

export const selectIsDeviceFlipped = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    return esitter.roomsArray.some(
      (room: IDeviceRoomInfo) => room.deviceId === deviceId && room?.deviceInfo.videoRotationAngle !== 0
    );
  }
);

export const selectHasReceivedWSMessage = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    return esitter.roomsArray.some((room: IDeviceRoomInfo) => room.deviceId === deviceId && room?.hasReceivedWSMessage);
  }
);

export const getCallId = createSelector([getEsitter], esitter => esitter.callId);
export const getSelectedCameraUuid = createSelector(
  [getEsitter],
  esitter => esitter.selectedDevice?.deviceInfo.selectedCameraUuid || ''
);

export const getSelectedDevice = createSelector([getEsitter], esitter => esitter.selectedDevice);
export const getSelectedDeviceName = createSelector(
  [getEsitter],
  esitter => esitter.selectedDevice?.deviceName || esitter.selectedDevice?.deviceId
);

export const getSelectedDeviceNotes = createSelector([getEsitter], esitter => esitter.selectedDevice?.note || '');
export const getSelectedDeviceId = createSelector([getEsitter], esitter => esitter.selectedDevice?.deviceId || '');

export const selectIsVirtualBedRailsSupportedForSelectedDevice = createSelector(
  [getEsitter],
  esitter =>
    esitter.selectedDevice?.deviceCapabilities?.find(capabilityItem => capabilityItem.id === 1000)?.available || false
);

export const isDeviceSelected = createSelector(
  [getSelectedDeviceId, (selectedDeviceId, deviceId) => deviceId],
  (selectedDeviceId, deviceId) => {
    return selectedDeviceId === deviceId;
  }
);

export const selectSelectedDeviceInPrivacyMode = createSelector(
  [getEsitter],
  esitter => esitter.selectedDevice?.deviceInfo?.privacyMode || false
);
export const selectSelectedDevicePaused = createSelector(
  [getEsitter],
  esitter => esitter.selectedDevice?.paused || false
);

export const selectDeviceByCameraUuid = createSelector(
  [getEsitter, (esitter, cameraUuid) => cameraUuid],
  (esitter, cameraUuid) => {
    return esitter.roomsArray.find(d => d.deviceInfo.selectedCameraUuid === cameraUuid);
  }
);
