import { Box, Stack, SvgIcon, SxProps, Theme, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { makeThemedStyles } from '../../../../../../hooks/useThemedStyles';
import { mergeSx } from '../../../../../../utils/styles';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
import { BedrailsStatus } from '../BedrailsPanel/BedrailsPanel';

interface IconWithTextProps {
  icon: typeof SvgIcon;
  text: string | React.ReactNode;
  sx?: SxProps<Theme>;
  iconSx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
}

export const IconWithText = React.memo((props: IconWithTextProps): React.ReactElement | null => {
  const { styles } = useStyles();
  const { icon: Icon, text, sx, iconSx, labelSx, variant = 'body1' } = props;
  const mergedIconSx = useMemo(() => mergeSx(styles.icon, iconSx), [iconSx, styles.icon]);
  const mergedLabelSx = useMemo(() => mergeSx(styles.label, labelSx), [labelSx, styles.label]);

  return (
    <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} component={'div'} sx={sx}>
      <Box
        sx={{
          width: '2.625rem',
          height: '2.625rem',
          display: 'flex',
          backgroundColor: 'transparent',
          borderRadius: '50%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Icon sx={mergedIconSx} color={'error'} />
      </Box>
      <Typography variant={variant} sx={mergedLabelSx}>
        {text}
      </Typography>
    </Stack>
  );
});

const useStyles = makeThemedStyles()(theme => ({
  icon: {
    fontSize: '12px',
    color: '#FFFFFF'
  },
  label: {
    pl: '.62rem',
    color: '#FFFFFF',
    fontWeight: '700'
  }
}));
