import { Modal, SvgIconTypeMap, Box } from '@mui/material';
import React, { useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import styles from './InfoModal.module.css';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { RootState } from '../../reducers/root';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import IconButton from '@mui/material/IconButton';

interface InfoModalProps {
  visible: boolean;
  title: string;
  MuiSvgIcon?: OverridableComponent<SvgIconTypeMap<any, 'svg'>>;
  MuiSvgIconStyle?: any;
  description: string | React.ReactNode;
  secondaryBtnName?: string;
  primaryBtnName: string;
  onSecondaryBtnClick?: () => void;
  onPrimaryBtnClick?: () => void;
  hideCloseBtn?: boolean;
  hidePrimaryBtn?: boolean;
  // Fires after *any* button is pressed, should be a function to 'close' the Modal
  onClose: () => void;
  primaryBtnColor?: string;
  disablePrimaryBtnAutoClose?: boolean;
}

export const InfoModal = React.memo((props: InfoModalProps): React.ReactElement => {
  const {
    disablePrimaryBtnAutoClose = false,
    visible,
    title,
    MuiSvgIcon,
    MuiSvgIconStyle,
    description,
    secondaryBtnName,
    primaryBtnName,
    onSecondaryBtnClick,
    onPrimaryBtnClick,
    hideCloseBtn,
    onClose,
    primaryBtnColor
  } = props;

  const { theme } = useSelector((state: RootState) => state.setup);
  const { palette } = useTheme();
  const isDarkMode = palette.mode === 'dark';
  const isMobile = useSelector((state: RootState) => state.settings.browserType) === 'mobile';
  const { modalVisible } = useSelector((state: RootState) => state.vrcall);

  const secondaryButtonClick = useCallback(() => {
    onSecondaryBtnClick && onSecondaryBtnClick();
    onClose();
  }, [onClose, onSecondaryBtnClick]);

  const primaryButtonClick = useCallback(() => {
    onPrimaryBtnClick && onPrimaryBtnClick();
    !disablePrimaryBtnAutoClose && onClose();
  }, [onClose, onPrimaryBtnClick, disablePrimaryBtnAutoClose]);

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: isMobile ? '0 20px' : '0',
        zIndex: '9999'
      }}
      open={visible}
      onClose={onClose}
    >
      <div
        className={styles.container}
        style={{
          width: isMobile ? '100%' : typeof description !== 'string' ? '720px' : '520px',
          backgroundColor: isDarkMode ? '#333333' : '#F2F2F2'
        }}
      >
        <div className={styles.titleContainer}>
          <p className={styles.title} style={{ color: isDarkMode ? '#ffffff' : '#111111' }}>
            {title}
          </p>
          {!hideCloseBtn && (
            <CloseIcon sx={{ color: isDarkMode ? '#ffffff' : '#111111', cursor: 'pointer' }} onClick={onClose} />
          )}
        </div>
        {typeof description !== 'string' ? (
          <Box
            sx={{
              padding: '20px 0 0 20px',
              // marginTop: '20px',
              marginBottom: '20px',
              overflowY: 'auto',
              borderBottom: 'none',
              maxHeight: 'calc(100vh - 370px)',
              '& .gridContainer': {
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                gap: '20px',
                paddingRight: '24px',
                '& .gridBox': {
                  '& > div': {
                    padding: '0 !important'
                  }
                }
              },
              '::-webkit-scrollbar': {
                backgroundColor: 'transparent',
                width: '22px',
                height: '22px'
              },
              '::-webkit-scrollbar-thumb': {
                backgroundColor: isDarkMode ? '#444444' : '#666666',
                backgroundClip: 'padding-box',
                border: '8px solid transparent',
                borderRadius: '20px'
              },
              ':-webkit-scrollbar-track': {
                backgroundColor: 'transparent'
              },
              '::-webkit-scrollbar-button:vertical:start:increment': {
                display: 'block',
                height: '5px'
              },
              '::-webkit-scrollbar-button:vertical:end:increment': {
                display: 'block',
                width: '5px'
              }
            }}
          >
            {description}
          </Box>
        ) : (
          <div className={styles.descriptionContainer}>
            {MuiSvgIcon && (
              <div className={styles.icon}>
                <MuiSvgIcon sx={{ width: '32px', height: '32px', ...MuiSvgIconStyle }} />
              </div>
            )}
            <p
              className={styles.description}
              style={{ color: isDarkMode ? '#ffffff' : '#111111' }}
              dangerouslySetInnerHTML={{
                __html: description
              }}
            />
          </div>
        )}
        <div
          className={styles.buttons}
          style={{ paddingLeft: isMobile ? '20px' : 0, paddingRight: isMobile ? '20px' : 0 }}
        >
          {secondaryBtnName && (
            <button
              className={`${styles.btn} ${styles.secondaryBtn}`}
              onClick={secondaryButtonClick}
              style={{ width: isMobile ? '100%' : '200px' }}
            >
              {secondaryBtnName}
            </button>
          )}
          <button
            className={`${styles.btn} ${styles.primaryBtn}`}
            onClick={primaryButtonClick}
            style={{
              display: primaryBtnName ? 'block' : 'none',
              width: isMobile ? '100%' : '200px',
              backgroundColor:
                primaryBtnColor && primaryBtnColor ? primaryBtnColor : theme === 'dark' ? '#297859' : '#1F4033'
            }}
          >
            {primaryBtnName}
          </button>
        </div>
      </div>
    </Modal>
  );
});
