import { Action as ReduxAction, combineReducers } from 'redux';

import appReducer from './app';
import devicesReducer from './devices';
import settingsReducer from './settings';
import setupReducer from './setup';
import websocketsReducer from './websockets';
import vrCallReducer from './vrcall';
import vrParticipantsReducer from './vrparticipants';
import esitterReducer from './esitter';
import mediaPermissionsReducer from './mediapermissions';
import authReducer from './auth';
import uiStateReducer from './uiState';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import bedrailsReducer from './bedrailsSlice';
import toastReducer from './toastSlice';
import closeCaptionsSlice from './closeCaptionsSlice';
import dialogSlice from './dialogSlice';

const rootReducer = combineReducers({
  app: appReducer,
  devices: devicesReducer,
  dialog: dialogSlice,
  settings: settingsReducer,
  setup: setupReducer,
  websockets: websocketsReducer,
  vrcall: vrCallReducer,
  vrparticipants: vrParticipantsReducer,
  esitter: esitterReducer,
  mediapermissions: mediaPermissionsReducer,
  uiState: uiStateReducer,
  bedrails: bedrailsReducer,
  toast: toastReducer,
  closeCaptions: closeCaptionsSlice,
  auth: authReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, ReduxAction<string>>;
export type AppDispatch = ThunkDispatch<RootState, unknown, ReduxAction<string>>;
export default rootReducer;
