/* eslint max-len: 0 */
import * as React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export const CallEndIcon = createSvgIcon(
  <g>
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 36 36' fill='none'>
      <g clipPath='url(#clip0_1252_190892)'>
        <path d='M4.5 31.5L31.5 4.5' stroke='current' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
        <g filter='url(#filter0_d_1252_190892)'>
          <path
            d='M8.7465 21.2415C6.23169 17.6294 4.76227 13.3935 4.5 9C4.5 8.20435 4.81607 7.44129 5.37868 6.87868C5.94129 6.31607 6.70435 6 7.5 6H13.5L16.5 13.5L12.75 15.75C12.912 16.08 13.0845 16.4025 13.2675 16.7175L8.7465 21.2415Z'
            fill='current'
          />
          <path
            d='M15.894 20.133C17.1585 21.393 18.627 22.449 20.25 23.25L22.5 19.5L30 22.5V28.5C30 29.2956 29.6839 30.0587 29.1213 30.6213C28.5587 31.1839 27.7956 31.5 27 31.5C21.1472 31.1497 15.6263 28.6642 11.484 24.5145M8.7465 21.2415C6.23169 17.6294 4.76227 13.3935 4.5 9C4.5 8.20435 4.81607 7.44129 5.37868 6.87868C5.94129 6.31607 6.70435 6 7.5 6H13.5L16.5 13.5L12.75 15.75C12.912 16.08 13.0845 16.4025 13.2675 16.7175L8.7465 21.2415Z'
            stroke='current'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <path
          d='M11.4829 24.518L16.1381 20.1732L19.8622 23.2767L22.6553 19.2422L30.1036 22.656V28.2422L28.8622 30.7249L27.0002 31.3456L22.0346 30.7249L15.207 27.6215L11.4829 24.518Z'
          fill='current'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_1252_190892'
          x='-4.5'
          y='-3'
          width='43.5'
          height='43.5'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='4' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1252_190892' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1252_190892' result='shape' />
        </filter>
        <clipPath id='clip0_1252_190892'>
          <rect width='36' height='36' fill='current' />
        </clipPath>
      </defs>
    </svg>
  </g>,
  'CallEndIcon'
);
