import { Components, Theme } from '@mui/material';

export const componentsOverrides: Components<Theme> = {
  MuiCssBaseline: {
    styleOverrides: themeParam => ({
      body: {
        '*::-webkit-scrollbar': {
          backgroundColor: 'transparent',
          width: '22px',
          height: '22px'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: themeParam.palette.mode === 'dark' ? '#666666' : '#BBBBBB',
          backgroundClip: 'padding-box',
          border: '8px solid transparent',
          borderRadius: '20px'
        },
        ':-webkit-scrollbar-track': {
          backgroundColor: 'transparent'
        },
        '*::-webkit-scrollbar-button:vertical:start:increment': {
          display: 'block',
          height: '5px'
        },
        '*::-webkit-scrollbar-button:vertical:end:increment': {
          display: 'block',
          width: '5px'
        }
      }
    })
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        position: 'relative',
        height: '100vh',
        overflow: 'auto'
      }
    }
  },
  MuiDialog: {
    styleOverrides: {
      paper: ({ theme }) => ({
        borderRadius: '24px',
        overflowY: 'inherit',
        width: '100%',
        background: theme.palette.mode === 'dark' ? '#333333' : '#F2F2F2'
      })
    }
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        '&.Mui-focused': {
          color: 'inherit',
          fontWeight: 'inherit'
        }
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        boxShadow: 'none',
        borderRadius: '8px',
        background: theme.palette.mode === 'dark' ? '#3C3C3C' : '#DDDDDD'
      })
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontWeight: theme.typography.fontWeightBold,
        marginBottom: theme.spacing(1)
      })
    }
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        margin: 0
      })
    }
  },
  MuiFormControl: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderColor: theme.palette.grey[200],
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            border: `2px solid ${theme.palette.primary.main}`,
            boxShadow: `0 0 8px ${theme.palette.primary.main}50`,
            outline: 'none'
          },
          '&.Mui-focused.Mui-error fieldset': {
            border: `2px solid ${theme.palette.error.main}`,
            boxShadow: `0 0 8px ${theme.palette.error.main}50`,
            outline: 'none'
          }
        }
      })
    }
  },
  MuiTextField: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderColor: theme.palette.grey[200],
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            border: `2px solid ${theme.palette.primary.main}`,
            boxShadow: `0 0 8px ${theme.palette.primary.main}50`,
            outline: 'none'
          }
        },

        '& .MuiOutlinedInput-input:-webkit-autofill': {
          WebkitBoxShadow: `0 0 0 100rem ${theme.palette.mode === 'light' ? '#fff' : theme.palette.grey['A400']} inset`
        }
      })
    }
  },
  MuiSwitch: {
    styleOverrides: {
      root: ({ theme }) => ({
        width: '3.2rem',
        height: '1.85rem',
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 3,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(1.3rem)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.primary.main,
              opacity: 1,
              border: 0
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5
            }
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: theme.palette.primary.light,
            border: '6px solid #fff'
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.grey[800]
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
          }
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: '1.5rem',
          height: '1.5rem'
        },
        '& .MuiSwitch-track': {
          borderRadius: 38 / 2,
          backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.grey[800],
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500
          })
        }
      })
    }
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: 0
      })
    }
  },
  MuiRadio: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: 0,
        '& .MuiRadio-colorSecondary.Mui-checked': {
          color: theme.palette.primary.main,
          '&:hover': {
            //  if theme setting type is dark, hover color is primary.light
          }
        }
      })
    }
  },
  MuiButtonGroup: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: 0,
        '& .MuiButtonGroup-grouped:not(:last-of-type)': {
          borderRight: 'none'
        }
      })
    }
  },
  MuiCheckbox: {
    styleOverrides: {
      root: ({ theme }) => ({})
    }
  }
};
