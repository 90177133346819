import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import WebSocketProxy from 'src/services/websocket_proxy/WebSocketProxy';
import WebSocket from 'isomorphic-ws';
import {
  selectRetryCount,
  selectShouldInitializeWs,
  selectWsInstanceCount,
  selectWsReady,
  selectWsUuid
} from './selectors/websockets';

const MOCK_WS_URI = 'ws://localhost:9876';
const WS_URI = `${process.env.REACT_APP_WS_HOST}`;
const WS_GUEST_URI = `${process.env.REACT_APP_WS_GUEST}`;

// If we don't receive a heartbeat for 15 seconds, we are either connecting to the wrong
// endpoint, or the websocket connections are rate limited (and retrying will not help).
// Terminate the call.
// const WS_HEARTBEAT_INITIAL_TIMEOUT_SECONDS = 15;
const WS_HEARTBEAT_INITIAL_TIMEOUT_SECONDS = 60 * 10;

export function useWebsocketHelper(
  onRetriesExhausted?: () => void,
  setReconnectingStatus?: (arg0: boolean) => void
): any {
  const wsUuid = useSelector(selectWsUuid);
  const wsReady = useSelector(selectWsReady);
  const wsInstanceCount = useSelector(selectWsInstanceCount);
  const retryCount = useSelector(selectRetryCount);
  const shouldInitializeWs = useSelector(selectShouldInitializeWs);
  const dispatch = useDispatch();

  // Instantiate websocket (if its the first time) and attach handlers to the websocket (every time we instantiate it)
  useEffect(() => {
    function setReinitTimer(): void {
      const newReinitTimerId = setInterval(() => {
        dispatch({
          type: 'REINIT_WS',
          payload: { uri: WS_URI, guest_uri: WS_GUEST_URI, mock_uri: MOCK_WS_URI }
        });
      }, 5000);

      dispatch({
        type: 'WS_SET_REINIT_TIMER_ID',
        payload: newReinitTimerId
      });
      dispatch({
        type: 'SET_WS_NOT_READY',
        payload: {}
      });
    }

    if (!shouldInitializeWs) {
      // Wait for application to indicate it is OK to establish a websocket connection
      return;
    }

    if (!wsUuid) {
      const newWsUuid = uuidv4();
      console.log('wsHelper: Initializing websocket (first time)');
      (window as any)[newWsUuid] = new WebSocketProxy({ url: WS_URI, guestUrl: WS_GUEST_URI, mockUrl: MOCK_WS_URI });
      dispatch({
        type: 'INIT_WS',
        payload: { wsUuid: newWsUuid, uri: WS_URI, guest_uri: WS_GUEST_URI }
      });
      setReinitTimer();
      return;
    }

    // Below code only executes after the `INIT_WS` dispatch successfully sets wsUuid/state
    console.log(`WS: Attaching handlers to websocket instance ${wsInstanceCount}`);
    try {
      const globalWs = (window as any)[wsUuid];
      if (!globalWs) {
        return;
      }
      const heartbeatTimerId = setTimeout(() => {
        // this is a permanent/irrecoverable failure. This represents no heartbeat
        // received for the *first* heartbeat occurrence after initializing the call.
        console.warn('timed out inside heartbeat timer ID');
        if (globalWs?.setReconnectingStatusHandler) {
          globalWs?.setReconnectingStatusHandler(true);
        }
        dispatch({
          type: 'REINIT_WS',
          payload: { uri: WS_URI, guest_uri: WS_GUEST_URI, mock_uri: MOCK_WS_URI }
        });
      }, WS_HEARTBEAT_INITIAL_TIMEOUT_SECONDS * 1000);
      dispatch({ type: 'WS_RESET_HEARTBEAT_TIMER_ID', payload: { heartbeatTimerId } });
      if (onRetriesExhausted) {
        globalWs.onRetriesExhaustedHandler = onRetriesExhausted;
      }
      if (setReconnectingStatus) {
        globalWs.setReconnectingStatusHandler = setReconnectingStatus;
      }
      globalWs.onopen = () => {
        console.log('WS: Connected');
        if (globalWs.setReconnectingStatusHandler) {
          globalWs.setReconnectingStatusHandler(false);
        }
        dispatch({ type: 'SET_WS_READY', payload: {} });
        dispatch({
          type: 'WS_SET_REINIT_TIMER_ID',
          payload: undefined
        });
        dispatch({
          type: 'WS_RESET_RETRY_COUNT',
          payload: {}
        });
      };

      globalWs.onerror = () => {
        console.log('WS: Error');
      };

      globalWs.onclose = (event: WebSocket.CloseEvent) => {
        if (event?.reason === 'internal reset') {
          // do nothing, return
          console.log('WS: Disconnected (internal reset)');
          return;
        }
        console.log(`WS: Disconnected (unexpected, code: ${event?.code}, reason: ${event?.reason})`);
        if (globalWs?.setReconnectingStatusHandler) {
          globalWs?.setReconnectingStatusHandler(true);
        }
        // start a timer, and store the timeout for reconnect
        // when the timer expires, we'll call a function to reinitialize the websocket
        if (retryCount > 0) {
          setReinitTimer();
        } else if (globalWs?.onRetriesExhaustedHandler) {
          // no retries remaining, don't try to reinitialize
          globalWs?.onRetriesExhaustedHandler();
        } else {
          console.warn('WS: Retries exhausted, but no onRetriesExhausted handler specified');
        }
      };
    } catch (error) {
      // might occur if we try to attach handlers after websocket has closed (call ended)
      // or reinitialize (if CLEAR_WEBSOCKETS is not the last dispatched event)
      console.log(`Unable to attach websocket handlers: ${error}`);
    }
  }, [wsInstanceCount, wsUuid, dispatch, setReconnectingStatus, onRetriesExhausted, retryCount, shouldInitializeWs]);

  // function should take one parameter (data)
  const setWsOnMessageHandler = useCallback(
    (onMessageFunction: any): void => {
      if (!wsUuid) {
        return;
      }
      function internalWrapperForHeartbeat(data: any): void {
        try {
          const message = JSON.parse(data?.data);
          if (message?.event === 'heartbeat') {
            // If we receive a heartbeat interval, and its greater than 1 second,
            // fire our reinitialization logic at 2.5 x heartbeat interval
            // the reducer will handle clearing and redispatching the
            const heartbeatIntervalMsecs = Number(message?.data);
            if (heartbeatIntervalMsecs && heartbeatIntervalMsecs > 1000) {
              const heartbeatTimerId = setTimeout(() => {
                // here, we want to close and reinitialize the global websocket
                // as we failed to get a heartbeat in time. We also force the reconnecting
                // modal to appear.
                console.warn('sending reinit ws');
                if (globalWs?.setReconnectingStatusHandler) {
                  globalWs?.setReconnectingStatusHandler(true);
                }
                dispatch({
                  type: 'REINIT_WS',
                  payload: { uri: WS_URI, guest_uri: WS_GUEST_URI, mock_uri: MOCK_WS_URI }
                });
              }, heartbeatIntervalMsecs * 1.5);
              dispatch({
                type: 'WS_RESET_HEARTBEAT_TIMER_ID',
                payload: { heartbeatTimerId, failure: false }
              });
              console.log('send back heartbteat');
              sendWsMessage({ event: 'heartbeat' });
            }
          }
        } catch {
          console.log('WS: Unable to parse message to check heartbeat');
        }
        // pass websocket message data to the handler
        onMessageFunction(data);
      }
      const globalWs = (window as any)[wsUuid];
      if (globalWs) {
        globalWs.onmessage = internalWrapperForHeartbeat;
      }
    },
    // want to reset the onMessageFunction every time the websocket reinitializes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wsUuid, dispatch, wsInstanceCount]
  );

  const sendWsMessage = useCallback(
    (message: object) =>
      new Promise((resolve, reject) => {
        const ws = (window as any)[wsUuid];
        if (ws?.readyState === 1) {
          console.log('wsHelper: sending message');
          ws.send(JSON.stringify(message));
          resolve('wsHelper: message sent');
        } else {
          console.log(`Unable to send. Readystate: ${ws?.readyState}`);
          reject({ message: `wsHelper: Unable to send. WS Readystate: ${ws?.readyState}` });
        }
      }),
    // want to reset the sender reference every time the websocket reinitializes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wsUuid, wsReady, wsInstanceCount]
  );

  const sendBinaryWsMessage = useCallback(
    (message: Blob) =>
      new Promise((resolve, reject) => {
        const ws = (window as any)[wsUuid];
        if (ws?.readyState === 1) {
          console.log(`wsHelper: sending binary message of length ${message.size}`);
          ws.send(message);
          resolve('wsHelper: binary message sent');
        } else {
          console.log(`Unable to send. Readystate: ${ws?.readyState}`);
          reject({ message: `wsHelper: Unable to send. WS Readystate: ${ws?.readyState}` });
        }
      }),
    // want to reset the sender reference every time the websocket reinitializes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wsUuid, wsReady, wsInstanceCount]
  );

  // Allows callers to indicate when authentication is complete, so the ws connection can be established
  const initiateWsConnection = useCallback(() => {
    dispatch({
      type: 'SHOULD_INIT_WS',
      payload: { shouldInitializeWs: true }
    });
  }, [dispatch]);
  return { sendWsMessage, sendBinaryWsMessage, wsReady, setWsOnMessageHandler, initiateWsConnection };
}
