import { PaletteOptions } from '@mui/material';

export const initialPalette: PaletteOptions = {
  primary: {
    light: '#34CE92',
    main: '#1F4033',
    dark: '#0CA76A'
  },
  secondary: {
    light: '#45E4C8', // Unique Blue
    main: '#34CE92' // Greenery
  },
  error: {
    main: '#A30909', // danger Red
    light: '#D31212' // light red
  },
  warning: {
    main: '#CEA513' // waring yellow
  },
  common: {
    black: '#000000', // clean dark
    white: '#FFFFFF' // clean white
  },
  grey: {
    50: '#F2F2F2', // natural1
    100: '#E9E9E9', // natural2
    200: '#DDDDDD', // natural3
    300: '#CCCCCC', // natural4
    400: '#BBBBBB', // natural5
    500: '#AAAAAA', // natural6
    600: '#999999', // natural7
    700: '#888888', // natural8
    800: '#777777', // natural9
    900: '#666666', // natural10
    A100: '#555555', // natural11
    A200: '#444444', // natural12
    A400: '#333333', // natural13
    A700: '#222222' // natural14
  }
};
