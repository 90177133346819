import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

// import { postParticipantLogin } from 'src/api/embed';
import { makeCallActive } from 'src/api/esitter';
import { ESitterRouteNames } from 'src/features/esitter/router';
import { VRCallRouteNames } from 'src/features/vrcall/router';
import { EmbedRouteNames } from 'src/features/embed/router';
import styles from '../styles/Begin.module.css';
import useMediaDevices from 'src/hooks/useMediaDevices';
import { useMediaHardwareChecker } from 'src/hooks/useMediaHardwareChecker';
import { InfoModal } from 'src/components/InfoModal/InfoModal';

import { useWebsocketHelper } from 'src/wsHelper';
import { isBackendError } from 'src/errors/BackendError';
import { RootState } from '../../../reducers/root';
import { LogoutIcon } from '../../../assets/icons/LogoutIcon';
import { VrVideoCamIcon } from '../../../assets/icons/VrVideoCamIcon';
import { NotAvailableCam } from '../../../assets/icons/NotAvailableCam';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { t } from 'i18next';

const Begin: React.FC<EmptyProps> = () => {
  const navigate = useNavigate();
  const { callUuid } = useParams();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const isDarkMode = palette.mode === 'dark';

  const { initialCamCheckComplete, initialMicCheckComplete, isCameraOk, isMicrophoneOk } = useSelector(
    (state: RootState) => state.mediapermissions
  );

  const { triggerCameraAndMicCheck, triggerMicCheck } = useMediaHardwareChecker();
  const { mediaAccessModalVisible, mediaAccessModalData } = useSelector((state: RootState) => state.app);
  const { initiateWsConnection, wsReady, setWsOnMessageHandler } = useWebsocketHelper();

  const [loggedIn, setLoggedIn] = useState(false);
  const [callParticipants, setCallParticipants] = useState<any[]>([]);
  const [userUuid, setUserUuid] = useState('');
  const [requiredMediaIsReady, setRequiredMediaIsReady] = useState(false);
  const [wsMessageHandlerIsConfigured, setWsMessageHandlerIsConfigured] = useState(false);
  const [hasTriggeredInitialMediaCheck, setHasTriggeredInitialMediaCheck] = useState(false);

  // Trigger population of redux state for media devices before joining call.
  useMediaDevices({ shouldEnumerateDevices: true });

  const { changingMode, initialRequestedDeviceMode, mode } = useSelector((state: RootState) => state.vrcall);

  const onModalClose = useCallback(() => {
    dispatch({
      type: 'SET_APP_MEDIA_ACCESS_MODAL_VISIBLE',
      payload: false
    });
  }, [dispatch]);

  // Handle initially obtaining media permissions
  useEffect(() => {
    if (requiredMediaIsReady) {
      return;
    }

    const callIsVr = query.get('type') === 'vr';

    if (!hasTriggeredInitialMediaCheck) {
      if (callIsVr && !initialCamCheckComplete) {
        console.log('VR begin: triggering mic/cam check');
        triggerCameraAndMicCheck();
      } else if (!initialMicCheckComplete) {
        triggerMicCheck();
      }
      setHasTriggeredInitialMediaCheck(true);
      return;
    }

    // Permissions checks started, wait for them to coplete
    if ((callIsVr && !initialCamCheckComplete) || !initialMicCheckComplete) {
      return;
    }

    let displayMediaErrorModal = !isMicrophoneOk;
    let modalTitle = t('micUseModal.title');
    let modalDescription = t('micUseModal.description');
    let primaryBtnName = t('micUseModal.retryButton');
    let secondaryBtnName = t('micUseModal.endSessionButton');
    if (callIsVr) {
      displayMediaErrorModal = displayMediaErrorModal || !isCameraOk;
      primaryBtnName = 'Retry';
      secondaryBtnName = 'Cancel your call';
      modalTitle = 'Allow cam and mic use';
      modalDescription =
        'We need access to your camera and microphone so that other participants can see and hear you.' +
        ' Click the camera blocked icon in your browsers address bar to adjust the settings and continue.';
    }

    // do not proceed to make the call active until we confirm media hardware is available
    if (displayMediaErrorModal) {
      dispatch({
        type: 'SET_APP_MEDIA_ACCESS_MODAL_DATA',
        payload: {
          title: modalTitle,
          MuiSvgIcon: ErrorOutlineIcon,
          MuiSvgIconStyle: { color: '#D93B3D' },
          description: modalDescription,
          primaryBtnName,
          secondaryBtnName,
          onPrimaryBtnClick: () => {
            // nothing, as the `onClose` behavior captures this case. We want to recheck the hardware.
          },
          onSecondaryBtnClick: () => {
            if (!callIsVr) {
              //Set end Screen message and Icon
              dispatch({
                type: 'SET_END_CALL_ICON',
                payload: <LogoutIcon aria-label={'Logout'} fill={'#FFFFFF'} stroke={'#FFFFFF'} />
              });
              dispatch({
                type: 'SET_END_CALL_MESSAGE',
                payload: t('sessionEnded')
              });
            }
            navigate(EmbedRouteNames.CallEnded);
            // Loading call ended page will trigger useEffect cleanup in eSitter to Stop Call
          },
          hideCloseBtn: true,
          onClose: () => {
            if (callIsVr && !isCameraOk) {
              triggerCameraAndMicCheck();
            } else if (!isMicrophoneOk) {
              triggerMicCheck();
            } else {
              // Permissions are OK, proceed
              onModalClose();
              setRequiredMediaIsReady(true);
            }
          }
        }
      });
      dispatch({
        type: 'SET_APP_MEDIA_ACCESS_MODAL_VISIBLE',
        payload: true
      });
      return;
    }
    // if we reach here, there is no error
    onModalClose();
    setRequiredMediaIsReady(true);
  }, [
    requiredMediaIsReady,
    setRequiredMediaIsReady,
    query,
    navigate,
    dispatch,
    onModalClose,
    triggerCameraAndMicCheck,
    triggerMicCheck,
    isCameraOk,
    isMicrophoneOk,
    initialCamCheckComplete,
    initialMicCheckComplete,
    hasTriggeredInitialMediaCheck,
    setHasTriggeredInitialMediaCheck
  ]);

  // Handle login separately from subsequent logic so it is not attempted multiple times
  // Only log in after we ensure required media is available
  useEffect(() => {
    if (!callUuid) {
      return;
    }
    // Check for permissions for *any* valid mic/camera, and ensure we've successfully detected at least one
    // mic before proceeding
    if (!requiredMediaIsReady) {
      return;
    }
    if (!loggedIn) {
      const attemptLogin = async () => {
        console.log('************* attemptLogin - get user info');
        const userId = sessionStorage.getItem(`${process.env.REACT_APP_IAS_USER_ID}`);
        const user = sessionStorage.getItem(`${process.env.REACT_APP_IAS_USER_INFO}`);
        if (!user) {
          if (query.get('type') === 'esitter') {
            //Set end Screen message and Icon
            dispatch({
              type: 'SET_END_CALL_ICON',
              payload: <LogoutIcon aria-label={'Logout'} fill={'#FFFFFF'} stroke={'#FFFFFF'} />
            });
            dispatch({
              type: 'SET_END_CALL_MESSAGE',
              payload: t('sessionEnded')
            });
          }
          navigate(EmbedRouteNames.CallEnded);
          return;
        }

        const userInfo = JSON.parse(user);
        let userName = '';
        if (userInfo.name && userInfo.name[0] && userInfo.name[0].family && userInfo.name[0].given) {
          userName = `${userInfo.name[0].given[0]} ${userInfo.name[0].family[0]}`;
        }
        const userImageUrl = sessionStorage.getItem(`${process.env.REACT_APP_IAS_USER_PROFILE_URL}`);

        const participants = [
          {
            host: true,
            uuid: userId,
            metadata: {
              displayName: userName,
              specialty: '',
              imageUrl: userImageUrl
            }
          }
        ];

        // dispatch({
        //   type: 'SET_CMS_VERSION',
        //   payload: postResponse.headers.get('x-cms-version')
        // });

        setUserUuid(userId ? userId : '');
        dispatch({
          type: 'SET_MY_USER_ID',
          payload: participants[0]
        });
        setCallParticipants(participants);
        setLoggedIn(true);
        // now, also trigger websocket establishment
        console.log('************* initiateWsConnection');
        initiateWsConnection();
      };
      attemptLogin();
    }
  }, [requiredMediaIsReady]);

  // copy handler here so we can access it
  /* WEBSOCKET MESSAGES HANDLING */
  const onWebsocketMessage = useCallback(
    (data: any) => {
      let message: any;
      try {
        message = JSON.parse(data?.data);
      } catch (error) {
        console.log(`Received unparseable JSON from server: ${data?.data}`);
        return;
      }
      console.log('WS message: ', JSON.stringify(message));
      const messageData = message?.data && typeof message?.data === 'string' ? JSON.parse(message.data) : message?.data;

      //ignore WS messages with different callUUID.
      if (!message || !messageData || message.callUuid !== callUuid) {
        return;
      }

      if (message?.event === 'callState') {
        console.log('callState received!');
      } else if (message?.event === 'deviceCallState') {
        //Change Mode confirmation message
        if (messageData.stateType === 'reported') {
          dispatch({
            type: 'SET_VR_MODE',
            payload: messageData.mode
          });
          // if the device is ringing - set ringing
          if (messageData.ringing) {
            console.log('messageData for ringing 1: ', messageData.ringing);
            dispatch({
              type: 'SET_VR_MODE_CHANGING',
              payload: true
            });
          }
        }
        // else if (messageData.stateType === 'desired') {
        //   //for other participants on the call (only hosts care for this)
        //   dispatch({
        //     type: 'SET_VR_MODE_CHANGING',
        //     payload: true
        //   });
        // }
      } else if (message?.event === 'error') {
        //Patient rejected the call or somehow the change mode failed
        if (messageData.failedEvent === 'deviceCallState') {
          console.log(
            'Error message from device because: ',
            messageData.reasonCode,
            messageData.reasonMsg,
            messageData.supplementaryData
          );
          // only leave the call on patient rejection if:
          // 1. We are *not* trying to begin an interaction, the device is currently in observation mode,
          // and rejects (rejects the initial call), OR
          // - The initial requested device mode (before call started) was interactive
          // busy = 1001, privacy mode = 1006
          if (messageData.reasonCode === 1001 || messageData.reasonCode === 1006) {
            if (messageData.reasonCode === 1006) {
              dispatch({
                type: 'SET_VR_FECC_VALUES',
                payload: { privacyMode: true }
              });
            }
            //only if reason is patient rejected the call
            if (
              (!changingMode && mode.includes('observation')) ||
              initialRequestedDeviceMode?.includes('interactive')
            ) {
              // end the call and notify user
              dispatch({
                type: 'SET_VR_MODAL_DATA',
                payload: {
                  title: t('popup.patientNotAvailableAtThisTime.title'),
                  MuiSvgIcon: NotAvailableCam,
                  MuiSvgIconStyle: {
                    width: '50px',
                    height: '50px',
                    backgroundColor: isDarkMode ? '#222222' : '#DDDDDD',
                    borderRadius: '50%',
                    padding: '10px',
                    fill: isDarkMode ? '#FFFFFF' : '#111111',
                    stroke: isDarkMode ? '#FFFFFF' : '#111111'
                  },
                  description: t('popup.patientNotAvailableAtThisTime.description'),
                  primaryBtnName: t('okay'),
                  onPrimaryBtnClick: () => {
                    //same behavior as onClose
                  },
                  onClose: () => {
                    dispatch({
                      type: 'END_CALL_PATIENT_REJECTION',
                      payload: true
                    });
                  }
                }
              });
            } else {
              // notify user, do not end the call
              dispatch({
                type: 'SET_VR_MODAL_DATA',
                payload: {
                  title: t('popup.patientNotAvailableAtThisTime.title'),
                  MuiSvgIcon: NotAvailableCam,
                  MuiSvgIconStyle: {
                    width: '50px',
                    height: '50px',
                    backgroundColor: isDarkMode ? '#222222' : '#DDDDDD',
                    borderRadius: '50%',
                    padding: '10px',
                    fill: isDarkMode ? '#FFFFFF' : '#111111',
                    stroke: isDarkMode ? '#FFFFFF' : '#111111'
                  },
                  description: t('popup.patientNotAvailableAtThisTime.description'),
                  primaryBtnName: t('okay'),
                  onPrimaryBtnClick: () => {
                    //same behavior as onClose
                  },
                  onClose: () => {
                    dispatch({
                      type: 'END_CALL_PATIENT_REJECTION',
                      payload: true
                    });
                  }
                }
              });
            }
            dispatch({
              type: 'SET_VR_MODE_CHANGING',
              payload: false
            });
            dispatch({
              type: 'SET_AM_I_STARTING_INTERACTION',
              payload: false
            });
            dispatch({
              type: 'SET_VR_MODAL_VISIBLE',
              payload: true
            });
          } else if (messageData.reasonCode === 1002) {
            // if a mode change was already happening
            dispatch({
              type: 'SET_VR_MODE_CHANGING',
              payload: true
            });
          }
        }
      } else if (message?.event === 'deviceFeccState' && message?.senderType === 'DEVICE') {
        if (messageData.stateType === 'reported') {
          dispatch({
            type: 'SET_VR_FECC_VALUES',
            payload: messageData
          });
          dispatch({
            type: 'SET_HAS_RECEIVED_WS_MESSAGE',
            payload: true
          });
        }
      }
    },
    [changingMode, initialRequestedDeviceMode, dispatch, mode, callUuid]
  );

  // Listen for initial call state/FECC state that might be received before the page
  // navigates to VRCall page
  useEffect(() => {
    console.log('Begin : set WS message handler');
    const callType = query.get('type');
    if (wsReady) {
      if (callType === 'vr') {
        setWsOnMessageHandler(onWebsocketMessage);
        setWsMessageHandlerIsConfigured(true);
      } else {
        setWsMessageHandlerIsConfigured(true);
      }
    }
  }, [wsReady, setWsOnMessageHandler, setWsMessageHandlerIsConfigured, onWebsocketMessage, query]);

  useEffect(() => {
    // Only start call once login is complete, and websocket is established, and websocket listener is configured
    if (!loggedIn || !callUuid || !wsMessageHandlerIsConfigured) {
      return;
    }

    const delay = 2000;

    const callType = query.get('type');
    const beginAndNavigateToCall = setTimeout(async () => {
      console.log('beginAndNavigateToCall - makeCallActive');
      const patchResponse = await makeCallActive(callUuid);

      if (!patchResponse || isBackendError(patchResponse)) {
        if (query.get('type') === 'esitter') {
          //Set end Screen message and Icon
          dispatch({
            type: 'SET_END_CALL_ICON',
            payload: <LogoutIcon aria-label={'Logout'} fill={'#FFFFFF'} stroke={'#FFFFFF'} />
          });
          dispatch({
            type: 'SET_END_CALL_MESSAGE',
            payload: t('sessionEnded')
          });
        }
        navigate(EmbedRouteNames.CallEnded);
        return;
      }

      switch (callType) {
        case 'esitter': {
          dispatch({ type: 'RESET_MY_MIC_IS_ACTIVE' });
          dispatch({ type: 'RESET_MY_CAM_IS_ACTIVE' });
          navigate(ESitterRouteNames.Esitter, {
            state: {
              call: patchResponse
            }
          });

          break;
        }
        case 'vr': {
          dispatch({ type: 'SET_MY_MIC_IS_ACTIVE' });
          dispatch({ type: 'SET_MY_CAM_IS_ACTIVE' });
          dispatch({
            type: 'SET_VR_TEST_CALL',
            payload: query.get('test')?.includes('true') ? true : false
          });

          // get the thumbnail for this provider
          const matchingUser = callParticipants.find((p: any) => p.uuid === userUuid);
          console.log(`part: ${JSON.stringify(callParticipants)} self: ${userUuid}`);
          dispatch({
            type: 'SET_ALL_PARTICIPANT_STATUS',
            payload: [
              {
                host: true,
                uuid: userUuid,
                // includes image URL
                metadata: matchingUser?.metadata
              }
            ]
          });

          navigate(VRCallRouteNames.VRCall, {
            state: { call: patchResponse, userUuid, isHost: true }
          });
          break;
        }
      }
    }, delay);

    return () => {
      clearTimeout(beginAndNavigateToCall);
    };
  }, [callUuid, userUuid, callParticipants, loggedIn, navigate, query, dispatch, wsMessageHandlerIsConfigured]);
  // TODO return black screen instead of white screen
  return (
    <Box className={styles.container} sx={{ backgroundColor: palette.mode === 'dark' ? 'black' : '#BBBBBB' }}>
      <InfoModal
        visible={mediaAccessModalVisible}
        title={mediaAccessModalData.title}
        MuiSvgIcon={mediaAccessModalData.MuiSvgIcon}
        MuiSvgIconStyle={mediaAccessModalData.MuiSvgIconStyle}
        description={mediaAccessModalData.description}
        secondaryBtnName={mediaAccessModalData.secondaryBtnName}
        primaryBtnName={mediaAccessModalData.primaryBtnName}
        onSecondaryBtnClick={mediaAccessModalData.onSecondaryBtnClick}
        onPrimaryBtnClick={mediaAccessModalData.onPrimaryBtnClick}
        hideCloseBtn={mediaAccessModalData.hideCloseBtn}
        onClose={mediaAccessModalData.onClose || onModalClose}
      />
    </Box>
  );
};

export default Begin;
