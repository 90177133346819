import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'src/services/api/api';

export enum RequestStatus {
  NotAsked = 'NotAsked',
  Loading = 'Loading',
  Succeed = 'Succeed',
  Failed = 'Failed'
}

export type CloseCaptionsInfo = {
  uuid: string;
  captions: boolean;
};

export type CloseCaptionsEnableBody = {
  areCaptionsDefault: boolean;
  devices: CloseCaptionsInfo[];
  participants: CloseCaptionsInfo[];
};

export type CloseCaptionsRequestParam = {
  callId: string;
  body: CloseCaptionsEnableBody;
};

export type CloseCaptionsMessage = {
  senderUuid: string;
  senderType: string;
  callUuid: string;
  data: {
    stateType: string;
    transcript: string;
  };
};

export type CloseCaptionsInitialState = {
  closeCaptionsConfig: {
    areCaptionsDefault: boolean;
    devices: CloseCaptionsInfo[];
    participants: CloseCaptionsInfo[];
  };
  closeCaptionsMessages: CloseCaptionsMessage[];

  closeCaptionsStatus: RequestStatus;
  error: unknown;
};

// enablig/disabling close captions, depending on the "areCaptionsDefault" param
export const closeCaptionsEnable = createAsyncThunk(
  'closeCaptions/closeCaptionsEnable',
  async ({ callId, body }: CloseCaptionsRequestParam) => {
    const res = await api.patch<CloseCaptionsEnableBody>(`/api/v2/calls/${callId}/captions`, {
      body
    });
    return res.body;
  }
);

export const initialState: CloseCaptionsInitialState = {
  closeCaptionsConfig: {
    areCaptionsDefault: false,
    devices: [],
    participants: []
  },
  closeCaptionsMessages: [],
  closeCaptionsStatus: RequestStatus.NotAsked,
  error: null
};

const closeCaptionsSlice = createSlice({
  name: 'closeCaptions',
  initialState,
  reducers: {
    setCCMessage(state, { payload }) {
      state.closeCaptionsMessages = [...state.closeCaptionsMessages, payload];
    }
  },
  extraReducers: builder => {
    builder.addCase(closeCaptionsEnable.fulfilled, (state, action) => {
      state.closeCaptionsStatus = RequestStatus.Succeed;
      state.closeCaptionsConfig = action.payload;
    });
    builder.addCase(closeCaptionsEnable.pending, state => {
      state.closeCaptionsStatus = RequestStatus.Loading;
    });
    builder.addCase(closeCaptionsEnable.rejected, (state, { error }) => {
      state.closeCaptionsStatus = RequestStatus.Failed;
      state.error = error;
    });
  }
});

export const { setCCMessage } = closeCaptionsSlice.actions;

export default closeCaptionsSlice.reducer;
