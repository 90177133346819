import { Action, SettingsInitialState } from 'src/@types/reducer';
import { getBrowserType } from 'src/detectMobileDevice';

const initialState: SettingsInitialState = {
  browserType: getBrowserType(),
  didVisitDeviceTable: false,
  selectedAudioInputId: null,
  selectedVideoInputId: null,
  audioInputId: null,
  audioOutputId: null,
  videoInputId: null,
  audioInputInitialized: false,
  audioOutputInitialized: false,
  videoInputInitialized: false,
  themeMode: (localStorage.getItem('theme') as 'light' | 'dark') || 'light'
};

const settingsReducer = (state = initialState, action: Action<any>): SettingsInitialState => {
  switch (action.type) {
    case 'SET_BROWSER_TYPE':
      return {
        ...state,
        browserType: action.payload
      };
    case 'SET_DID_VISIT_DEVICE_TABLE':
      return {
        ...state,
        didVisitDeviceTable: action.payload
      };
    case 'SET_SELECTED_AUDIO_INPUT_ID_FROM_USER':
      return {
        ...state,
        selectedAudioInputId: action.payload
      };
    case 'SET_SELECTED_VIDEO_INPUT_ID_FROM_USER':
      return {
        ...state,
        selectedVideoInputId: action.payload
      };
    case 'SET_AUDIO_INPUT_ID':
      return {
        ...state,
        audioInputId: action.payload,
        audioInputInitialized: true
      };
    case 'SET_AUDIO_OUTPUT_ID':
      return {
        ...state,
        audioOutputId: action.payload,
        audioOutputInitialized: true
      };
    case 'SET_VIDEO_INPUT_ID':
      return {
        ...state,
        videoInputId: action.payload,
        videoInputInitialized: true
      };
    case 'SET_AUDIO_INPUT_INITIALIZED':
      return {
        ...state,
        audioInputInitialized: action.payload
      };
    case 'SET_AUDIO_OUTPUT_INITIALIZED':
      return {
        ...state,
        audioOutputInitialized: action.payload
      };
    case 'SET_VIDEO_INPUT_INITIALIZED':
      return {
        ...state,
        videoInputInitialized: action.payload
      };
    case 'SET_THEME_MODE':
      return {
        ...state,
        themeMode: action.payload
      };
    default:
      return state;
  }
};

export default settingsReducer;
