/* eslint max-len: 0 */
import * as React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export const JoinCallViaNoCamIcon = createSvgIcon(
  <g>
    <svg width='24' height='24' viewBox='0 0 146 193' fill='current' xmlns='http://www.w3.org/2000/svg'>
      <g id='User'>
        <path
          id='Vector'
          d='M72.5344 97.8986C99.2411 97.8986 120.891 75.9832 120.891 48.9493C120.891 21.9153 99.2411 0 72.5344 0C45.8278 0 24.1777 21.9153 24.1777 48.9493C24.1777 75.9832 45.8278 97.8986 72.5344 97.8986Z'
          fill='current'
        />
        <path
          id='Vector_2'
          d='M0 193V168.758C0 155.9 5.12736 143.568 14.2541 134.476C23.3809 125.383 35.7595 120.275 48.6667 120.275H97.3333C110.241 120.275 122.619 125.383 131.746 134.476C140.873 143.568 146 155.9 146 168.758V193'
          fill='current'
        />
      </g>
    </svg>
  </g>,
  'Join Call Via No Cam Icon'
);
