import React from 'react';
import { FallbackPage } from 'src/components/FallbackPage/FallbackPage';
import { CarepointRouteNames } from './route-names';

const Carepoint = React.lazy(() => import('../screens/Carepoint'));

export const CarepointRoutes = [
  {
    path: CarepointRouteNames.Carepoint,
    element: (
      <React.Suspense fallback={<FallbackPage />}>
        <Carepoint />
      </React.Suspense>
    )
  }
];
