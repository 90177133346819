/* eslint max-len: 0 */
import * as React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export const NotAvailableCam = createSvgIcon(
  <g>
    <svg width='24' height='24' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='24px icons' clipPath='url(#clip0_844_81025)'>
        <path
          id='Vector'
          d='M5 5L35 35'
          stroke='current'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M25 18.335V16.6683L32.5883 12.875C32.8424 12.7481 33.1246 12.6881 33.4083 12.7009C33.692 12.7137 33.9677 12.7987 34.2093 12.948C34.4509 13.0973 34.6504 13.3058 34.7888 13.5538C34.9271 13.8018 34.9998 14.081 35 14.365V25.6383C35.0001 25.9856 34.8917 26.3242 34.69 26.6069C34.4883 26.8895 34.2034 27.1021 33.875 27.215'
          stroke='current'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_3'
          d='M16.6667 10H21.6667C22.5507 10 23.3986 10.3512 24.0237 10.9763C24.6488 11.6014 25 12.4493 25 13.3333V18.3333M25 25V26.6667C25 27.5507 24.6488 28.3986 24.0237 29.0237C23.3986 29.6488 22.5507 30 21.6667 30H8.33333C7.44928 30 6.60143 29.6488 5.97631 29.0237C5.35119 28.3986 5 27.5507 5 26.6667V13.3333C5 12.4493 5.35119 11.6014 5.97631 10.9763C6.60143 10.3512 7.44928 10 8.33333 10H10'
          stroke='current'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_844_81025'>
          <rect width='40' height='40' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </g>,
  'Not Available Cam Icon'
);
