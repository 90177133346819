import fhir from 'fhir/r4';
import Client from 'fhirclient/lib/Client';
import FHIR from 'fhirclient';
import { fhirclient } from 'fhirclient/lib/types';

class FhirClient {
  private static instance: Client;

  public static async getInstance() {
    if (!FhirClient.instance) {
      FhirClient.instance = await FHIR.oauth2.ready();
    }
    return FhirClient.instance;
  }

  public static authorize() {
    return FHIR.oauth2.authorize({
      client_id: process.env.REACT_APP_IAS_CLIENT_ID,
      iss: process.env.REACT_APP_FHIR_BASE_URL,
      redirectUri: `${window.location.origin}/callback`,
      pkceMode: 'required'
    });
  }

  public static async create(practitionerRoleData: fhirclient.FHIR.Resource) {
    const client = await FhirClient.getInstance();
    return await client.create(practitionerRoleData);
  }

  public static async request(uuid: string | unknown) {
    const client = await FhirClient.getInstance();
    return client.request<fhir.Practitioner>(`Practitioner/${uuid}`);
  }
}

export default FhirClient;
