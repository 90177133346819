import React, { useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import { TextButton } from '../TextButton/TextButton';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import { setContentName } from '../../../../../../actions/esitter';
import { useDispatch, useSelector } from 'react-redux';
import { BedrailsIndicator } from '../BedrailsIndicator/BedrailsIndicator';
import { MuteButton } from '../../../../screens/NotificationBar/MuteButton/MuteButton';
import { useTheme } from '@mui/material';
import {
  getCallId,
  getSelectedCameraUuid,
  getSelectedDeviceId,
  selectSelectedDeviceInPrivacyMode
} from '../../../../../../selectors/esitter';
import { SidePanelContent } from 'src/@types/enums';
import {
  setEnabled,
  setMotionDetected,
  setRoomBoundaryMuted,
  setRoomBoundaryMutedFor
} from '../../../../../../reducers/bedrailsSlice';
import { useEsitterWSMessages } from '../../../../hooks/useEsitterWSMessages';
import { getSelectedCameraBedrailsStatus } from '../../../../../../selectors/bedrails';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { SettingOutlineIcon } from '../../../../../../assets/icons/SettingOutlineIcon';
import { Stack } from '@mui/material';
import { t } from 'i18next';

export enum BedrailsStatus {
  ON = 'ON',
  OFF = 'OFF',
  MUTED = 'MUTED',
  ALARM = 'ALARM'
}

type BedrailsComponentsMap = Record<BedrailsStatus, React.ReactNode>;

export const BedrailsPanel = React.memo((): React.ReactElement | null => {
  const dispatch = useDispatch();
  const {
    palette: { mode }
  } = useTheme();
  const status = useSelector(getSelectedCameraBedrailsStatus);
  const callId = useSelector(getCallId);
  const selectedCameraUuid = useSelector(getSelectedCameraUuid);
  const selectedDeviceId = useSelector(getSelectedDeviceId);
  const isDeviceInPrivacyMode = useSelector(selectSelectedDeviceInPrivacyMode);

  const { sendUpdateVirtualBedRailsEnabledWSMessage } = useEsitterWSMessages(selectedDeviceId, callId);

  const onEditClick = useCallback(() => {
    dispatch(setContentName(SidePanelContent.VIRTUAL_BEDRAILS));
  }, [dispatch]);

  const handleMute = useCallback(
    (ms: number) => {
      dispatch(setRoomBoundaryMutedFor(selectedCameraUuid, ms));
    },
    [dispatch, selectedCameraUuid]
  );

  const handleTurnOff = useCallback(() => {
    dispatch(setRoomBoundaryMuted(selectedCameraUuid, 0));
    dispatch(setEnabled({ cameraUuid: selectedCameraUuid, enabled: false }));
    dispatch(setMotionDetected({ cameraUuid: selectedCameraUuid, motionDetected: false }));

    sendUpdateVirtualBedRailsEnabledWSMessage(selectedCameraUuid, false);
  }, [selectedCameraUuid, dispatch, sendUpdateVirtualBedRailsEnabledWSMessage]);

  const handleReactivate = useCallback(() => {
    dispatch(setRoomBoundaryMuted(selectedCameraUuid, 0));
  }, [dispatch, selectedCameraUuid]);

  const EditButton = React.memo(() => (
    <TextButton
      fullWidth
      title={t('virtualBedrailsEdit')}
      icon={SettingOutlineIcon}
      iconSx={{ width: '30px', height: '30px', stroke: mode === 'dark' ? '#FFFFFF' : '#666666' }}
      onClick={onEditClick}
      disabled={isDeviceInPrivacyMode}
      labelSx={{
        color: mode === 'dark' ? '#FFFFFF' : '#666666',
        justifyContent: 'center',
        border: '1px solid #666666',
        borderRadius: '4px',
        padding: '4px 16px',
        height: 'auto',
        fontSize: 12,
        textTransform: 'none',
        '& .MuiButton-startIcon': {
          marginRight: '0'
        },
        '& .MuiButton-startIcon > *:first-of-type': {
          padding: '6px',
          marginRight: 0,
          background: 'transparent !important'
        }
      }}
    />
  ));

  const notificationsOffOutlinedIcon = useMemo(() => <NotificationsOffOutlinedIcon />, []);
  const arrowDropDownIcon = useMemo(() => <ArrowDropDownRoundedIcon sx={{ fontSize: '22px' }} />, []);

  const MuteAndDismissButtons = React.memo(() => (
    <>
      <Box
        sx={{
          backgroundColor: mode === 'dark' ? '#222222' : '#CCCCCC',
          borderRadius: '8px',
          marginBottom: '10px',
          padding: '5px 20px 5px 15px',
          display: 'flex',
          justifyContent: 'space-evenly',
          position: 'relative'
        }}
      >
        <MuteButton
          sx={{
            justifyContent: 'center',
            fontSize: '13px'
          }}
          variant={'text'}
          startIcon={notificationsOffOutlinedIcon}
          endIcon={arrowDropDownIcon}
          onMute={handleMute}
          isAlarm={false}
        />
        <Box
          sx={{
            content: "''",
            position: 'absolute',
            top: '28%',
            right: '48%',
            transform: 'translate(50%)',
            bottom: '0',
            width: '1px',
            height: '25px',
            padding: '5px 0 5px 0',
            backgroundColor: mode === 'dark' ? '#111111' : '#BBBBBB'
          }}
        />
        <TextButton
          title={`${t('bedObservation.bedrailsAlarm.mute')}`}
          onClick={handleTurnOff}
          icon={HighlightOffOutlinedIcon}
          labelSx={{ fontSize: '13px', color: mode === 'dark' ? '#FFFFFF' : '#111111' }}
        />
      </Box>
      <EditButton />
    </>
  ));

  const ReactivateAlertButton = React.memo(() => (
    <TextButton fullWidth title='Reactivate Alert' onClick={handleReactivate} icon={PlayCircleOutlineOutlinedIcon} />
  ));

  const componentsMap: BedrailsComponentsMap = {
    [BedrailsStatus.ON]: <EditButton />,
    [BedrailsStatus.OFF]: <EditButton />,
    [BedrailsStatus.ALARM]: <MuteAndDismissButtons />,
    [BedrailsStatus.MUTED]: <ReactivateAlertButton />
  };

  const components = componentsMap[status] || null;

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <BedrailsIndicator status={status} />
      </Box>
      <Box
        sx={{
          marginTop: '10px',
          textAlign: 'center'
        }}
      >
        {components}
      </Box>
    </>
  );
});
