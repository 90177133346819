import React from 'react';
import { FallbackPage } from 'src/components/FallbackPage/FallbackPage';
import { ESitterRouteNames } from './route-names';

const Esitter = React.lazy(() => import('../screens/Esitter'));

export const ESitterRoutes = [
  {
    path: ESitterRouteNames.Esitter,
    element: (
      <React.Suspense fallback={<FallbackPage />}>
        <Esitter />
      </React.Suspense>
    )
  }
];
