import { Action, MediaPermissionsInitialState } from 'src/@types/reducer';

const initialState: MediaPermissionsInitialState = {
  isCameraOk: false,
  isMicrophoneOk: false,
  shouldCheckMic: false,
  shouldCheckCamera: false,
  checkAttemptCount: 0,
  initialCamCheckComplete: false,
  initialMicCheckComplete: false,
  checkInProgress: false
};

const mediaPermissionsReducer = (state = initialState, action: Action<any>): MediaPermissionsInitialState => {
  switch (action.type) {
    case 'CAMERA_PERM_IS_OK':
      return {
        ...state,
        isCameraOk: true
      };
    case 'CAMERA_PERM_IS_NOT_OK':
      return {
        ...state,
        isCameraOk: false
      };
    case 'MIC_PERM_IS_OK':
      return {
        ...state,
        isMicrophoneOk: true
      };
    case 'MIC_PERM_IS_NOT_OK':
      return {
        ...state,
        isMicrophoneOk: false
      };
    case 'SHOULD_CHECK_MIC_PERM':
      return {
        ...state,
        shouldCheckMic: true,
        checkAttemptCount: state.checkInProgress ? state.checkAttemptCount : state.checkAttemptCount + 1
      };
    case 'SHOULD_CHECK_CAMERA_PERM':
      return {
        ...state,
        shouldCheckCamera: true,
        checkAttemptCount: state.checkInProgress ? state.checkAttemptCount : state.checkAttemptCount + 1
      };
    case 'SHOULD_CHECK_MIC_AND_CAMERA_PERM':
      return {
        ...state,
        shouldCheckCamera: true,
        shouldCheckMic: true,
        checkAttemptCount: state.checkInProgress ? state.checkAttemptCount : state.checkAttemptCount + 1
      };
    case 'CAMERA_OR_MIC_CHECK_BEGUN':
      return {
        ...state,
        checkInProgress: true
      };
    case 'CAMERA_OR_MIC_CHECK_COMPLETED':
      return {
        ...state,
        checkInProgress: false
      };

    case 'INITIAL_CAMERA_PERM_CHECK_COMPLETE':
      return {
        ...state,
        initialCamCheckComplete: true
      };
    case 'INITIAL_MIC_PERM_CHECK_COMPLETE':
      return {
        ...state,
        initialMicCheckComplete: true
      };
    default:
      return state;
  }
};

export default mediaPermissionsReducer;
