import React from 'react';
import { VRCallRouteNames } from './route-names';
import { FallbackPage } from 'src/components/FallbackPage/FallbackPage';

const VRCall = React.lazy(() => import('../screens/VRCall'));

export const VRCallRoutes = [
  {
    path: VRCallRouteNames.VRCall,
    element: (
      <React.Suspense fallback={<FallbackPage />}>
        <VRCall />
      </React.Suspense>
    )
  }
];
