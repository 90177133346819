import { WebSocket as MockWebSocket } from 'mock-socket';
import MockWebSocketServer from './MockWebSocketServer';
import WebSocket from 'isomorphic-ws';
import { gridColumnGroupsHeaderStructureSelector } from '@mui/x-data-grid';

class WebSocketProxy {
  private ws: WebSocket;
  private mockWs: MockWebSocket | undefined = undefined;
  private mockServer: MockWebSocketServer | undefined = undefined;
  public onopen: ((event: WebSocket.Event) => void) | null = null;
  public onmessage: ((event: WebSocket.MessageEvent) => void) | null = null;
  public onclose: ((event: WebSocket.CloseEvent) => void) | null = null;
  public onerror: ((event: WebSocket.ErrorEvent) => void) | null = null;

  constructor({ url, guestUrl, mockUrl }: { url: string; guestUrl: string; mockUrl: string }) {
    const accessToken = sessionStorage.getItem(`${process.env.REACT_APP_IAS_ACCESS_TOKEN}`);
    const guestToken = sessionStorage.getItem(`${process.env.REACT_APP_GUEST_ACCESS_TOKEN}`);
    const authToken = accessToken ? `Bearer ${accessToken}` : guestToken;
    const wshost = accessToken ? url : guestUrl;
    console.log('**************** Open WebSocket ', url, ' token=', accessToken, ' guest_token=', guestToken);
    if (accessToken) {
      console.log('accessToken is valid', accessToken, url);
    } else if (guestToken) {
      console.log('guestToken is valid', guestToken, guestUrl);
    } else {
      console.log('no valid token');
    }
    const wsurl = `${wshost}/?token=${authToken}`;
    console.log(`url length = ${wsurl.length}`);
    this.ws = new WebSocket(wsurl);
    this.setupEventHandlers();

    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCKWS_ENABLED === 'true') {
      // Set up a mock websocket client and server. When we receive any message to be sent
      // to the CMS, we will also capture and forward it to the internal "MockServer" via the
      // `mockWs` client.
      this.mockServer = new MockWebSocketServer(mockUrl);
      this.mockWs = new MockWebSocket(mockUrl);
      this.setupMockEventHandlers();
    }
  }

  // Forward readyState transparently from the underlying 'real' websocket
  get readyState(): number {
    return this.ws.readyState;
  }

  // for debug only
  get mockReadyState(): number {
    return this.mockWs?.readyState || 0;
  }

  // Forward send requests transparently to both clients (CMS and mock server)
  public send(data: string): void {
    this.ws.send(data);
    this.mockWs?.send(data);
  }

  public close(code: number, reason: string): void {
    this.mockWs?.close();
    this.ws.close(code, reason);
  }

  private setupMockEventHandlers = () => {
    if (this.mockWs) {
      // client event handlers
      this.mockWs.onopen = () => {
        console.log('Mock WS Client opened');
      };
      this.mockWs.onclose = () => {
        console.log('Mock WS Client closed');
      };
      this.mockWs.onmessage = (event: MessageEvent) => {
        if (this.onmessage) {
          console.info(`Mock client forwarding websocket to app, message: ${event.data}`);
          const adaptedEvent: WebSocket.MessageEvent = {
            data: event.data,
            type: event.type,
            target: this.ws
          };
          this.onmessage(adaptedEvent);
        }
      };
    }
  };

  private setupEventHandlers = () => {
    // These will be overwritten in wsHelper
    this.ws.onopen = (event: WebSocket.Event) => this.onopen && this.onopen(event);
    this.ws.onclose = (event: WebSocket.CloseEvent) => this.onclose && this.onclose(event);
    this.ws.onerror = (event: WebSocket.ErrorEvent) => this.onerror && this.onerror(event);

    // Route incoming messages from both WebSockets to the onmessage handler
    // If we get any message from *either* the mock WS Server or the CMS,
    // the onmessage handlers in the app will be executed.
    this.ws.onmessage = (event: WebSocket.MessageEvent) => {
      if (this.onmessage) {
        this.onmessage(event);
      }
    };
  };
}

export default WebSocketProxy;
