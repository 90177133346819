import React, { useState, useRef, useEffect, useCallback } from 'react';
import { TextField, IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import MicIcon from '@mui/icons-material/Mic';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import styles from '../styles/Participant.module.css';
import { ButtonControl } from 'src/components/ButtonControl/ButtonControl';
import { useNavigate, useParams } from 'react-router';
import { api } from '../../../services/api/api';
import { addParticipant, getInvitationInfo } from '../../../api/embed';
import { VRCallRouteNames } from 'src/features/vrcall/router';
import { IInvitedParticipantInfo } from 'src/@types/embed';
import { useDispatch, useSelector } from 'react-redux';
import { EButtonControlSize, EButtonControlColor } from 'src/@types/enums';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { InfoModal } from 'src/components/InfoModal/InfoModal';
import CallEndIcon from '@mui/icons-material/CallEnd';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { EmbedRouteNames } from 'src/features/embed/router';
import { useWebsocketHelper } from 'src/wsHelper';
import { useMediaHardwareChecker } from 'src/hooks/useMediaHardwareChecker';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { isBackendError, isOk } from 'src/errors/BackendError';
import { RootState } from '../../../reducers/root';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { WarningIcon } from '../../../assets/icons/WarningIcon';
import logo_dark from '../../../assets/images/logo_dark.svg';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { deepOrange } from '@mui/material/colors';
import { UserIcon } from '../../../assets/icons/UserIcon';
import { theme } from '../../../theme';
import { WarningFullIcon } from '../../../assets/icons/WarningFullIcon';
import { WarningCircleIcon } from '../../../assets/icons/WarningCircleIcon';
import { VrVideoCamIcon } from '../../../assets/icons/VrVideoCamIcon';
import { VrMicIcon } from '../../../assets/icons/VrMicIcon';
import { VrMicMuteIcon } from '../../../assets/icons/VrMicMuteIcon';
import { NotAvailableCam } from '../../../assets/icons/NotAvailableCam';
import { JoinCallViaNoCamIcon } from '../../../assets/icons/JoinCallViaNoCamIcon';
import { pointer } from 'd3';
import { JoinUserIcon } from '../../../assets/icons/JoinUserIcon';
import { VideoCamIcon } from '../../../assets/icons/VideoCamIcon';
import { useTranslation } from 'react-i18next';

const Participant: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [name, setName] = useState('');
  const [callInfoRequestComplete, setCallInfoRequestComplete] = useState(false);
  const [providerName, setProviderName] = useState('');
  const [hospitalName, setHospitalName] = useState('');
  const [hospitalLogoURL, setHospitalLogoURL] = useState(logo_dark);
  const [hasAttemptedJoin, setHasAttemptedJoin] = useState(false);

  const [isMicActive, setIsMicActive] = useState(false);
  const [isCamActive, setIsCamActive] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [initialDeviceRooms, setInitialDeviceRooms] = useState<any[]>([]);
  const [myUserUuid, setMyUserUuid] = useState('');

  const { browserType } = useSelector((state: RootState) => state.settings);
  const { modalVisible, modalData } = useSelector((state: RootState) => state.vrcall);
  const { mediaAccessModalVisible, mediaAccessModalData } = useSelector((state: RootState) => state.app);
  const [requiredMediaIsReady, setRequiredMediaIsReady] = useState(false);

  const { initiateWsConnection, wsReady } = useWebsocketHelper();
  const { initialCamCheckComplete, isCameraOk, isMicrophoneOk } = useSelector(
    (state: RootState) => state.mediapermissions
  );
  const { triggerCameraAndMicCheck } = useMediaHardwareChecker();
  const { callId, otpToken } = useParams();

  const dispatch = useDispatch();
  const { palette } = useTheme();
  const isDarkMode = palette.mode === 'dark';
  const isMobile = useSelector((state: RootState) => state.settings.browserType) === 'mobile';
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onModalClose = useCallback(() => {
    dispatch({
      type: 'SET_VR_MODAL_VISIBLE',
      payload: false
    });
  }, [dispatch]);

  const onMediaAccessModalClose = useCallback(() => {
    dispatch({
      type: 'SET_APP_MEDIA_ACCESS_MODAL_VISIBLE',
      payload: false
    });
  }, [dispatch]);

  const onSetMediaAccessModalVisible = useCallback(() => {
    const modalTitle = t('popup.allowCamAndMicUse.title');
    const modalDescription = `<div style="font-size: 16px">${t('popup.allowCamAndMicUse.description')}</div>`;
    const primaryBtnName = t('popup.allowCamAndMicUse.retry');
    const secondaryBtnName = t('popup.allowCamAndMicUse.cancelYourCall');

    dispatch({
      type: 'SET_APP_MEDIA_ACCESS_MODAL_DATA',
      payload: {
        title: modalTitle,
        MuiSvgIcon: WarningCircleIcon,
        MuiSvgIconStyle: {
          width: '60px',
          height: '60px',
          fill: isDarkMode ? '#FFFFFF' : '#000000',
          stroke: isDarkMode ? '#FFFFFF' : '#000000',
          backgroundColor: isDarkMode ? '#222222' : '#D9D9D9',
          padding: '10px',
          borderRadius: '50%'
        },
        description: modalDescription,
        primaryBtnName,
        secondaryBtnName,
        onPrimaryBtnClick: () => {
          triggerCameraAndMicCheck();
        },
        onSecondaryBtnClick: () => {
          // navigate(EmbedRouteNames.CallEnded);
        },
        hideCloseBtn: false,
        disablePrimaryBtnAutoClose: true,

        onClose: () => {
          onMediaAccessModalClose();
        }
      }
    });
    dispatch({
      type: 'SET_APP_MEDIA_ACCESS_MODAL_VISIBLE',
      payload: true
    });
  }, [dispatch, navigate, triggerCameraAndMicCheck, onMediaAccessModalClose]);

  const getVideoStream = useCallback(async () => {
    try {
      const mediaStream = await navigator.mediaDevices?.getUserMedia({
        video: { facingMode: 'user', width: 558.22, height: 314 }
      });
      if (mediaStream) {
        if (videoRef.current !== null) {
          videoRef.current.srcObject = mediaStream;
          videoRef.current.play();
        } else {
          setIsCamActive(false);
        }
      }
    } catch (error) {
      onSetMediaAccessModalVisible();
      setIsCamActive(false);
    }
  }, [onSetMediaAccessModalVisible]);

  const getAudioStream = useCallback(async () => {
    try {
      const mediaStream = await navigator.mediaDevices?.getUserMedia({ audio: true });
      if (!mediaStream) {
        setIsMicActive(false);
      }
    } catch (error) {
      onSetMediaAccessModalVisible();
      setIsMicActive(false);
    }
  }, [onSetMediaAccessModalVisible]);

  const toggleCamera = () => {
    if (isCamActive) {
      if (videoRef.current !== null) {
        const stream = videoRef.current.srcObject as MediaStream;
        if (stream) {
          stream.getTracks().forEach(track => {
            track.stop();
          });
        }
      }
    } else {
      getVideoStream();
    }
    setIsCamActive(state => !state);
  };

  const toggleMic = () => {
    if (!isMicActive) {
      getAudioStream();
    }
    setIsMicActive(state => !state);
  };

  let validationError: string | JSX.Element | null = null;
  let joinButtonEnabled = false;
  let showValidationError = false;

  //only allows some symbols .,-"' to be entered
  const inputRegex = new RegExp('[^\\p{L}\\p{N}\\p{Pi}\\p{Pf}\\p{Pd}\\p{Pc}\\p{Ps}\\p{Pe},"\'. -]+', 'u');

  if (!name || name.trim().length < 3) {
    if (hasAttemptedJoin) {
      showValidationError = true;
    }
    validationError = `${t('joinCallVia.atLeast')}`;
    joinButtonEnabled = false;
  } else if (name.trim().length > 32 || inputRegex.test(name)) {
    showValidationError = true;
    validationError = `${t('joinCallVia.lessCharacter')}`;
    joinButtonEnabled = false;
  } else if (requiredMediaIsReady) {
    joinButtonEnabled = true;
  }

  // Display media access modal on initial call load, over other data
  useEffect(() => {
    if (!initialCamCheckComplete) {
      triggerCameraAndMicCheck();
      return;
    }
    const displayMediaErrorModal = !isMicrophoneOk || !isCameraOk;

    if (displayMediaErrorModal) {
      onSetMediaAccessModalVisible();
    } else {
      onMediaAccessModalClose();
      setRequiredMediaIsReady(true);
    }
  }, [
    initialCamCheckComplete,
    isMicrophoneOk,
    isCameraOk,
    triggerCameraAndMicCheck,
    onMediaAccessModalClose,
    setRequiredMediaIsReady,
    onSetMediaAccessModalVisible
  ]);

  // Will be executed prior after the participant tries to join, before navigating to the call
  useEffect(() => {
    if (!callId || !loggedIn || !wsReady) {
      return;
    }

    navigate(VRCallRouteNames.VRCall, {
      state: {
        call: { callUuid: callId, deviceRooms: initialDeviceRooms },
        userUuid: myUserUuid,
        isParticipant: true
      }
    });
  }, [dispatch, callId, initialDeviceRooms, myUserUuid, navigate, loggedIn, wsReady]);

  const onJoinClick = useCallback(async () => {
    if (!requiredMediaIsReady) {
      onSetMediaAccessModalVisible();
      return;
    }
    if (!validationError && requiredMediaIsReady) {
      if (callId && otpToken) {
        console.log('************* onJoinClick - addParticipant');
        sessionStorage.setItem(`${process.env.REACT_APP_GUEST_ACCESS_TOKEN}`, otpToken);
        api.initHeaderToken();
        const response = await addParticipant(callId, {
          // trim leading/trailing whitespace before submitting name
          // Only allow remaining name to contain letters, numbers, spaces and allowed symbols
          displayName: name.trim(),
          loginToken: otpToken,
          browserType: browserType
        });

        if (!isBackendError(response)) {
          const participant = response;
          dispatch({
            type: 'SET_ALL_PARTICIPANT_STATUS',
            payload: participant.participantStatus
          });

          // Dispatch to the app-wide self mic/camera state that will be used once we join the call
          isMicActive ? dispatch({ type: 'SET_MY_MIC_IS_ACTIVE' }) : dispatch({ type: 'RESET_MY_MIC_IS_ACTIVE' });

          isCamActive ? dispatch({ type: 'SET_MY_CAM_IS_ACTIVE' }) : dispatch({ type: 'RESET_MY_CAM_IS_ACTIVE' });

          // dispatch({
          //   type: 'SET_CMS_VERSION',
          //   payload: headers.get('x-cms-version')
          // });

          setInitialDeviceRooms(participant.deviceRooms);
          setMyUserUuid(participant.userUuid);

          // Will go to a separate useEffect after login to set up websocket before
          // navigating to the call
          console.log('************* initiateWsConnection');
          setLoggedIn(true);
          initiateWsConnection();
        } else if (isBackendError(response) && response.status === 400) {
          //VR_P_16.1n (Desktop)
          const body = response.payload;

          console.log('invited link 400 error', body);

          if (body[0]?.keyword === 'inUse') {
            console.log('Link already in use');
            //Set Popup info and display
            dispatch({
              type: 'SET_VR_MODAL_DATA',
              payload: {
                title: t('error'),
                MuiSvgIcon: ErrorOutlineOutlinedIcon,
                MuiSvgIconStyle: { color: '#D93B3D' },
                description: t('errorConnecting'),
                primaryBtnName: t('okay'),
                onPrimaryBtnClick: onModalClose
              }
            });
            dispatch({
              type: 'SET_VR_MODAL_VISIBLE',
              payload: true
            });
          } else if (body[0]?.keyword === 'notExist' && body[0]?.dataPath === 'callId') {
            //VR_P_16.1m (Desktop)
            console.log('Call has already ended.');
            //Set end Screen message and Icon
            dispatch({
              type: 'SET_END_CALL_ICON',
              payload: <CallEndIcon sx={{ color: '#BEC3CC' }} />
            });
            dispatch({
              type: 'SET_END_CALL_MESSAGE',
              payload: t('endedHost')
            });
            // navigate(EmbedRouteNames.CallEnded);
          } else {
            // This will only happen in case of malicious attack (e.g. changing authtoken)
            console.log('Error on joining.');
            //Set end Screen message and Icon
            dispatch({
              type: 'SET_END_CALL_ICON',
              payload: <CallEndIcon sx={{ color: '#BEC3CC' }} />
            });
            dispatch({
              type: 'SET_END_CALL_MESSAGE',
              payload: 'There was an error with your invitation link.'
            });
            // navigate(EmbedRouteNames.CallEnded);
          }
        }
      }
    }
    setHasAttemptedJoin(true);
  }, [
    browserType,
    isCamActive,
    isMicActive,
    requiredMediaIsReady,
    name,
    callId,
    otpToken,
    initiateWsConnection,
    navigate,
    dispatch,
    validationError,
    setInitialDeviceRooms,
    setHasAttemptedJoin,
    setLoggedIn,
    onModalClose,
    onSetMediaAccessModalVisible
  ]);

  const getCallInfo = useCallback(async () => {
    const invitationInfo = await getInvitationInfo(callId, otpToken);

    if (isOk<IInvitedParticipantInfo>(invitationInfo)) {
      setProviderName(invitationInfo.invitingUser.metadata.displayName);
      setHospitalName(invitationInfo.locationMetadata);
      //setHospitalLogoURL(invitationInfo.hospital.logo);
      setCallInfoRequestComplete(true);
    } else if (isBackendError(invitationInfo) && invitationInfo.status === 400) {
      const body = invitationInfo.payload;

      if (body[0]?.keyword === 'notExist' && body[0]?.dataPath === 'callId') {
        //VR_P_16.1m (Desktop)
        console.log('Call has already ended.');
        //Set end Screen message and Icon
        dispatch({
          type: 'SET_END_CALL_ICON',
          payload: <CallEndIcon sx={{ color: '#BEC3CC' }} />
        });
        dispatch({
          type: 'SET_END_CALL_MESSAGE',
          payload: t('joinCallVia.callEndedMessage')
        });
      } else {
        // This will only happen in case of malicious attack (e.g. changing authtoken)
        console.log('Error on joining.');
        //Set end Screen message and Icon
        dispatch({
          type: 'SET_END_CALL_ICON',
          payload: <CallEndIcon sx={{ color: '#BEC3CC' }} />
        });
        dispatch({
          type: 'SET_END_CALL_MESSAGE',
          payload: 'There was an error with your invitation link.'
        });
      }

      setTimeout(() => {
        // navigate(EmbedRouteNames.CallEnded);
      }, 1000);
    }
  }, [callId, otpToken, setProviderName, setHospitalName, setHospitalLogoURL, dispatch, navigate]);

  // UseEffect to ensure we release the camera track before navigating to another screen
  useEffect(() => {
    const ref = videoRef.current;
    return () => {
      const stream = ref?.srcObject as MediaStream;
      if (stream) {
        stream.getTracks().forEach(t => t.stop());
      }
    };
    // need to update ref every time the camera state changes
  }, [isCamActive]);

  useEffect(() => {
    getVideoStream();
    getAudioStream();
    getCallInfo();
  }, [getAudioStream, getCallInfo, getVideoStream]);

  return (
    <Box
      className={styles.container}
      sx={{ overflowY: 'scroll', backgroundColor: palette.mode === 'dark' ? '#222222' : '#BBBBBB' }}
    >
      {mediaAccessModalVisible ? (
        <InfoModal
          visible={mediaAccessModalVisible}
          title={mediaAccessModalData.title}
          MuiSvgIcon={mediaAccessModalData.MuiSvgIcon}
          MuiSvgIconStyle={mediaAccessModalData.MuiSvgIconStyle}
          description={mediaAccessModalData.description}
          secondaryBtnName={mediaAccessModalData.secondaryBtnName}
          primaryBtnName={mediaAccessModalData.primaryBtnName}
          onSecondaryBtnClick={mediaAccessModalData.onSecondaryBtnClick}
          onPrimaryBtnClick={mediaAccessModalData.onPrimaryBtnClick}
          hideCloseBtn={mediaAccessModalData.hideCloseBtn}
          onClose={mediaAccessModalData.onClose || onMediaAccessModalClose}
          disablePrimaryBtnAutoClose={mediaAccessModalData.disablePrimaryBtnAutoClose}
        />
      ) : (
        <InfoModal
          visible={modalVisible}
          title={modalData.title}
          MuiSvgIcon={modalData.MuiSvgIcon}
          MuiSvgIconStyle={modalData.MuiSvgIconStyle}
          description={modalData.description}
          secondaryBtnName={modalData.secondaryBtnName}
          primaryBtnName={modalData.primaryBtnName}
          onSecondaryBtnClick={modalData.onSecondaryBtnClick}
          onPrimaryBtnClick={modalData.onPrimaryBtnClick}
          onClose={modalData.onClose || onModalClose}
        />
      )}
      <Box
        className={styles.box}
        sx={{
          justifyContent: isMobile ? 'flex-start' : 'center',
          width: isMobile ? '100vw' : 'auto',
          height: '100vh'
        }}
      >
        {hospitalLogoURL && hospitalLogoURL.length > 0 ? (
          <img
            className={styles.hospitalLogo}
            alt='hospital logo'
            src={hospitalLogoURL}
            onError={e => {
              e.currentTarget.style.display = 'none';
            }}
            style={{ width: isMobile ? '260px' : 'auto', margin: isMobile ? '3rem 0 8rem' : '0 0 11rem 0' }}
          />
        ) : (
          <div className={styles.hospitalLogo} style={{ display: 'none' }} />
        )}
        <Box
          className={styles.content}
          sx={{
            position: 'relative',
            display: isMobile ? 'flex' : 'block',
            justifyContent: 'space-between',
            width: isMobile ? '100vw' : 'auto',
            height: isMobile ? '100%' : 'auto',
            backgroundColor: isDarkMode ? '#000000' : '#F2F2F2',
            padding: isMobile ? '6rem 1rem 3rem' : '5vw 10vw 2.5vw',
            textAlign: 'center',
            borderRadius: '1rem'
          }}
        >
          <Box className={styles.nameAndControls} sx={{ width: isMobile ? '100%' : 'auto' }}>
            {isCamActive ? (
              <Box
                style={{
                  position: 'absolute',
                  top: isMobile ? '-5rem' : '-7rem',
                  width: isMobile ? '10rem' : '12rem',
                  height: isMobile ? '10rem' : '12rem',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                  backgroundColor: isDarkMode ? '#333333' : '#BBBBBB'
                }}
              >
                <video ref={videoRef} autoPlay playsInline muted />
              </Box>
            ) : (
              <Avatar
                sx={{
                  position: 'absolute',
                  top: isMobile ? '-5rem' : '-7rem',
                  width: isMobile ? '10rem' : '12rem',
                  height: isMobile ? '10rem' : '12rem',
                  overflow: 'hidden',
                  backgroundColor: isDarkMode ? '#333333' : '#BBBBBB',
                  boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.15)'
                }}
              >
                <JoinCallViaNoCamIcon
                  sx={{
                    position: 'relative',
                    top: '1.3rem',
                    fontSize: '8.5rem',
                    fill: isDarkMode ? '#666666' : '#DDDDDD'
                  }}
                />
              </Avatar>
            )}

            <Typography
              variant='h1'
              sx={{
                fontSize: isMobile ? '1.5rem' : '48px',
                color: palette.mode === 'dark' ? '#FFFFFF' : '#000000',
                letterSpacing: '-1px'
              }}
            >
              {providerName ? providerName : 'Jessica Smith'}, MD
            </Typography>

            {providerName && hospitalName ? (
              <Typography
                variant='subtitle1'
                sx={{
                  margin: isMobile ? '10px 0' : '15px 0 40px',
                  fontSize: isMobile ? '14px' : '28px',
                  lineHeight: isMobile ? 'initial' : '42px'
                }}
              >
                {t('joinCallVia.joinCall', { providerName, hospitalName })}
              </Typography>
            ) : (
              <Box sx={{ margin: isMobile ? '0' : '15px 0 0' }}>
                {callInfoRequestComplete && (
                  <Typography variant='subtitle1' sx={{ fontSize: '28px', lineHeight: '42px' }}>
                    You have been invited to join a call
                  </Typography>
                )}
              </Box>
            )}

            <Stack
              direction={isMobile ? 'column' : 'row'}
              spacing={2}
              alignItems={isMobile ? 'center' : 'flex-start'}
              justifyContent='center'
              sx={{ width: isMobile ? '100%' : 'auto' }}
            >
              <Box
                className={styles.inputName}
                sx={{ width: isMobile ? '100%' : 'none', marginTop: isMobile ? '40px' : '0' }}
              >
                <TextField
                  variant='outlined'
                  fullWidth
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder={t('joinCallVia.enterYourName')}
                  error={showValidationError}
                  helperText={showValidationError && validationError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <IconButton sx={{ padding: '0' }}>
                          <JoinUserIcon
                            sx={{ fill: isDarkMode ? 'white' : 'black', stroke: isDarkMode ? 'white' : 'black' }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: showValidationError && (
                      <InputAdornment position='end'>
                        <IconButton sx={{ padding: '0' }}>
                          <WarningFullIcon sx={{ width: '24px', height: '24px' }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: {
                      marginTop: isMobile ? '10px' : '0',
                      borderRadius: '4px',
                      backgroundColor: isDarkMode ? 'transparent' : '#FFFFFF'
                    }
                  }}
                  sx={{
                    flexDirection: isMobile ? 'column-reverse' : 'column',
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#D31212'
                      }
                    },
                    '& .MuiFormHelperText-root': {
                      '&.Mui-error': {
                        margin: '5px 0 0',
                        color: '#D31212'
                      }
                    }
                  }}
                />
              </Box>
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                <IconButton
                  sx={{
                    padding: '10px',
                    borderRadius: '4px',
                    backgroundColor: isCamActive ? palette.primary.main : isDarkMode ? '#333333' : '#999999',
                    '&:hover': {
                      backgroundColor: isCamActive ? palette.primary.dark : isDarkMode ? '#333333' : '#868686'
                    }
                  }}
                  onClick={toggleCamera}
                >
                  <VideoCamIcon
                    sx={{
                      width: '34px',
                      height: '34px',
                      stroke: isDarkMode ? '#FFFFFF' : '#111111',
                      fill: isDarkMode ? '#FFFFFF' : '#111111'
                    }}
                  />
                </IconButton>
                <IconButton
                  sx={{
                    padding: isMicActive ? '10px' : '15px',
                    borderRadius: '4px',
                    backgroundColor: isMicActive ? palette.primary.main : isDarkMode ? '#333333' : '#999999',
                    '&:hover': {
                      backgroundColor: isMicActive ? palette.primary.dark : isDarkMode ? '#333333' : '#868686'
                    }
                  }}
                  onClick={toggleMic}
                >
                  {isMicActive ? (
                    <VrMicIcon sx={{ width: '34px', height: '34px', fill: 'white', stroke: 'white' }} />
                  ) : (
                    <VrMicMuteIcon sx={{ width: '24px', height: '24px', fill: 'white', stroke: 'white' }} />
                  )}
                </IconButton>
              </Stack>
            </Stack>
          </Box>
          <Button
            id='join_btn'
            variant='contained'
            disabled={!joinButtonEnabled}
            onClick={onJoinClick}
            sx={{
              width: '100%',
              maxWidth: isMobile ? 'none' : '300px',
              height: '60px',
              fontSize: '16px',
              fontWeight: 'bold',
              margin: '40px auto 0',
              borderRadius: '8px',
              '&.Mui-disabled': {
                backgroundColor: isDarkMode ? '#777777' : '#999999',
                color: '#DDDDDD'
              }
            }}
          >
            {t('joinCallVia.joinMeeting')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Participant;
