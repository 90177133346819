import React, { useEffect } from 'react';
import { SaveButton } from '../content/SaveButton/SaveButton';
import { transformPointsToPolygon } from '../../../functions';
import { setBoundaryFirstTime, setSaveDisabled, setSavingWithTimeout } from '../../../../../reducers/bedrailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEsitterWSMessages } from '../../../hooks/useEsitterWSMessages';
import { getCallId, getSelectedCameraUuid, getSelectedDeviceId } from '../../../../../selectors/esitter';
import { getSelectedCameraBedrailsPolygon, selectIsSaved, selectIsSaving } from '../../../../../selectors/bedrails';
import { VirtualBedrailsSensitivityLevel } from '../content/VirtualBedrailsContent/VirtualBedrailsContent';

interface SaveBedrailsButtonProps {
  enable: boolean;
  disabled: boolean;
  sensitivityLevel: VirtualBedrailsSensitivityLevel;
}

export const SaveBedrailsButton = (props: SaveBedrailsButtonProps): JSX.Element => {
  const { enable, disabled, sensitivityLevel } = props;
  const dispatch = useDispatch();
  const callId = useSelector(getCallId);
  const selectedDeviceId = useSelector(getSelectedDeviceId);
  const selectedCameraUuid = useSelector(getSelectedCameraUuid);
  const { sendUpdateVirtualBedRailsEnabledWSMessage } = useEsitterWSMessages(selectedDeviceId, callId);
  const points = useSelector(getSelectedCameraBedrailsPolygon);

  const loading = useSelector(selectIsSaving);
  const loaded = useSelector(selectIsSaved);

  const onSaveClick = () => {
    const polygon = transformPointsToPolygon(points);
    sendUpdateVirtualBedRailsEnabledWSMessage(selectedCameraUuid, enable, sensitivityLevel, polygon);
    dispatch(setSavingWithTimeout(selectedCameraUuid));
    dispatch(setSaveDisabled({ cameraUuid: selectedCameraUuid, disabled: true }));
  };

  useEffect(() => {
    if (loaded) {
      dispatch(setBoundaryFirstTime({ cameraUuid: selectedCameraUuid }));
    }
  }, [dispatch, loaded, selectedCameraUuid]);

  return <SaveButton disabled={disabled} loading={loading} loaded={loaded} onClick={onSaveClick} />;
};
