/* eslint max-len: 0 */
import * as React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export const VideoCamIcon = createSvgIcon(
  <g>
    <svg width='24' height='24' viewBox='0 0 58 58' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M36.25 24.1673L47.2531 18.667C47.6214 18.4829 48.0307 18.396 48.4421 18.4146C48.8534 18.4331 49.2532 18.5564 49.6035 18.7729C49.9539 18.9893 50.2431 19.2916 50.4437 19.6512C50.6443 20.0108 50.7498 20.4157 50.75 20.8275V37.1738C50.7498 37.5856 50.6443 37.9905 50.4437 38.3501C50.2431 38.7097 49.9539 39.012 49.6035 39.2285C49.2532 39.4449 48.8534 39.5682 48.4421 39.5868C48.0307 39.6053 47.6214 39.5184 47.2531 39.3343L36.25 33.834V24.1673Z'
        stroke='current'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31.4167 14.5H12.0833C9.41396 14.5 7.25 16.664 7.25 19.3333V38.6667C7.25 41.336 9.41396 43.5 12.0833 43.5H31.4167C34.086 43.5 36.25 41.336 36.25 38.6667V19.3333C36.25 16.664 34.086 14.5 31.4167 14.5Z'
        fill='currentColor'
        stroke='current'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </g>,
  'Video Cam Icon'
);
