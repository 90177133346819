/* eslint max-len: 0 */
import * as React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export const JoinUserIcon = createSvgIcon(
  <g>
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 32 32' fill='none'>
      <g clipPath='url(#clip0_3298_207980)'>
        <path
          d='M15.9993 14.6667C18.9449 14.6667 21.3327 12.2789 21.3327 9.33333C21.3327 6.38781 18.9449 4 15.9993 4C13.0538 4 10.666 6.38781 10.666 9.33333C10.666 12.2789 13.0538 14.6667 15.9993 14.6667Z'
          stroke='current'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8 28V25.3333C8 23.9188 8.5619 22.5623 9.5621 21.5621C10.5623 20.5619 11.9188 20 13.3333 20H18.6667C20.0812 20 21.4377 20.5619 22.4379 21.5621C23.4381 22.5623 24 23.9188 24 25.3333V28'
          stroke='current'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_3298_207980'>
          <rect width='32' height='32' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </g>,
  'Join User Icon'
);
