import React from 'react';
import { createHashRouter, Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { NotFoundRoutes, NotFoundRouteNames } from 'src/features/notfound/router';
import { DeviceRouteNames, DeviceRoutes } from 'src/features/devices/router';
import { VRCallRoutes } from 'src/features/vrcall/router';
import { AuthRouteNames, AuthRoutes } from '../features/auth/router';
import { ESitterRoutes } from '../features/esitter/router';
import { EmbedRoutes } from '../features/embed/router';
import { CarepointRoutes } from '../features/carepoint/router';

const Router: React.FC<EmptyProps> = () => {
  const key = localStorage.getItem('@x-api-key');

  const router = createBrowserRouter([
    { path: '/', element: <Navigate to={key ? AuthRouteNames.Main : AuthRouteNames.SignIn} /> },
    ...NotFoundRoutes,
    ...AuthRoutes,
    ...CarepointRoutes,
    ...DeviceRoutes,
    ...ESitterRoutes,
    ...VRCallRoutes,
    ...EmbedRoutes
  ]);

  return <RouterProvider router={router} fallbackElement={<Navigate to={NotFoundRouteNames.NotFound} />} />;
};

export default Router;
