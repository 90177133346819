import { GetAccountResult, UpdateProfileRequest } from '../@types/auth.types';
import AuthApi from '../services/api/authApi';
import qs from 'qs';
import FhirClient from '../features/auth/types/FhirClient';
import { User, Name } from '../features/auth/types/UserInfo.types';

interface IDefaultTokenResponse {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
}

export async function getAccount(): Promise<GetAccountResult> {
  const authApi = new AuthApi({
    baseURL: `${process.env.REACT_APP_IAS_BASE_URL}`
  });
  const res = await authApi.get<GetAccountResult>('/user/v1/account');
  return res.body;
}

export async function updateProfile(data: UpdateProfileRequest): Promise<void> {
  const authApi = new AuthApi({
    baseURL: `${process.env.REACT_APP_IAS_BASE_URL}`
  });
  await authApi.put('/user/v1/account/profile', { body: data });
}

export async function getProfileImage(): Promise<string> {
  const sUser = sessionStorage.getItem(`${process.env.REACT_APP_IAS_USER_INFO}`);
  if (sUser) {
    const user: User = JSON.parse(sUser);
    return await getProfileImageUrl(user);
  }
  return String();
}

async function getProfileImageUrl(user: User) {
  const photo = user.photo?.find(photo => photo.title !== 'signature');
  const filename = photo?.url?.split('/').pop() ?? '';
  // const profileImage = await getImageUrl(`/${filename}`, { op: 'r' });
  const authApi = new AuthApi({
    baseURL: `${process.env.REACT_APP_DSS_BASE_URL}${process.env.REACT_APP_DSS_HEALTHCARE_BASE_PATH}`
  });
  const query = qs.stringify({ op: 'r' });
  const result = await authApi.get(`/${filename}${query && '?' + query}`);
  return user.photo.length === 0 ? '' : (result.body as string);
}

export async function getUserName(): Promise<string> {
  const sUser = sessionStorage.getItem(`${process.env.REACT_APP_IAS_USER_INFO}`);
  if (sUser) {
    const user: User = JSON.parse(sUser);
    const name: Name = user.name[0];
    if (name.given[0] && name.family) {
      return `${name.given[0]} ${name.family}`;
    }
  }
  return String();
}

export async function getDssUrl(path: string): Promise<string> {
  const authApi = new AuthApi({
    baseURL: `${process.env.REACT_APP_DSS_BASE_URL}${process.env.REACT_APP_DSS_HEALTHCARE_BASE_PATH}`
  });
  const res = await authApi.get<string>(path);
  return res.body;
}

export async function putProfileImage(path: string, files: File): Promise<any> {
  await fetch(path, {
    method: 'PUT',
    body: files,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

async function getUserInfo(uuid: string | undefined) {
  //return await FhirClient.request(uuid);
  const resp = await FhirClient.request(uuid);
  console.log(`getUserInfo ${JSON.stringify(resp)}`);
  return resp;
}

export async function requestUpdateToken(): Promise<IDefaultTokenResponse> {
  const refreshToken = sessionStorage.getItem('IAS_REFRESH_TOKEN');
  const authApi = new AuthApi({
    baseURL: `${process.env.REACT_APP_IAS_BASE_URL}`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const res = await authApi.post<string>(
    `/authentication/v1/authorization/${process.env.REACT_APP_IAS_AUTH_SERVER_NAME}/token`,
    {
      body: {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: `${process.env.REACT_APP_IAS_CLIENT_ID}`
      }
    }
  );
  return res.body as unknown as IDefaultTokenResponse;
}

export function addSessionToken(accessToken: string, refreshToken: string) {
  sessionStorage.setItem(`${process.env.REACT_APP_IAS_ACCESS_TOKEN}`, accessToken);
  sessionStorage.setItem(`${process.env.REACT_APP_IAS_REFRESH_TOKEN}`, refreshToken);
}

export function removeSessionToken() {
  sessionStorage.removeItem(`${process.env.REACT_APP_IAS_ACCESS_TOKEN}`);
  sessionStorage.removeItem(`${process.env.REACT_APP_IAS_REFRESH_TOKEN}`);
}

export function addUserId(uuid: string) {
  sessionStorage.setItem(`${process.env.REACT_APP_IAS_USER_ID}`, uuid);
}

export function removeUserId() {
  sessionStorage.removeItem(`${process.env.REACT_APP_IAS_USER_ID}`);
}

export async function addSessionUserInfo(uuid: string | undefined) {
  if (uuid) {
    sessionStorage.setItem(`${process.env.REACT_APP_IAS_USER_INFO}`, JSON.stringify(await getUserInfo(uuid)));
    sessionStorage.setItem(`${process.env.REACT_APP_IAS_USER_PROFILE_URL}`, JSON.stringify(await getProfileImage()));
  }
}

export async function removeSessionUserInfo() {
  sessionStorage.removeItem(`${process.env.REACT_APP_IAS_USER_INFO}`);
  sessionStorage.removeItem(`${process.env.REACT_APP_IAS_USER_PROFILE_URL}`);
}
